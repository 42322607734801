<template>
  <AppLayout>
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
      <div class="widget widget-six">

        <projectMenu :project="project"></projectMenu>

        <div class="widget-content">
          <div v-if="$page.props.errors">
            <ul style="list-style-type: none; padding: 0" v-for="error in $page.props.errors">
              <li class="alert alert-danger">{{ error }}</li>
            </ul>
          </div>

          <div class="text-end mb-3">
            <ProjectsHistory
                :project="project"
                :model="'ProjectFileHistory'">
            </ProjectsHistory>
          </div>

          <div class="card-body">
            <div class="row mt-3">
              <div class="col-sm-12" v-if="projectFileData">
                <h4>Plik projektu</h4>

                <div v-if="isLoading">
                  <div class="position-absolute w-100 h-100 text-center" style="z-index: 9">
                    <div class="spinner-border text-danger me-2 align-self-center loader-lg" style="top:20%"></div>
                  </div>
                </div>

                <div :style="{ opacity: isLoading ? '0.4' : '1' }">
                  <ejs-grid id="GridProjectFile" ref="gridProjectFile" :key="gridProjectKey" gridLines="Both"
                            class="top-margin"
                            height='100%'
                            :keyPressed="onKeyDown"
                            :allowExcelExport='true'
                            :dataSource="projectFileData" :editSettings="editSettings"
                            :allowPaging="true" :pageSettings='pageSettings'
                            :allowSorting='true' :allowFiltering='true'
                            :filterSettings="filterOptions"
                            :allowReordering='true' :allowResizing='true'
                            :showColumnChooser='true' :selectionSettings='selectionOptions'
                            :allowSelection='true'
                            :enablePersistence="true"
                            :toolbar='toolbar'
                            :actionComplete="actionComplete"
                            :cellSave="onCellSave"
                            :toolbarClick="toolbarClickE"
                            :batchCancel="onCancelAction"
                            :dataBound="dataBound">
                    <e-columns>
                      <e-column field='id' headerText='ID' width="85" :allowEditing="false"
                                isPrimaryKey="true"></e-column>
                      <e-column field="topic" headerText="Temat" type="string"></e-column>
                      <e-column field="priority" type="number" headerText="Priorytet"></e-column>
                      <e-column field="topic2" type='string' headerText="Temat"></e-column>
                      <e-column field="person_responsible_for" headerText="Osoba odpowiedzialna" editType='dropdownedit'
                                type="string" :edit='personResponsibleParams'></e-column>
                      <e-column field="resolution_steps" type='string'
                                headerText="Kroki podjęte do rozwiązania problemu"></e-column>
                      <e-column field="meeting_date" headerText="Data spotkania" type="date" editType='datepickeredit'
                                format='y-MM-d'></e-column>
                      <e-column field="percentages" headerText="%" type='string' width="70"></e-column>
                      <e-column field="status" headerText="Status" editType="dropdownedit" :edit="statusParams"
                                type="string"></e-column>
                      <e-column field="additional_points" type='string' headerText="Dodatkowe punkty"></e-column>
                      <e-column field="correspondence_url" headerText="Url" type='string'></e-column>
                      <e-column field='created_by_name' headerText='Stworzył' :allowEditing="false"></e-column>
                    </e-columns>
                  </ejs-grid>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div class="widget-content">
          <div class="row g-0">
            <GraphProjectFileProgress :projectId="project.id"></GraphProjectFileProgress>
          </div>
        </div>

      </div>
    </div>
  </AppLayout>
</template>

<script>
import AppLayout from "@/Layouts/App.vue";
import route from "../../../../../vendor/tightenco/ziggy/src/js/index.js";
import projectMenu from "@/Pages/Projects/parts/projectMenu.vue";
import GraphProjectFileProgress from '../../../Components/Widgets/Graphs/GraphProjectFileProgress.vue';

import ProjectsHistory from "@/Pages/Projects/Components/ProjectsHistory.vue";
import {
  ColumnChooser,
  ColumnDirective,
  ColumnsDirective,
  Edit, ExcelExport,
  Filter,
  GridComponent,
  Page,
  Reorder,
  Resize,
  Sort,
  Toolbar
} from '@syncfusion/ej2-vue-grids';

export default {
  components: {
    ProjectsHistory, AppLayout, projectMenu, GraphProjectFileProgress,
    'ejs-grid': GridComponent,
    'e-columns': ColumnsDirective,
    'e-column': ColumnDirective
  },
  props: {
    project: Object,
    users: Array,
    projectFiles: Object
  },

  setup(page) {
    let projectFileData = page.projectFiles;
    let usersData = page.users;

    return {
      usersData,
      projectFileData
    }
  },

  data() {
    return {
      isLoading: false,
      gridProjectKey: 0,
      pageSettings: {pageSize: 15},
      filterOptions: {type: "Excel"},
      toolbar: this.getToolbar(),
      projectFileRules: {required: true},
      editSettings: {
        allowEditing: true,
        allowAdding: true,
        allowDeleting: true,
        showConfirmDialog: false,
        showDeleteConfirmDialog: false,
        mode: 'Batch'
      },
      selectionOptions: {type: 'Multiple', cellSelectionMode: 'Box', mode: 'Cell'},
      personResponsibleParams: {
        params: {
          dataSource: this.users.map(person_responsible_for => ({
            person_responsible_for
          })),
          fields: {text: "person_responsible_for", value: "person_responsible_for"}
        }
      },
      statusParams: {
        params: {
          dataSource: [
            {'status': 'In Progress'},
            {'status': 'Late'},
            {'status': 'Ready'},
            {'status': 'To Do'},
            {'status': 'Zaznaczone'},
          ],
          fields: {text: "status", value: "status"}
        }
      },
    }
  },

  provide:
      {
        grid: [Page, Edit, Toolbar, Sort, Filter, Reorder, Resize, ColumnChooser, ExcelExport]
      },


  methods: {
    route,
    isRouteActive(routeName) {
      return route().current() === routeName;
    },

    onCellSave(args) {
      if (args.previousValue !== args.value) {
        this.$makeDirty();
      }
    },

    onCancelAction() {
      this.$makeClean();
    },

    getToolbar() {
      return [
        "Add",
        {text: 'Usuń', prefixIcon: 'e-delete', id: 'delete_btn'},
        "Update",
        "Cancel",
        {text: "Search", prefixIcon: 'e-search', id: "search", align: 'center'},
        {text: "Export do Excela", prefixIcon: 'e-excelexport', id: "excelexport", align: 'right'},
        "ColumnChooser",
      ];
    },

    toolbarClickE(args) {
      if (args.item.id === 'delete_btn') {
        Toast.fire({
          toast: false,
          icon: 'warning',
          title: 'Usuwanie Wiersza',
          html: 'Na pewno chcesz usunąć wybrane wiersze?',
          showDenyButton: true,
          timer: false,
          position: 'center',
          showConfirmButton: true,
          confirmButtonText: 'Tak, usuń!',
          denyButtonText: 'Nie',
        }).then((result) => {
          if (result.isConfirmed) {
            this.deleteRow();
          }
        });
        return false;
      }

      if (args.item.id === 'excelexport') {
        this.$refs.gridProjectFile.excelExport();
      }
    },

    onKeyDown: function (args) {
      let keyCode = args.which || args.keyCode;
      let isCtrlKey = (args.ctrlKey || args.metaKey) ? true : ((keyCode === 17));

      // code 83 to 'S'
      if (isCtrlKey && keyCode === 83) {
        args.preventDefault();

        // zapisz
        let toolbar = document.getElementsByClassName('e-toolbar-left')[0];
        let buttons = toolbar.querySelectorAll('.e-toolbar-item');
        let updateButton = buttons[2].querySelector('button');
        if (updateButton) {
          updateButton.click();
        }
      }
    },

    deleteRow() {
      let self = this;
      this.isLoading = true;
      const scrollPosition = window.scrollY;
      let gridInstance = document.getElementById('GridProjectFile').ej2_instances[0];
      const selectedIndexes = gridInstance.getSelectedRowCellIndexes();
      let deletedRows = selectedIndexes.map(o => gridInstance.currentViewData[o.rowIndex]);
      deletedRows = deletedRows.map(obj => obj.id); // zmiana na array tylko z id

      selectedIndexes.forEach(o => {
        gridInstance.deleteRecord('id', gridInstance.currentViewData[o.rowIndex]);
      });

      axios.delete(route('projects.show.project-file.delete', {
        project: self.project,
        projectFiles: JSON.stringify(deletedRows)
      })).then(response => {
        if (response.status === 200) {
          Toast.fire({
            position: 'top-end',
            toast: true,
            icon: response.data.icon,
            title: response.data.title,
            html: response.data.message,
          });
        } else {
          console.log(response);
          Toast.fire({
            position: 'center',
            toast: false,
            icon: response.data.icon,
            title: response.data.title,
            html: response.data.message,
            timer: false,
            showCloseButton: true
          });
        }
        self.reloadGrid();
        self.updateProjectFilesRows();
        self.isLoading = false;
        this.$makeClean();
        this.$nextTick(() => {
          window.scrollTo(0, scrollPosition);
        });
      }).catch(error => {
        console.log(error);
      });
    },

    dataBound() {
      let gridInstance = document.getElementById('GridProjectFile').ej2_instances[0];
      gridInstance.hideScroll();
    },

    actionComplete(args) {
      if (args.requestType === "batchsave") {
        this.isLoading = true;

        let newData = this.parseJsonRows(args.rows);
        this.updateProjectFilesRows(newData);
      }
    },

    updateProjectFilesRows(rows) {
      const self = this;
      axios.post(route('projects.show.project-file.update', self.project.id), {
        'rows': rows
      }).then(response => {
        self.reloadGrid();
        this.projectFileData = response.data;
        self.isLoading = false;
        this.$makeClean();
      }).catch(error => {
        console.log(error);
      })
    },

    parseJsonRows(rows) {
      let jsonRows = [];
      rows.forEach(function (row) {
        jsonRows.push(row.data);
      });

      return jsonRows;
    },

    reloadGrid() {
      this.gridProjectKey += 1;
    }
  }
}
</script>

<style>
@import "@syncfusion/ej2-vue-grids/styles/material.css";
@import "@syncfusion/ej2-base/styles/material.css";
@import "@syncfusion/ej2-buttons/styles/material.css";
@import "@syncfusion/ej2-calendars/styles/material.css";
@import "@syncfusion/ej2-dropdowns/styles/material.css";
@import "@syncfusion/ej2-inputs/styles/material.css";
@import "@syncfusion/ej2-navigations/styles/material.css";
@import "@syncfusion/ej2-popups/styles/material.css";
@import "@syncfusion/ej2-splitbuttons/styles/material.css";

[id$="_excelexport"] {
  float: right;
}
</style>
