<template>
  <form @submit.prevent="submitForm" ref="projectForm">
    <table class="table table-bordered">
      <thead>
      <tr>
        <th>Kategoria</th>
        <th>Dostawca 1</th>
        <th>Dostawca 2</th>
        <th>Dostawca 3</th>
        <th>Uwagi</th>
        <th>Zatwierdzenia</th>
        <th>Data Zatwierdzenia</th>
        <th>Ostatnia aktualizacja</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(deliverers) in projectSuppliers">
        <td>
          {{ deliverers[Object.keys(deliverers)[0]][0].categoryDisplayName }}
        </td>
        <td v-for="key in [1, 2, 3]" :key="key">
          <template v-if="deliverers[key]">
            <div v-for="delivererData in deliverers[key]" :key="delivererData.id">
              <div class="checkbox-success custom-control custom-checkbox">
                <input type="checkbox"
                       class="custom-control-input"
                       ref="delivererId"
                       :id="delivererData.id"
                       :value="delivererData.id"
                       :data-category="delivererData.category"
                       @change="changeFormData(delivererData.category, delivererData.id)">
                <label class="custom-control-label" :for="delivererData.id">{{ delivererData.name }}</label>
              </div>
            </div>
          </template>

          <template v-else>
            <td></td>
          </template>
        </td>

        <td @dblclick="editComment(selectedSuppliersByCategory[deliverers[Object.keys(deliverers)[0]][0]?.category]?.[0]?.comments)"
            @change="changeFormData(deliverers[Object.keys(deliverers)[0]][0].category, 'comments', 'comments')">
          {{ selectedSuppliersByCategory[deliverers[Object.keys(deliverers)[0]][0]?.category]?.[0]?.comments }}
        </td>

        <td>
          <div v-for="(name, position) in loggedUserNeedApprove">
            <div class="checkbox-success custom-control custom-checkbox text-color">
              <input type="checkbox" class="custom-control-input"
                     :id="'defaultSwitch'+position+deliverers[Object.keys(deliverers)[0]][0].id"
                     :checked="isApproved(selectedSuppliersByCategory[deliverers[Object.keys(deliverers)[0]][0]?.category]?.[0], position)"
                     @change="changeFormData(selectedSuppliersByCategory[deliverers[Object.keys(deliverers)[0]][0]?.category]?.[0]?.category, position, 'approvals')">
              <label class="custom-control-label"
                     :for="'defaultSwitch'+position+deliverers[Object.keys(deliverers)[0]][0].id">{{ name }}</label>
            </div>
          </div>

          <div class="text-muted small" v-for="(position, approvalUser) in shouldApprovalSelectedSuppliers">
            <div class="text-success"
                 v-if="isApproved(selectedSuppliersByCategory[deliverers[Object.keys(deliverers)[0]][0]?.category]?.[0], approvalUser) === true">
              <i class="bi bi-check-circle"></i> {{ position }}
            </div>
            <div class="text-danger" v-else>
              <i class="bi bi-x-circle"></i> {{ position }}
            </div>
          </div>
        </td>

        <td>
          {{ selectedSuppliersByCategory[deliverers[Object.keys(deliverers)[0]][0]?.category]?.[0]?.approval_date }}
          <br> {{
            selectedSuppliersByCategory[deliverers[Object.keys(deliverers)[0]][0]?.category]?.[0]?.approval_by?.first_name
          }}&nbsp;{{
            selectedSuppliersByCategory[deliverers[Object.keys(deliverers)[0]][0]?.category]?.[0]?.approval_by?.last_name
          }}
        </td>

        <td>
          {{ selectedSuppliersByCategory[deliverers[Object.keys(deliverers)[0]][0]?.category]?.[0]?.updated_at }}<br> {{
            selectedSuppliersByCategory[deliverers[Object.keys(deliverers)[0]][0]?.category]?.[0]?.updated_by?.first_name
          }}&nbsp;{{
            selectedSuppliersByCategory[deliverers[Object.keys(deliverers)[0]][0]?.category]?.[0]?.updated_by?.last_name
          }}
        </td>
      </tr>
      </tbody>
    </table>
    <button v-if="shouldApprovalSelectedSuppliers && isDirty" type="submit" :disabled="isProcessing"
            class="btn btn-primary">Zaktualizuj
    </button>
  </form>
</template>


<script>
import route from "../../../../../vendor/tightenco/ziggy/src/js/index.js";
import {usePermission} from "@/composables/resources/js/composables/permissions.js";

const {hasPermission} = usePermission();
export default {
  components: {hasPermission},
  props: {
    projectSuppliers: Object,
    shouldApprovalSelectedSuppliers: Object,
    selectedSuppliers: Object,
    selectedSuppliersByCategory: Object,
    project: Object,
    authUser: Object
  },

  data() {
    let projectSelectedSuppliersApprovals = {};

    this.selectedSuppliers.forEach(function (ps, idx) {
      projectSelectedSuppliersApprovals[idx] = ps.selected_suppliers_approvals;
    });

    // sprawdzenie czy użytkownik jest na stanowisku X
    let loggedUserNeedApprove = {};
    for (let key in this.shouldApprovalSelectedSuppliers) {
      if (this.authUser.position === this.shouldApprovalSelectedSuppliers[key]) {
        loggedUserNeedApprove[key] = this.shouldApprovalSelectedSuppliers[key];
        delete this.shouldApprovalSelectedSuppliers[key];
      }
    }

    // sprawdzenie czy użytkownik jest przypisany do projektu jako engineer
    if (this.authUser.id === this.project.engineer_id) {
      loggedUserNeedApprove['engineer'] = this.shouldApprovalSelectedSuppliers['engineer'];
      delete this.shouldApprovalSelectedSuppliers['engineer'];
    }

    // sprawdzenie czy użytkownik jest przypisany do projektu jako project_manager
    if (this.authUser.id === this.project.project_manager_id) {
      loggedUserNeedApprove['project_manager'] = this.shouldApprovalSelectedSuppliers['project_manager'];
      delete this.shouldApprovalSelectedSuppliers['project_manager'];
    }

    // sprawdzenie, czy użytkownik jest przypisany do projektu jako kierownik działu automatyki i programowania
    if (this.authUser.id === this.project.automation_manager_id) {
      loggedUserNeedApprove['automation_manager'] = this.shouldApprovalSelectedSuppliers['automation_manager'];
      delete this.shouldApprovalSelectedSuppliers['automation_manager'];
    }

    // sprawdzenie, czy użytkownik jest przypisany do projektu jako kierownik działu planowania
    if (this.authUser.id === this.project.planning_manager_id) {
      loggedUserNeedApprove['planning_manager'] = this.shouldApprovalSelectedSuppliers['planning_manager'];
      delete this.shouldApprovalSelectedSuppliers['planning_manager'];
    }


    return {
      hasPermission,
      projectSelectedSuppliersApprovals,
      loggedUserNeedApprove,
      isDirty: false,
      isProcessing: false,
      formData: {}
    }
  },

  mounted() {
    this.loadInputStatus(this.$refs.delivererId);
  },

  methods: {
    formIsDirty() {
      this.isDirty = true;
    },

    formIsNotDirty() {
      this.isDirty = false;
      this.formData = {};
    },

    isApproved(selected_supplier, position) {
      if (!selected_supplier) {
        return false;
      }
      let selectedSupplier = selected_supplier.selected_suppliers_approvals;

      if (!selectedSupplier) {
        return false;
      }

      for (const supplier of selectedSupplier) {
        if (supplier.position === position) {
          if (supplier.approved === 1) {
            return true;
          }
        }
      }

      return false;
    },

    loadInputStatus(delivererButtons) {
      const self = this;
      delivererButtons.forEach(function (checkbox) {
        self.selectedSuppliers.forEach(function (supplier) {
          let buttonCategory = checkbox.dataset.category;
          if (buttonCategory === supplier.category) {
            if (supplier.deliverers === null || supplier.deliverers.length === 0) {
              return;
            }

            if (supplier.deliverers.length > 0) {
              supplier.deliverers.forEach(function (id) {
                if (parseInt(checkbox.id) === id) {
                  checkbox.checked = true;
                }
              })
            }
          }
        });
      })
    },

    changeFormData(category, id, deliverer = 'deliverers') {
      this.formIsDirty();
      let inputValue;
      if (event.target.type === 'checkbox')
        inputValue = event.target.checked;
      else
        inputValue = event.target.value;

      if (!this.formData[category])
        this.formData[category] = [];

      if (!this.formData[category][deliverer])
        this.formData[category][deliverer] = [];

      if (!this.formData[category][deliverer][id])
        this.formData[category][deliverer][id] = inputValue;
      else
        this.formData[category][deliverer][id] = inputValue;

      return this.formData;
    },

    editComment(value) {
      this.formIsDirty();
      let val = value === null || value === undefined ? '' : value;
      event.target.innerHTML = '<input class="additionalInput form-control form-control-sm" type="text" name="comments" value="' + val + '">';

    },

    editApprovalDate(value) {
      this.formIsDirty();
      let val = value === null ? this.getToday() : value;
      event.target.innerHTML = '<input class="additionalInput form-control form-control-sm" type="date" name="approval_date" value="' + val + '">';
    },

    submitForm() {
      this.isProcessing = true;
      const self = this;
      const data = this.formData;

      axios.post(route('project.selected-suppliers.update', {project: self.project.id, data})).then(response => {

        this.formIsNotDirty();
        this.isProcessing = false;

        this.changeInputsToDiv();

        Toast.fire({
          toast: true,
          icon: response.data.icon,
          title: response.data.title,
          html: response.data.message,
          showClass: {popup: 'animate__animated animate__fadeInDown'},
          hideClass: {popup: 'animate__animated animate__fadeOutUp'},
          timer: 2500,
        })


      }).catch(error => {
        console.log(error);
        Toast.fire({
          toast: true,
          icon: error.data.icon,
          title: error.data.title,
          html: error.data.message,
          showClass: {popup: 'animate__animated animate__fadeInDown'},
          hideClass: {popup: 'animate__animated animate__fadeOutUp'},
          timer: 2500,
        })
      })
    },

    changeInputsToDiv() {
      $.each($('.additionalInput'), function (idx, input) {
        $(input).parent().html($(input).val());
        $(input).remove();
      })
    },

    getToday() {
      const date = new Date();
      var year = date.getFullYear();
      var month = date.getMonth() + 1; // Miesiące są indeksowane od 0, więc dodajemy 1
      var day = date.getDate();

      return year + '-' + (month < 10 ? '0' : '') + month + '-' + (day < 10 ? '0' : '') + day;
    }
  }
}
</script>
