<template>

    <button type="button" class="btn btn-sm btn-outline-info" data-bs-toggle="modal" data-bs-target="#modalHistory" @click="getHistoryForProject">
        <i class="bi bi-clock-history"></i>&nbsp; Historia zmian
    </button>

    <!-- Modal -->
    <div class="modal fade" id="modalHistory" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
        <div class="modal-dialog modal-full-width" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Ostatnie zmiany</h5>
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body table-responsive">
                    <table class="table table-bordered">
                        <thead>
                        <tr>
                            <th>ID</th>
                            <th>Kolumna</th>
                            <th>Stara wartość</th>
                            <th>Nowa wartość</th>
                            <th>Data aktualizacji</th>
                            <th>Aktualizował</th>
                        </tr>
                        </thead>
                        <tbody v-if="isLoading">
                        <tr>
                            <td colspan="7" class="text-center py-4">
                                <div class="spinner-border spinner-border-reverse align-self-center loader-sm text-secondary">Ładowanie...</div>
                            </td>
                        </tr>
                        </tbody>
                        <tbody v-else>
                        <tr v-for="history in histories">
                            <td>{{ history.original_id }}</td>
                            <td>{{ history.column }}</td>
                            <td>{{ history.old_value }}</td>
                            <td>{{ history.new_value }}</td>
                            <td>{{ history.created_at }}</td>
                            <td>
                                {{ history.created_by?.first_name ?? '' }} {{ history.created_by?.last_name ?? '' }}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Zamknij</button>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import route from "../../../../../vendor/tightenco/ziggy/src/js/index.js";

export default {
    props: {
        project: Object
    },

    data() {
        return {
            histories: Object,
            isLoading: false,
        }
    },

    methods: {
        getHistoryForProject() {
            const self = this;
            self.isLoading = true;

            axios.post(route('projects.risk-assessment.getHistoryForRiskAssessment', {project: self.project})).then(response => {
                self.histories = response.data;
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.isLoading = false;
            })
        }
    }

}
</script>
