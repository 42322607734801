<template>
  <div>
    <label>{{ label }}</label>

    <ejs-documenteditor
        :id="id"
        height="690px"
        :ref="refName"
        :serviceUrl="serviceUrl"
        :enableToolbar="true"
    ></ejs-documenteditor>
  </div>
</template>

<script>

import {DocumentEditorContainerComponent, Toolbar, ImageResizer, Editor} from '@syncfusion/ej2-vue-documenteditor';

import '@syncfusion/ej2-vue-documenteditor/styles/material.css';

export default {
  components: {'ejs-documenteditor': DocumentEditorContainerComponent},

  props: {
    id: String,
    label: String,
    modelName: String,
    modelText: String,
    hidden: Boolean,
    language: String,
  },

  data() {
    return {
      refName: 'ref_' + this.id,
      serviceUrl: 'https://ej2services.syncfusion.com/production/web-services/api/documenteditor/'
    };
  },

  provide: {
    DocumentEditorContainer: [Toolbar, ImageResizer, Editor]
  },

  mounted() {
    this.loadHtmlContent();
  },

  methods: {

    loadHtmlContent() {
      this.$refs[this.refName].ej2Instances.documentEditor.editor.paste(JSON.stringify(this.modelText));
    }
  }
}
</script>
