import $ from 'jquery';
// icons
import 'bootstrap-icons/font/bootstrap-icons.css';
// inertia, store, vue
import {createApp, h} from 'vue';
import {createInertiaApp, Link, router} from '@inertiajs/vue3';
import store from "./store";
// permission
import LaravelPermissionToVueJS from 'laravel-permission-to-vuejs'
// perfect scrollbar
import PerfectScrollbar from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";

import './bootstrap';
// modals
import "../assets/sass/components/custom-modal.scss";
// datatables
import './datatables-setting.js';
// sweetalerts
import 'sweetalert2/dist/sweetalert2.min.css'
import Swal from "sweetalert2";

// syncfusion
import {L10n, loadCldr, registerLicense, setCulture} from '@syncfusion/ej2-base';
import {gregorian, numberingSystems, numbers, syncfusion_pl, timeZoneNames} from "@/locales/syncfusion/pl.js";

// vueForm
import Vueform from '@vueform/vueform'
import vueformConfig  from '../library/vueform/vueform.config.js';

// select2
import 'select2/dist/css/select2.min.css';
import 'select2-bootstrap-5-theme/dist/select2-bootstrap-5-theme.min.css';
import '/resources/library/js/select2/langs/pl.js';
import select2 from 'select2';
// app settings
import appSetting from "./app-setting.js";

import route from "../../vendor/tightenco/ziggy/src/js/index.js";
// globalne makeDirty i makeClean
import makeDirtyCleanPlugin from "./makeDirtyCleanPlugin.js";

// syncfusion license
registerLicense('Ngo9BigBOggjHTQxAR8/V1NBaF5cXmZCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXpfd3ZUR2NfWEF3VkA=');

// syncfusion language settings
loadCldr(numberingSystems, gregorian, numbers, timeZoneNames);
setCulture('pl');
L10n.load(syncfusion_pl);


window.jQuery = window.$ = $

window.Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

select2({
    theme: 'bootstrap-5'
});

window.$appSetting = appSetting;
window.$appSetting.init();

const _appName = import.meta.env.VITE_APP_NAME;

createInertiaApp({
    title: (title) => `${title} - ` + _appName,
    resolve: name => {
        const pages = import.meta.glob('./Pages/**/*.vue', {eager: true})
        return pages[`./Pages/${name}.vue`]
    },
    setup({el, App, props, plugin}) {
        const myApp = createApp({render: () => h(App, props)})
            .use(plugin)
            .component('Link', Link)
            .mixin({methods: {route}})

        myApp.use(store)
            .use(LaravelPermissionToVueJS)
            .use(PerfectScrollbar)
            .use(makeDirtyCleanPlugin)
            .use(Vueform, vueformConfig)
            .mount(el);

        // informowanie przed przejściem do innych stron, jeśli są niezapisane dane
        router.on('before', (event) => {
            if (store.state.isDirty) {
                if (!confirm('Masz niezapisane dane. Czy na pewno chcesz opuścić tę stronę?')) {
                    event.preventDefault();
                } else {
                    myApp.config.globalProperties.$makeClean();
                    this.$makeClean();
                }
            }
        })
    },
    progress: {
        // settings of progressBar
        color: "orangered",
        delay: 250,
        includeCSS: true,
        showSpinner: false,
    },
}).then()


