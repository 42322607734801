<template>
  <div>

    <!-- Modal -->
    <div class="modal fade" id="offerVersionHistoryModal" tabindex="-1" role="dialog" aria-labelledby="modelTitleId"
         aria-hidden="true">
      <div class="modal-dialog modal-full-width" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title" id="modelTitleId">Historia oferty</h4>
            <button type="button" class="close ms-auto" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="container-fluid">

              <div v-if="loading">
                <div>
                  <div class="spinner-grow text-danger me-2 align-self-center loader-sm"></div>
                </div>
              </div>
              <div v-else class="table-responsive">
                <table class="table table-striped table-bordered history-table">
                  <thead>
                  <tr>
                    <th></th>
                    <th>Aktywny</th>
                    <th>Wersja</th>
                    <th>Numer</th>
                    <th>Status</th>
                    <th>Tytuł</th>
                    <th>Wstęp</th>
                    <th>Opis</th>
                    <th>Epilog</th>
                    <th>Cena</th>
                    <th>Szczegóły ceny</th>
                    <th>Waluta</th>
                    <th>Gwarancja</th>
                    <th>Opis gwarancji</th>
                    <th>Godziny reakcji serwisu</th>
                    <th>Punkty serwisowe</th>
                    <th>Dane techniczne</th>
                    <th>Zmiany techniczne</th>
                    <th>Zmiany koncepcyjne</th>
                    <th>Siła wyższa</th>
                    <th>Komentarze</th>
                    <th>Czas realizacji</th>
                    <th>Jednostka czasu realizacji</th>
                    <th>Format czasu realizacji</th>
                    <th>Czas realizacji od złożenia zamówienia</th>
                    <th>Dostawa</th>
                    <th>Kod pocztowy dostawy</th>
                    <th>Miasto dostawy</th>
                    <th>Ulica dostawy</th>
                    <th>Numer mieszkania dostawy</th>
                    <th>Warunki płatności</th>
                    <th>Wstępne zaakceptowanie FAT</th>
                    <th>Przygotowanie do wysyłki maszyny</th>
                    <th>Opis dostawy</th>
                    <th>Montowanie</th>
                    <th>Uruchomienie</th>
                    <th>Wsparcie</th>
                    <th>Końcowa akceptacja SAT</th>
                    <th>Dokumentacja</th>
                    <th>Certyfikacja CE</th>
                    <th>Data wygaśnięcia</th>
                    <th>Utworzone przez</th>
                    <th>Zmodyfikowane przez</th>
                    <th>Data utworzenia</th>
                    <th>Data modyfikacji</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(history, idx) in offerVersionHistories">
                    <td>
                      <a target="_blank" class="btn btn-xs btn-outline-info"
                         :href="route('offer-version-history.show', {'offerVersionHistory':history.id})">
                        <i class="bi bi-box-arrow-up-right me-2"></i> Podgląd
                      </a>
                    </td>
                    <td class="text-center" style="font-size: 16px !important;">
                      <div v-if="history.is_active === 1" class="text-success">
                        <i class="bi bi-check2-circle"></i>
                      </div>
                      <div v-else class="text-danger">
                        <i class="bi bi-x-circle"></i>
                      </div>
                    </td>
                    <td :class="{'border-danger': isDifferent('version', history.version, idx)}">
                      {{ history.version }}
                    </td>
                    <td :class="{'border-danger': isDifferent('number', history.number, idx)}">
                      {{ history.number }}
                    </td>
                    <td :class="{'border-danger': isDifferent('number', history.status_id, idx)}">
                      {{ history.status?.status || history.status_id }}
                    </td>
                    <td :class="{'border-danger': isDifferent('title', history.title, idx)}">
                      {{ history.title }}
                    </td>
                    <td :class="{'border-danger': isDifferent('introduction', history.introduction, idx)}">
                      <iframe :srcdoc="srcDoc(history.introduction)" readonly class="iframe"></iframe>
                    </td>
                    <td :class="{'border-danger': isDifferent('description', history.description, idx)}">
                      <iframe :srcdoc="srcDoc(history.description)" readonly class="iframe"></iframe>
                    </td>
                    <td :class="{'border-danger': isDifferent('epilogue', history.epilogue, idx)}">
                      <iframe :srcdoc="srcDoc(history.epilogue)" readonly class="iframe"></iframe>
                    </td>
                    <td :class="{'border-danger': isDifferent('price', history.price, idx)}">
                      {{ history.price }}
                    </td>
                    <td :class="{'border-danger': isDifferent('price_details', history.price_details, idx)}">
                      <iframe :srcdoc="srcDoc(history.price_details)" readonly class="iframe"></iframe>
                    </td>
                    <td :class="{'border-danger': isDifferent('currency', history.currency, idx)}">
                      {{ history.currency }}
                    </td>
                    <td :class="{'border-danger': isDifferent('warranty', history.warranty, idx)}">
                      <iframe :srcdoc="srcDoc(history.warranty)" readonly class="iframe"></iframe>
                    </td>
                    <td :class="{'border-danger': isDifferent('warranty_description', history.warranty_description, idx)}">
                      <iframe :srcdoc="srcDoc(history.warranty_description)" readonly class="iframe"></iframe>
                    </td>
                    <td :class="{'border-danger': isDifferent('service_response_hours', history.service_response_hours, idx)}">
                      {{ history.service_response_hours }}
                    </td>
                    <td :class="{'border-danger': isDifferent('service_points', history.service_points, idx)}">
                      <iframe :srcdoc="srcDoc(history.service_points)" readonly class="iframe"></iframe>
                    </td>
                    <td :class="{'border-danger': isDifferent('technical_data', history.technical_data, idx)}">
                      <iframe :srcdoc="srcDoc(history.technical_data)" readonly class="iframe"></iframe>
                    </td>
                    <td :class="{'border-danger': isDifferent('technical_changes', history.technical_changes, idx)}">
                      <iframe :srcdoc="srcDoc(history.technical_changes)" readonly class="iframe"></iframe>
                    </td>
                    <td :class="{'border-danger': isDifferent('conceptual_changes', history.conceptual_changes, idx)}">
                      <iframe :srcdoc="srcDoc(history.conceptual_changes)" readonly class="iframe"></iframe>
                    </td>
                    <td :class="{'border-danger': isDifferent('force_majeure', history.force_majeure, idx)}">
                      <iframe :srcdoc="srcDoc(history.force_majeure)" readonly class="iframe"></iframe>
                    </td>
                    <td :class="{'border-danger': isDifferent('comments', history.comments, idx)}">
                      <iframe :srcdoc="srcDoc(history.comments)" readonly class="iframe"></iframe>
                    </td>
                    <td :class="{'border-danger': isDifferent('completion_time', history.completion_time, idx)}">
                      {{ history.completion_time }}
                    </td>
                    <td :class="{'border-danger': isDifferent('completion_time_unit', history.completion_time_unit, idx)}">
                      {{ history.completion_time_unit }}
                    </td>
                    <td :class="{'border-danger': isDifferent('completion_time_format', history.completion_time_format, idx)}">
                      {{ history.completion_time_format }}
                    </td>
                    <td :class="{'border-danger': isDifferent('completion_time_start_from_contract_submission', history.completion_time_start_from_contract_submission, idx)}">
                      {{ history.completion_time_start_from_contract_submission }}
                    </td>
                    <td :class="{'border-danger': isDifferent('delivery', history.delivery, idx)}">
                      {{ history.delivery }}
                    </td>
                    <td :class="{'border-danger': isDifferent('zip_code_delivery', history.zip_code_delivery, idx)}">
                      {{ history.zip_code_delivery }}
                    </td>
                    <td :class="{'border-danger': isDifferent('city_delivery', history.city_delivery, idx)}">
                      {{ history.city_delivery }}
                    </td>
                    <td :class="{'border-danger': isDifferent('street_delivery', history.street_delivery, idx)}">
                      {{ history.street_delivery }}
                    </td>
                    <td :class="{'border-danger': isDifferent('apartment_delivery', history.apartment_delivery, idx)}">
                      {{ history.apartment_delivery }}
                    </td>
                    <td :class="{'border-danger': isDifferent('term_of_payment', history.term_of_payment, idx)}">
                      <iframe :srcdoc="srcDoc(history.term_of_payment)" readonly class="iframe"></iframe>
                    </td>
                    <td :class="{'border-danger': isDifferent('initial_FAT_acceptance', history.initial_FAT_acceptance, idx)}">
                      <iframe :srcdoc="srcDoc(history.initial_FAT_acceptance)" readonly class="iframe"></iframe>
                    </td>
                    <td :class="{'border-danger': isDifferent('preparation_for_shipping_machine', history.preparation_for_shipping_machine, idx)}">
                      <iframe :srcdoc="srcDoc(history.preparation_for_shipping_machine)" readonly
                              class="iframe"></iframe>
                    </td>
                    <td :class="{'border-danger': isDifferent('delivery_desc', history.delivery_desc, idx)}">
                      <iframe :srcdoc="srcDoc(history.delivery_desc)" readonly class="iframe"></iframe>
                    </td>
                    <td :class="{'border-danger': isDifferent('assembly', history.assembly, idx)}">
                      <iframe :srcdoc="srcDoc(history.assembly)" readonly class="iframe"></iframe>
                    </td>
                    <td :class="{'border-danger': isDifferent('launch', history.launch, idx)}">
                      <iframe :srcdoc="srcDoc(history.launch)" readonly class="iframe"></iframe>
                    </td>
                    <td :class="{'border-danger': isDifferent('support', history.support, idx)}">
                      <iframe :srcdoc="srcDoc(history.support)" readonly class="iframe"></iframe>
                    </td>
                    <td :class="{'border-danger': isDifferent('final_SAT_acceptance', history.final_SAT_acceptance, idx)}">
                      <iframe :srcdoc="srcDoc(history.final_SAT_acceptance)" readonly class="iframe"></iframe>
                    </td>
                    <td :class="{'border-danger': isDifferent('documentation', history.documentation, idx)}">
                      <iframe :srcdoc="srcDoc(history.documentation)" readonly class="iframe"></iframe>
                    </td>
                    <td :class="{'border-danger': isDifferent('ce_certification', history.ce_certification, idx)}">
                      <iframe :srcdoc="srcDoc(history.ce_certification)" readonly class="iframe"></iframe>
                    </td>
                    <td :class="{'border-danger': isDifferent('expiration_date', history.expiration_date, idx)}">
                      {{ history.expiration_date }}
                    </td>
                    <td>
                      {{ history.created_by?.first_name }} {{ history.created_by?.last_name }}
                    </td>
                    <td :class="{'border-danger': isDifferent('updated_by', history.updated_by, idx)}">
                      {{ history.updated_by?.first_name }} {{ history.updated_by?.last_name }}
                    </td>
                    <td :class="{'border-danger': isDifferent('created_at', history.created_at, idx)}">
                      {{ history.created_at }}
                    </td>
                    <td :class="{'border-danger': isDifferent('updated_at', history.updated_at, idx)}">
                      {{ history.updated_at }}
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>

            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Zamknij</button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  props: {
    offerVersionId: Number
  },

  data() {
    return {
      loading: false,
      offerVersionHistories: null
    }
  },

  mounted() {
    // Nasłuchiwanie na zdarzenie otwierania modalu
    const modal = document.getElementById('offerVersionHistoryModal');
    modal.addEventListener('shown.bs.modal', this.handleModalOpen);
  },

  beforeDestroy() {
    // Usuwanie nasłuchiwania na zdarzenie
    const modal = document.getElementById('offerVersionHistoryModal');
    modal.removeEventListener('shown.bs.modal', this.handleModalOpen);
  },

  watch: {
    offerVersionId(newVal) {
      if (newVal) {
        this.getOfferHistory();
      }
    }
  },

  methods: {
    handleModalOpen() {
      if (this.offerVersionId) {
        this.getOfferHistory();
      }
    },

    getOfferHistory() {
      this.loading = true;
      axios.get(route('offer-version-history.get', {offer_version_id: this.offerVersionId})).then(response => {
        this.offerVersionHistories = response.data.history;
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        this.loading = false;
      });
    },

    isDifferent(column, currentValue, index) {
      if (index === this.offerVersionHistories.length - 1) {
        return false; // Ostatni wiersz nie ma następnego wiersza do porównania
      }

      const nextItem = this.offerVersionHistories[index + 1];
      return nextItem[column] !== currentValue;
    },

    srcDoc(content) {
      const styles = `
    <style>
      body {
        font-family: 'Nunito', sans-serif;
        font-size: 8pt;
        color:#35363b;
        line-height: 1.5;

      }
    </style>
  `;

      return `${styles}${content}`;
    }
  }
}
</script>

<style scoped>
.history-table td {
  font-family: 'Nunito', sans-serif;
  font-size: 8pt;
  letter-spacing: 0.0312rem;
  line-height: 1.5;
  color: #000;
}

.border-danger {
  border: 2px solid orangered;
}

.iframe {
  width: 600px;
  height: 250px;
}
</style>