<template>
  <AppLayout>
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
      <div class="widget widget-six">
        <div class="widget-heading">
          <div class="row">
            <div class="col-xl-12 col-md-12 col-sm-12 col-12 d-inline-flex align-items-center">
              <h4 class="me-3 my-0">Lista kontrahentów</h4>
              <Link :href="route('customers.create')" class="btn btn-outline-primary">
                <i class="bi bi-plus-circle"></i>&nbsp;Utwórz kontrahenta
              </Link>
            </div>
          </div>
        </div>

        <div class="widget-content">
          <div class="custom-table table-responsive">
            <table ref="dataTables" class="table table-hover"/>
          </div>
        </div>

      </div>
    </div>
  </AppLayout>
</template>

<script>
import AppLayout from "@/Layouts/App.vue";
import {usePage} from "@inertiajs/vue3";

export default {
  components: {AppLayout},
  name: 'DataTable',

  mounted() {
    const {data} = usePage().props;
    this.user = usePage().props.user;
    this.initDataTable(data);
    this.initFilters();
  },
  methods: {
    initDataTable() {
      const self = this;
      const csrfToken = document.head.querySelector('meta[name="csrf-token"]').content;
      this.table = $(this.$refs.dataTables).DataTable({
        colReorder: true,
        stateSave: true,
        serverSide: true,
        processing: true,
        ajax: {
          url: '/get-customers-datatables', // endpoint do pobierania danych z serwera
          type: 'GET',
          data: function (params) {
            params.additional_param = 'value'; // Dodaj dodatkowe parametry, jeśli są potrzebne
            return params;
          },
          dataSrc: 'data',
        },
        stateSaveCallback: function (settings, data) {
          $.ajax({
            url: '/save-column-data?table=customers',
            data: data,
            type: 'POST',
            dataType: 'json',
            headers: {
              'X-CSRF-TOKEN': csrfToken, // Include the CSRF token in the headers
            },
            "success": function () {
              const datTab = $('.dataTable');
              // generuj link do wersji oferty
              let link = datTab.find('a[data-edit]');
              link.on('click', function (event) {
                event.preventDefault();
                const id = $(this).data('edit');
                self.generateEditCustomerLink(id);
              });

            }
          });
        },
        stateLoadCallback: function (settings) {
          var o;
          $.ajax({
            url: '/get-table-data?table=customers',
            async: false,
            dataType: 'json',
            success: function (json) {
              o = json;
            }
          });
          let elem = this;
          self.initColumnSearch(elem, o)

          return o;
        },
        columns: [
          {
            data: 'id',
            title: '#',
            name: 'id',
            width: "5%",
            isUnique: true,
            render: function (data) {
              return '<a class="text-primary" href="#" data-edit="' + data + '">' + data + '</a>';
            },
          },
          {
            data: 'name',
            name: 'name',
            title: 'Nazwa',
            width: "25%",
            render: function (data, idx, row) {
              return '<a class="text-primary" href="#" data-edit="' + row.id + '">' + data + '</a>';
            },
          },
          {data: 'short_name', title: 'Nazwa skrócona', width: "15%"},
          {
            data: 'recipient',
            title: 'Odbiorca',
            width: "5%",
            render: function (d) {
              if (d === 1) {
                return '<span class="d-none">1</span><i class="bi bi-check text-success fs-2"></i>';
              }

              return '<span class="d-none">0</span><i class="bi bi-x text-danger fs-2"></i>';
            }
          },
          {
            data: 'supplier',
            title: 'Dostawca',
            width: "5%",
            render: function (d) {
              if (d === 1) {
                return '<span class="d-none">1</span><i class="bi bi-check text-success fs-2"></i>';
              }

              return '<span class="d-none">0</span><i class="bi bi-x text-danger fs-2"></i>';
            }
          },
          {data: 'nip', title: 'Nip', "type": "number", width: "5%"},
          {
            data: function (row) {
              return row.customers_data?.zip_code ?? ''
            },
            name: 'customers_data.zip_code',
            title: 'Kod pocztowy',
            width: "10%",
          },
          {
            data: function (row) {
              let address = row.customers_data?.address ?? ''
              let address2 = row.customers_data?.address2 ?? ''
              return address + ' ' + address2;
            },
            name: "customers_data.concatAddress",
            title: 'Adres',
            width: "25%",
          },
        ],
        createdRow: function (row, data, dataIndex) {
          // po kliknieciu w 'td' przenies do edycji customera
          const link = $(row).find('a[data-edit]');
          link.on('click', function (event) {
            event.preventDefault();
            const id = $(this).data('edit');
            self.generateEditCustomerLink(id);
          });
        },
      });
    },
    generateEditCustomerLink(id) {
      this.$inertia.visit(route('customers.show', {customer: id}));
    },
    initColumnSearch(elem, o) {
      setTimeout(() => {
        var api = elem.api();
        api.columns()
            .eq(0)
            .each(function (colIdx) {
              var cell = $('.filters td').eq(
                  $(api.column(colIdx).header()).index()
              );
              var title_cell = $('th').eq(
                  $(api.column(colIdx).header()).index()
              );

              let searchText = o.columns[colIdx].search.search
              if (searchText !== null && searchText !== undefined && typeof searchText === 'string') {
                var cleanSearchText = searchText.replace(/\(|\)/g, '');
              }

              if (cleanSearchText) {
                $(cell).html('<input type="text" value=' + cleanSearchText + ' />');
              } else {
                let title = $(title_cell).text();
                $(cell).html('<input type="text" placeholder="' + title + '" />');
              }

              $('input',
                  $('.filters td').eq($(api.column(colIdx).header()).index()))
                  .off('keyup change')
                  .on('change', function (e) {
                    $(this).attr('title', $(this).val());
                    api.column($(this).parent().index()).search(this.value).draw();
                  })
                  .on('keyup', function (e) {
                    e.stopPropagation();
                    $(this).trigger('change');
                    $(this)
                        .focus()[0]
                        .setSelectionRange(this.selectionStart, this.selectionStart);
                  });
            });
      }, 50);
    },

    // Dodawanie dodatkowych inputów pod headerem do filtrowania
    initFilters() {
      const headerRow = $(this.$refs.dataTables).find('thead tr');
      const inputRow = document.createElement('tr');

      headerRow.find('th').each(function (index) {
        const inputCell = document.createElement('td');
        const input = document.createElement('input');
        input.type = 'text';
        input.placeholder = 'Filter...';

        $(inputCell).addClass('filters').css('max-width', '30px');
        $(inputCell).addClass('filters').append(input);
        $(inputRow).addClass('filters').append(inputCell);
      });

      headerRow.after(inputRow);
    },
  },
}

</script>
