<template>
  <h5 id="additionalArrangements" class="mb-2">Dodatkowe ustalenia</h5>
  <form method="post" @submit.prevent="handleSubmitAdditionalArrEmails"
        :action="route('project.show.update-additional-arrangements-emails', project)">
    <select id="select_additionalArrangementsEmail" class="select form-select form-select-sm" multiple v-model="emails">
      <option v-for="user in users" :key="user.id" :value="user.email">{{ user.email }}</option>
    </select>
    <button id="saveAdditionalEmails" :disabled="isProcessing" class="btn btn-sm btn-primary mt-2" type="submit"
            style="display: none">Zapisz
    </button>
  </form>

  <table class="mt-3 table table-responsive table-striped table-bordered">
    <thead>
    <tr>
      <th>Opis</th>
      <th>Wewnętrzne / z klientem</th>
      <th>Utworzył</th>
      <th>Pliki</th>
      <th></th>
    </tr>
    </thead>
    <tbody>
    <tr v-if="!isEditing" v-for="additional in additionalArrangements2">
      <td style="width: 50%">{{ additional.description || '' }}</td>
      <td>{{ additional.internal || '' }}</td>
      <td>{{ additional.created_by.first_name ?? '' }} {{ additional.created_by.last_name ?? '' }}</td>
      <td>
        <template v-if="additional.file_url">
          <a class="text-primary" :href="getFullFileUrl(additional.file_url)" target="_blank" download>
            {{ getFileName(additional.file_url) }}
          </a>
        </template>
      </td>
      <td class="text-end">
        <button type="button" class="mb-1 btn btn-sm btn-warning" title="Edytuj ustalenie"
                @click="editAdditionalArrangement(additional.id)">
          <i class="bi bi-pen me-2"></i>Edytuj
        </button>
        <button data-bs-toggle="modal" data-bs-target="#modalTasks"
                class="mb-1 ms-2 btn btn-sm py-1 px-3 btn-outline-primary" title="Edytuj zadania"
                @click="getTaskData(additional.id)">
          <i class="bi bi-card-checklist" style="font-size: 1rem"></i></button>
      </td>
    </tr>
    <tr v-else v-for="(additional, idx) in additionalArrangements2">
      <td>
        <textarea class="form-control form-control-sm" type="text" v-model="additional.description"/>
      </td>
      <td>
        <input class="form-control form-control-sm" type="text" v-model="additional.internal"/>
      </td>
      <td></td>
      <td>
        <input type="file" name="file" @change="updateFile(idx, $event)">
      </td>
      <td class="text-end">
        <button type="button" class="mb-1 me-2 btn btn-sm btn-primary" :disabled="isProcessing"
                @click="updateAdditionalArrangement()">
          <i class="bi bi-save me-2"></i>Zapisz
        </button>
        <button type="button" class="mb-1 btn btn-sm btn-outline-warning" @click="cancelEditAdditionalArrangement()"
                :disabled="isProcessing">Anuluj
        </button>
      </td>
    </tr>
    <tr v-if="isAddingAdditionalArrangement">
      <td>
        <textarea class="form-control form-control-sm" type="text" v-model="newAdditional.description" required/>
      </td>
      <td>
        <input class="form-control form-control-sm" type="text" v-model="newAdditional.internal" required/>
      </td>
      <td>
        <input type="file" name="file" @change="addNewFile($event)">
      </td>
      <td></td>
      <td class="text-end">
        <button type="button" class="btn btn-sm btn-primary" @click="storeAdditionalArrangement">
          <i class="bi bi-save me-2"></i>Zapisz
        </button>
      </td>
    </tr>
    </tbody>
  </table>

  <!-- Modal -->
  <div class="modal fade" id="modalTasks" tabindex="-1" role="dialog" aria-labelledby="modalTasks" aria-hidden="true">
    <div class="modal-dialog modal-full-width" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Zadania przypisane do ustalenia</h5>
          <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body pt-1" style="min-height: 300px;">
          <a class="text-success" :href="route('projects.show.tasks', project.id)"> Przejdź do wszystkich zadań</a>
          <div class="loader-box-loadingTasks d-none"
               style="position:absolute; height: 100%; width: 100%; z-index: 99; text-align: center; background-color: rgba(255,255,255,.7); display: flex;justify-content: center;align-items: center;">
            <div class="loader"></div>
            <div class="position-absolute" style="top:75%">Ładowanie zadań</div>
          </div>

          <div class="mt-4">
            <h4>Zadania</h4>

            <ProjectsTasksGrid
                :projectId='project.id'
                :notDoneOnly="false"
                :usersCanChange="usersCanChange"
                :assignedToArrangement="additionalArrangementId">
            </ProjectsTasksGrid>

          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-primary" data-bs-dismiss="modal">Zamknij</button>
        </div>
      </div>
    </div>
  </div>

  <button type="button" class="btn btn-sm btn-outline-primary" @click="isAddingAdditionalArrangement= true">Dodaj nowe
    ustalenie
  </button>
</template>

<script>
import route from "../../../../../vendor/tightenco/ziggy/src/js/index.js";
import ProjectsTasksGrid from "@/Pages/Projects/Components/ProjectsTasksGrid.vue";

export default {
  components: {ProjectsTasksGrid},
  props: {
    project: Object,
    users: Object,
    additionalArrangements: Object,
    usersCanChange: Array
  },

  data() {
    let newAdditional = {
      'description': null,
      'internal': null
    }

    return {
      emails: [],
      additionalArrangements2: this.additionalArrangements,
      isEditing: false,
      isProcessing: false,
      isAddingAdditionalArrangement: false,
      newAdditional,
      tasksData: [],
      usersEmails: null,
      additionalArrangementId: null,
    }
  },

  mounted() {

    let sel2 = $('#select_additionalArrangementsEmail').select2({
      multiple: true,
      placeholder: 'Wybierz maile',
      theme: 'bootstrap-5'
    }).on('select2:select select2:unselect', (e) => {
      $('#saveAdditionalEmails').show();
    })

    sel2.on('select2:select select2:unselect', (e) => {
      this.updateAddArrEmails(sel2.val());
      $('#saveAdditionalEmails').show();
    });

    sel2.val(JSON.parse(this.project.project_additional_arrangements_emails)).trigger('change');
  },

  computed: {
    baseUrl() {
      return window.location.origin; // Pobiera bazowy URL aplikacji
    }
  },

  methods: {
    route,
    editAdditionalArrangement(id) {
      this.isEditing = id;
    },

    cancelEditAdditionalArrangement() {
      this.isEditing = false;
    },

    updateAddArrEmails(selectedEmails) {
      this.emails = selectedEmails || [];
    },

    updateFile(idx, event) {
      this.additionalArrangements2[idx].file = event.target.files[0];
    },

    addNewFile(event) {
      this.newAdditional.file = event.target.files[0];
    },

    getFullFileUrl(fileUrl){
      return `${this.baseUrl}/storage/${fileUrl}`;
    },

    getFileName(fileUrl){
      return fileUrl.split('/').pop();
    },

    handleSubmitAdditionalArrEmails() {
      this.isEditing = true;
      this.isProcessing = true;
      axios.post(route('project.show.update-additional-arrangements-emails', this.project), {emails: this.emails}).then(response => {
        Toast.fire({
          position: 'top-end',
          toast: true,
          icon: 'success',
          title: 'Sukces',
          html: 'Poprawnie zapisano maile',
          showClass: {popup: 'animate__animated animate__fadeInDown'},
          hideClass: {popup: 'animate__animated animate__fadeOutUp'},
          timer: 2500,
          timerProgressBar: true,
          showConfirmButton: false,
          showCloseButton: true
        });
      }).catch(error => {
        console.log(error.response.data.message);
        Toast.fire({
          position: 'top-end',
          toast: true,
          icon: 'error',
          title: 'Błąd',
          html: error.response.data.message,
          showClass: {popup: 'animate__animated animate__fadeInDown'},
          hideClass: {popup: 'animate__animated animate__fadeOutUp'},
          timer: 2500,
          timerProgressBar: true,
          showConfirmButton: false,
          showCloseButton: true
        });
      }).finally(() => {
        this.isEditing = false;
        this.isProcessing = false;
      });
    },

    updateAdditionalArrangement() {
      this.isProcessing = true;

      // Tworzenie obiektu FormData
      const formData = new FormData();

      // Przejdź przez wszystkie ustalenia i dodaj je do FormData
      this.additionalArrangements2.forEach((arrangement, index) => {
        formData.append(`additionalArrangements[${index}][id]`, arrangement.id);
        formData.append(`additionalArrangements[${index}][description]`, arrangement.description);
        formData.append(`additionalArrangements[${index}][internal]`, arrangement.internal);

        // Dodaj plik, jeśli został wybrany
        if (arrangement.file) {
          formData.append(`file_${arrangement.id}`, arrangement.file);
        }
      });

      axios.post(route('project.show.update-additional-arrangements', {project: this.project.id}), formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then(response => {
        Toast.fire({
          position: 'top-end',
          toast: true,
          icon: 'success',
          title: 'Sukces',
          html: 'Poprawnie zapisano dodatkowe ustalenia',
          showClass: {popup: 'animate__animated animate__fadeInDown'},
          hideClass: {popup: 'animate__animated animate__fadeOutUp'},
          timer: 2500,
          timerProgressBar: true,
          showConfirmButton: false,
          showCloseButton: true,
        });
      }).catch(error => {
        console.log(error);
        Toast.fire({
          position: 'top-end',
          toast: true,
          icon: 'error',
          title: 'Błąd',
          html: error.response.data.message,
          showClass: {popup: 'animate__animated animate__fadeInDown'},
          hideClass: {popup: 'animate__animated animate__fadeOutUp'},
          timer: 2500,
          timerProgressBar: true,
          showConfirmButton: false,
          showCloseButton: true,
        });
      }).finally(() => {
        this.isEditing = false;
        this.isProcessing = false;
      });
    },

    storeAdditionalArrangement() {
      const self = this;

      // Tworzenie obiektu FormData
      const formData = new FormData();

      // Dodanie nowych danych ustaleń do FormData
      formData.append('newAdditionalArrangement[description]', this.newAdditional.description);
      formData.append('newAdditionalArrangement[internal]', this.newAdditional.internal);

      // Dodaj plik, jeśli został wybrany
      if (this.newAdditional.file) {
        formData.append('newAdditionalArrangement[file]', this.newAdditional.file);
      }

      axios.post(route('project.show.store-additional-arrangements', {project: this.project.id}), formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then(response => {
        if (response.data.status === 'success') {
          self.newAdditional = {
            description: null,
            internal: null,
            file: null // Zresetuj wybrany plik
          };
          self.additionalArrangements2 = response.data.additionalArrangements;
          Toast.fire({
            position: 'top-end',
            toast: true,
            icon: 'success',
            title: 'Sukces',
            html: 'Poprawnie zapisano dodatkowe ustalenia',
            showClass: {popup: 'animate__animated animate__fadeInDown'},
            hideClass: {popup: 'animate__animated animate__fadeOutUp'},
            timer: 2500,
            timerProgressBar: true,
            showConfirmButton: false,
            showCloseButton: true
          });
          self.isAddingAdditionalArrangement = false;
        } else {
          console.log(response);
          Toast.fire({
            position: 'top-end',
            toast: true,
            icon: 'error',
            title: 'Błąd',
            html: response.data.message,
            showClass: {popup: 'animate__animated animate__fadeInDown'},
            hideClass: {popup: 'animate__animated animate__fadeOutUp'},
            timer: 2500,
            timerProgressBar: true,
            showConfirmButton: false,
            showCloseButton: true
          });
        }
      }).catch(error => {
        console.log(error.response.message);
        Toast.fire({
          position: 'top-end',
          toast: true,
          icon: 'error',
          title: 'Błąd',
          html: error.response.message,
          showClass: {popup: 'animate__animated animate__fadeInDown'},
          hideClass: {popup: 'animate__animated animate__fadeOutUp'},
          timer: 2500,
          timerProgressBar: true,
          showConfirmButton: false,
          showCloseButton: true
        });
      }).finally(() => {
        this.isProcessing = false;
      });
    },

    getTaskData(arrangementId) {
      this.additionalArrangementId = arrangementId;
    },

    async updateTaskCell(newValue, oldData, row, column) {
      const self = this;
      // jeżeli faktycznie coś się zmieniło, a nie user skacze sobie po polach
      if (newValue === null && oldData === null)
        return false;

      if (!row.id) {
        $('.loader-box-task').removeClass('d-none');
      }

      await axios.post(route('projects.show.tasks.updateTask', self.project.id), {
        'id': row.id,
        'column': column.name,
        'newValue': newValue
      }).then(response => {
        row.id = response.data.task.id;
        row.created_by_name = response.data.task.created_by_name;
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        $('.loader-box-task').addClass('d-none');
      })
    }
  }
}
</script>
