<template>
  <div v-if="$page.props.errors">
    <ul style="list-style-type: none; padding: 0" v-for="error in $page.props.errors">
      <li class="alert alert-danger">{{ error }}</li>
    </ul>
  </div>

  <div class="card-body">
    <div class="row">
      <div class="col-md-12 col-lg-12">
        <form @submit.prevent="form.put(route('customers.update', form.id))">

          <div class="d-inline-flex">
            <h4 class="me-3 my-0 mb-2">Kontrahent</h4>

            <div class="checkbox-success custom-control custom-checkbox text-color me-2">
              <input type="checkbox" class="custom-control-input" id="recipient"
                     v-model="form.recipient"/>
              <label class="custom-control-label" for="recipient">Odbiorca</label>
            </div>

            <div class="checkbox-success custom-control custom-checkbox text-color me-2">
              <input type="checkbox" class="custom-control-input" id="supplier"
                     v-model="form.supplier"/>
              <label class="custom-control-label" for="supplier">Dostawca</label>
            </div>
          </div>

            <div class="row">
              <!-- First Line -->
              <div class="col-lg-3 col-md-6 col-sm-12 mb-3">
                <label class="form-label" for="name">Nazwa:</label>
                <div v-if="form.errors.name" class="text-danger">{{ form.errors.name }}</div>
                <input class="form-control" id="name" v-model="form.name"/>
              </div>

              <div class="col-lg-3 col-md-6 col-sm-12 mb-3">
                <label class="form-label" for="short_name">Krótka Nazwa:</label>
                <div v-if="form.errors.short_name" class="text-danger">{{ form.errors.short_name }}</div>
                <input class="form-control" id="short_name" v-model="form.short_name"/>
              </div>

              <div class="col-lg-3 col-md-6 col-sm-12 mb-3">
                <label class="form-label" for="nip">Nip:</label>
                <div v-if="form.errors.nip" class="text-danger">{{ form.errors.nip }}</div>
                <input class="form-control" id="nip" v-model="form.nip"/>
              </div>

              <div class="col-lg-3 col-md-6 col-sm-12 mb-3">
                <label class="form-label" for="zip_code">Kod pocztowy:</label>
                <div v-if="form.errors.zip_code" class="text-danger">{{ form.errors.zip_code }}</div>
                <input class="form-control" id="zip_code" v-model="form.zip_code"/>
              </div>

              <!-- Second Line -->
              <div class="col-lg-3 col-md-6 col-sm-12 mb-3">
                <label class="form-label" for="city">Miasto:</label>
                <div v-if="form.errors.city" class="text-danger">{{ form.errors.city }}</div>
                <input class="form-control" id="city" v-model="form.city"/>
              </div>

              <div class="col-lg-3 col-md-6 col-sm-12 mb-3">
                <label class="form-label" for="address">Adres (ulica i nr budynku):</label>
                <div v-if="form.errors.address" class="text-danger">{{ form.errors.address }}</div>
                <input class="form-control" id="address" v-model="form.address"/>
              </div>

              <div class="col-lg-3 col-md-6 col-sm-12 mb-3">
                <label class="form-label" for="address2">Nr lokalu:</label>
                <div v-if="form.errors.address2" class="text-danger">{{ form.errors.address2 }}</div>
                <input class="form-control" id="address2" v-model="form.address2"/>
              </div>

            </div>

            <div class="mt-3" v-if="form.isDirty">Istnieją niezapisane dane w formularzu</div>
            <button class="btn btn-primary mt-3" type="submit" :disabled="form.processing">Zapisz</button>
        </form>
      </div>

    </div>

    <div class="row mt-5">

      <div class="col-md-12 col-lg-12">
        <ContactPersonCrud :customerId="customer.id"
                           :contacts="customer.customers_contacts"></ContactPersonCrud>
      </div>
    </div>
  </div>
</template>

<script>
import ContactPersonCrud from "./ContactPersonCrud.vue";
import {useForm} from "@inertiajs/vue3";
import {ref} from "vue";

export default {
  components: {ContactPersonCrud},
  props: {
    customer: Object
  },

  setup(props) {

    const data = {
      id: props.customer.id,
      name: props.customer.name,
      short_name: props.customer.short_name,
      recipient: !!props.customer.recipient,
      supplier: !!props.customer.supplier,
      nip: props.customer.nip,
      company: props.customer.customers_data?.company,
      zip_code: props.customer.customers_data?.zip_code,
      city: props.customer.customers_data?.city,
      address: props.customer.customers_data?.address,
      address2: props.customer.customers_data?.address2,
      contacts: props.customer.customers_contacts || [],
    };

    const form = useForm(data);

    return {
      form
    };
  }
}
</script>