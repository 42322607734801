import * as numberingSystems from '@/locales/syncfusion/numberingSystems.json';
import * as gregorian from '@/locales/syncfusion/ca-gregorian.json';
import * as numbers from '@/locales/syncfusion/numbers.json';
import * as timeZoneNames from '@/locales/syncfusion/timeZoneNames.json';

export {
    numberingSystems,
    gregorian,
    numbers,
    timeZoneNames,
};
export const syncfusion_pl = {
    "pl": {
        "grid": {
            "EmptyRecord": "Brak danych do wyświetlenia",
            "True": "prawdziwe",
            "False": "fałszywy",
            "InvalidFilterMessage": "Nieprawidłowe dane filtru",
            "GroupDropArea": "Przeciągnij nagłówek kolumny tutaj, aby zgrupować kolumnę",
            "UnGroup": "Kliknij tutaj, aby rozgrupować",
            "GroupDisable": "Grupowanie jest wyłączone dla tej kolumny",
            "FilterbarTitle": "komórka paska filtru",
            "EmptyDataSourceError": "DataSource nie może być pusta przy pierwszym ładowaniu, ponieważ kolumny są generowane z dataSource w AutoGenerate Column Grid",
            "Add": "Dodaj",
            "Edit": "Edytuj",
            "Cancel": "Anuluj",
            "Update": "Aktualizuj",
            "Delete": "Usuń",
            "Print": "Drukuj",
            "Pdfexport": "Eksport do pliku PDF",
            "Excelexport": "Eksport do Excela",
            "Wordexport": "Eksport słów",
            "Csvexport": "Eksport CSV",
            "Search": "Szukaj",
            "Columnchooser": "Kolumny",
            "Save": "Zapisz",
            "Item": "pozycja",
            "Items": "przedmiotów",
            "EditOperationAlert": "Nie wybrano rekordów do operacji edycji",
            "DeleteOperationAlert": "Nie wybrano rekordów do operacji usuwania",
            "SaveButton": "Zapisz",
            "OKButton": "OK",
            "CancelButton": "Anuluj",
            "EditFormTitle": "Szczegóły",
            "AddFormTitle": "Dodaj nowy rekord",
            "BatchSaveConfirm": "Czy na pewno chcesz zapisać zmiany?",
            "BatchSaveLostChanges": "Niezapisane zmiany zostaną utracone. Jesteś pewien, że chcesz kontynuować?",
            "ConfirmDelete": "Czy na pewno chcesz usunąć rekord?",
            "CancelEdit": "Czy na pewno chcesz anulować zmiany?",
            "ChooseColumns": "Wybierz kolumnę",
            "SearchColumns": "kolumny wyszukiwania",
            "Matchs": "Nie znaleziono żadnego meczu",
            "FilterButton": "Filtr",
            "ClearButton": "Wyczyść",
            "StartsWith": "Zaczynać z",
            "EndsWith": "Kończy się na",
            "Contains": "Zawiera",
            "Equal": "Równy",
            "NotEqual": "Nie równe",
            "LessThan": "Mniej niż",
            "LessThanOrEqual": "Mniej niż równe",
            "GreaterThan": "Lepszy niż",
            "GreaterThanOrEqual": "Większy bądź równy",
            "ChooseDate": "Wybierz datę",
            "EnterValue": "Wpisz wartość",
            "Copy": "Kopiuj",
            "Group": "Grupuj według tej kolumny",
            "Ungroup": "Rozgrupuj według tej kolumny",
            "autoFitAll": "Automatyczne dopasowanie wszystkich kolumn",
            "autoFit": "Automatycznie dopasuj tę kolumnę",
            "Export": "Eksport",
            "FirstPage": "Pierwsza strona",
            "LastPage": "Ostatnia strona",
            "PreviousPage": "Poprzednia strona",
            "NextPage": "Następna strona",
            "SortAscending": "Sortuj rosnąco",
            "SortDescending": "Sortuj malejąco",
            "EditRecord": "Edytuj rekord",
            "DeleteRecord": "Usuń rekord",
            "FilterMenu": "Filtr",
            "SelectAll": "Zaznacz wszystko",
            "Blanks": "Puste",
            "FilterTrue": "Prawdziwe",
            "FilterFalse": "Fałszywy",
            "NoResult": "Nie znaleziono żadnego meczu",
            "ClearFilter": "Czysty filtr",
            "NumberFilter": "Filtry liczbowe",
            "TextFilter": "Filtry tekstowe",
            "DateFilter": "Filtry daty",
            "DateTimeFilter": "Filtry DateTime",
            "MatchCase": "Sprawa meczu",
            "Between": "pomiędzy",
            "CustomFilter": "Filtr niestandardowy",
            "CustomFilterPlaceHolder": "Wpisz wartość",
            "CustomFilterDatePlaceHolder": "Wybierz datę",
            "AND": "I",
            "OR": "LUB",
            "ShowRowsWhere": "Pokaż wiersze, w których:",
            "NotStartsWith": "Nie zaczyna się od",
            "Like": "Tak jak",
            "NotEndsWith": "Nie kończy się na",
            "NotContains": "Nie zawiera",
            "IsNull": "Zero",
            "NotNull": "Nie jest zerem",
            "IsEmpty": "Pusty",
            "IsNotEmpty": "Nie pusty",
            "AddCurrentSelection": "Dodaj bieżący wybór do filtra",
            "UnGroupButton": "Kliknij tutaj, aby rozgrupować",
            "AutoFitAll": "Automatycznie dopasuj wszystkie kolumny",
            "AutoFit": "Automatycznie dopasuj tę kolumnę",
            "Clear": "przejaśniać",
            "FilterMenuDialogARIA": "Okno dialogowe menu filtrów",
            "ExcelFilterDialogARIA": "Okno dialogowe filtra Excel",
            "DialogEditARIA": "Edytuj okno dialogowe",
            "ColumnChooserDialogARIA": "Selektor kolumn",
            "ColumnMenuDialogARIA": "Okno dialogowe menu kolumny",
            "CustomFilterDialogARIA": "Okno dialogowe filtra niestandardowego",
            "SortAtoZ": "Sortuj od A do Z",
            "SortZtoA": "Sortuj od Z do A",
            "SortByOldest": "Sortuj według najstarszych",
            "SortByNewest": "Sortuj według najnowszych",
            "SortSmallestToLargest": "Sortuj od najmniejszego do największego",
            "SortLargestToSmallest": "Sortuj od największego do najmniejszego",
            "Sort": "przesortować",
            "FilterDescription": "Naciśnij Alt w dół, aby otworzyć menu filtrów",
            "SortDescription": "Naciśnij Enter, aby posortować",
            "ColumnMenuDescription": "Naciśnij klawisz Alt w dół, aby otworzyć menu kolumnowe",
            "GroupDescription": "Naciśnij Ctrl spację, aby pogrupować",
            "ColumnHeader": " nagłówek kolumny ",
            "TemplateCell": " jest komórką szablonu",
            "CommandColumnAria": "jest nagłówkiem kolumny poleceń ",
            "DialogEdit": "Edycja okna dialogowego",
            "ClipBoard": "schowek",
            "GroupButton": "Przycisk grupowy",
            "UnGroupAria": "przycisk rozgrupowania",
            "GroupSeperator": "Separator dla zgrupowanych kolumn",
            "UnGroupIcon": "rozgrupuj zgrupowaną kolumnę ",
            "GroupedSortIcon": "posortuj zgrupowaną kolumnę ",
            "GroupedDrag": "Przeciągnij zgrupowaną kolumnę",
            "GroupCaption": " jest komórka napisów grupowych",
            "CheckBoxLabel": "pole wyboru",
            "Expanded": "Rozszerzony",
            "Collapsed": "Zwinięty",
            "SelectAllCheckbox": "Zaznacz pole wyboru Wszystkie",
            "SelectRow": "Wybierz wiersz"
        },
        "pager": {
            "currentPageInfo": "{0} z {1} stron",
            "totalItemsInfo": "({0} przedmiotów)",
            "firstPageTooltip": "Idź do pierwszej strony",
            "lastPageTooltip": "Idź do ostatniej strony",
            "nextPageTooltip": "Przejdź do następnej strony",
            "previousPageTooltip": "Wróć do poprzedniej strony",
            "nextPagerTooltip": "Przejdź do następnych pozycji pagera",
            "previousPagerTooltip": "Przejdź do poprzednich elementów pagera",
            "pagerDropDown": "przedmioty na stronę",
            "pagerAllDropDown": "Przedmiotów",
            "All": "Wszystko",
            "totalItemInfo": "({0} pozycja)",
            "Container": "Kontener pagera",
            "Information": "Informacje o pagerze",
            "ExternalMsg": "Zewnętrzna wiadomość na pager",
            "Page": "Strona ",
            "Of": " z ",
            "Pages": " Strony"
        },
        "kanban": {
            "items": "przedmiotów",
            "min": "Min",
            "max": "Maks",
            "cardsSelected": "Wybrane karty",
            "addTitle": "Dodaj nową kartę",
            "editTitle": "Edytuj szczegóły karty",
            "deleteTitle": "Usuń kartę",
            "deleteContent": "Czy na pewno chcesz usunąć tę kartę?",
            "save": "Zapisz",
            "delete": "Usuń",
            "cancel": "Anuluj",
            "yes": "tak",
            "no": "Nie",
            "close": "Blisko",
            "noCard": "Brak kart do wyświetlenia",
            "unassigned": "Nieprzypisany",
            "cards": "wózek sklepowy"
        },
        "dropdowns": {
            "noRecordsTemplate": "Nic nie znaleziono",
            "actionFailureTemplate": "Żądanie nie powiodło się",
            "overflowCountTemplate": "+${count} więcej ..",
            "selectAllText": "Zaznacz wszystko",
            "unSelectAllText": "Odznacz wszystko",
            "totalCountTemplate": "Wybrano ${count}"
        },
        "drop-down-list": {
            "noRecordsTemplate": "Nic nie znaleziono",
            "actionFailureTemplate": "Żądanie nie powiodło się"
        },
        "combo-box": {
            "noRecordsTemplate": "Nic nie znaleziono",
            "actionFailureTemplate": "Żądanie nie powiodło się"
        },
        "auto-complete": {
            "noRecordsTemplate": "Nic nie znaleziono",
            "actionFailureTemplate": "Żądanie nie powiodło się"
        },
        "multi-select": {
            "noRecordsTemplate": "Nic nie znaleziono",
            "actionFailureTemplate": "Żądanie nie powiodło się",
            "overflowCountTemplate": "+${count} więcej ..",
            "selectAllText": "Zaznacz wszystko",
            "unSelectAllText": "Odznacz wszystko",
            "totalCountTemplate": "Wybrano ${count}"
        },
        "listbox": {
            "noRecordsTemplate": "Nic nie znaleziono",
            "actionFailureTemplate": "Żądanie nie powiodło się",
            "selectAllText": "Zaznacz wszystko",
            "unSelectAllText": "Odznacz wszystko",
            "moveUp": "Podnieść",
            "moveDown": "Padnij",
            "moveTo": "Przenieś do",
            "moveFrom": "Przenieść się z",
            "moveAllTo": "Przenieś wszystko do",
            "moveAllFrom": "Przenieś wszystko z"
        },
        "spreadsheet": {
            "InsertingEmptyValue": "Wartość referencyjna jest nieprawidłowa.",
            "FindValue": "Znajdź wartość",
            "ReplaceValue": "Zastąp wartość",
            "FindReplaceTooltip": "Znaleźć zamiennik",
            "ByRow": "W rzędach",
            "ByColumn": "Według kolumn",
            "MatchExactCellElements": "Dopasuj całą zawartość komórki",
            "EnterCellAddress": "Podaj adres komórki",
            "FindAndReplace": "Znajdź i zamień",
            "ReplaceAllEnd": " mecze zastąpione przez",
            "FindNextBtn": "Znajdź następny",
            "FindPreviousBtn": "Znajdź poprzedni",
            "ReplaceBtn": "Zastąpić",
            "ReplaceAllBtn": "Zamień wszystko",
            "GotoHeader": "Iść do",
            "Sheet": "Arkusz",
            "SearchWithin": "Szukaj wewnątrz",
            "SearchBy": "Szukaj według",
            "Reference": "Odniesienie",
            "Workbook": "zeszyt ćwiczeń",
            "NoElements": "Nie mogliśmy znaleźć tego, czego szukałeś.",
            "FindWhat": "Znajdź co",
            "ReplaceWith": "Zamienić",
            "FileName": "Nazwa pliku",
            "ExtendValidation": "Zaznaczenie zawiera komórki bez sprawdzania poprawności danych. Czy chcesz rozszerzyć sprawdzanie poprawności na te komórki?",
            "Yes": "tak",
            "No": "Nie",
            "PROPER": "Konwertuje tekst na właściwą wielkość liter; pierwsza litera na wielką, a pozostałe na małą.",
            "Cut": "Skaleczenie",
            "Copy": "Kopiuj",
            "Paste": "Pasta",
            "PasteSpecial": "Wklej specjalnie",
            "All": "Wszystko",
            "Values": "Wartości",
            "Formats": "formaty",
            "Font": "Czcionka",
            "FontSize": "Rozmiar czcionki",
            "Bold": "Pogrubienie",
            "Italic": "italski",
            "Underline": "Podkreślać",
            "Strikethrough": "Przekreślenie",
            "TextColor": "Kolor tekstu",
            "FillColor": "Kolor wypełnienia",
            "HorizontalAlignment": "Wyrównanie poziome",
            "AlignLeft": "Wyrównaj do lewej",
            "AlignCenter": "Centrum",
            "AlignRight": "Wyrównaj do prawej",
            "VerticalAlignment": "Wyrównanie w pionie",
            "AlignTop": "Wyrównaj do góry",
            "AlignMiddle": "Wyrównaj do środka",
            "AlignBottom": "Wyrównaj do dołu",
            "MergeCells": "Połącz komórki",
            "MergeAll": "Połącz wszystko",
            "MergeHorizontally": "Połącz poziomo",
            "MergeVertically": "Połącz w pionie",
            "Unmerge": "Rozdziel",
            "UnmergeCells": "Rozdziel komórki",
            "SelectMergeType": "Wybierz typ scalania",
            "MergeCellsAlert": "Scalanie komórek zachowa tylko wartość skrajnie lewego górnego (najwyższego). Scalić mimo to?",
            "PasteMergeAlert": "Nie możemy tego zrobić z komórką scalającą.",
            "Borders": "Granice",
            "TopBorders": "Górne granice",
            "LeftBorders": "Lewe granice",
            "RightBorders": "Prawe granice",
            "BottomBorders": "Dolne granice",
            "AllBorders": "Wszystkie granice",
            "HorizontalBorders": "Granice poziome",
            "VerticalBorders": "Granice pionowe",
            "OutsideBorders": "Granice zewnętrzne",
            "InsideBorders": "Granice wewnętrzne",
            "NoBorders": "Brak granic",
            "BorderColor": "Kolor ramki",
            "BorderStyle": "Styl obramowania",
            "InsertFunction": "Wstaw funkcję",
            "Insert": "Wstaw",
            "Delete": "Usuń",
            "DuplicateSheet": "Duplikować",
            "MoveRight": "Ruch w prawo",
            "MoveLeft": "Przesuń w lewo",
            "Rename": "Przemianować",
            "Hide": "Ukrywać",
            "NameBox": "Pole nazwy",
            "ShowHeaders": "Pokaż nagłówki",
            "HideHeaders": "Ukryj nagłówki",
            "ShowGridLines": "Pokaż linie siatki",
            "HideGridLines": "Ukryj linie siatki",
            "FreezePanes": "Zamroź okienka",
            "FreezeRows": "Zamroź wiersze",
            "FreezeColumns": "Zablokuj kolumny",
            "UnfreezePanes": "Odblokuj okienka",
            "UnfreezeRows": "Odblokuj wiersze",
            "UnfreezeColumns": "Odblokuj kolumny",
            "AddSheet": "Dodaj arkusz",
            "ListAllSheets": "Wyświetl listę wszystkich arkuszy",
            "CollapseToolbar": "Zwiń pasek narzędzi",
            "ExpandToolbar": "Rozwiń pasek narzędzi",
            "CollapseFormulaBar": "Zwiń pasek formuły",
            "ExpandFormulaBar": "Rozwiń pasek formuły",
            "File": "Plik",
            "Home": "Dom",
            "Formulas": "Formuły",
            "View": "Pogląd",
            "New": "Nowy",
            "Open": "otwarty",
            "SaveAs": "Zapisz jako",
            "ExcelXlsx": "Microsoft Excel",
            "ExcelXls": "Microsoft Excel 97-2003",
            "CSV": "Wartości oddzielone przecinkami",
            "FormulaBar": "Pasek Formuły",
            "Sort": "Sortować",
            "SortAscending": "rosnąco",
            "SortDescending": "malejąco",
            "CustomSort": "Sortowanie niestandardowe",
            "AddColumn": "Dodaj kolumnę",
            "ContainsHeader": "Dane zawierają nagłówek",
            "CaseSensitive": "Wielkość liter ma znaczenie",
            "SortBy": "Sortuj według",
            "ThenBy": "Następnie przez",
            "SelectAColumn": "Wybierz kolumnę",
            "SortEmptyFieldError": "Wszystkie kryteria sortowania muszą mieć określoną kolumnę. Sprawdź wybrane kryteria sortowania i spróbuj ponownie.",
            "SortDuplicateFieldError": " jest sortowany według wartości więcej niż jeden raz. Usuń zduplikowane kryteria sortowania i spróbuj ponownie.",
            "SortOutOfRangeError": "Wybierz komórkę lub zakres w używanym zakresie i spróbuj ponownie.",
            "MultiRangeSortError": "Nie można tego zrobić w przypadku wyboru wielu zakresów. Wybierz pojedynczy zakres i spróbuj ponownie.",
            "HideRow": "Ukryj wiersz",
            "HideRows": "Ukryj wiersze",
            "UnhideRows": "Odkryj wiersze",
            "HideColumn": "Ukryj kolumnę",
            "HideColumns": "Ukryj kolumny",
            "UnhideColumns": "Odkryj kolumny",
            "InsertRow": "Wstaw wiersz",
            "InsertRows": "Wstaw wiersze",
            "Above": "Powyżej",
            "Below": "Poniżej",
            "InsertColumn": "Wstaw kolumnę",
            "InsertColumns": "Wstaw kolumny",
            "Before": "Przed",
            "After": "Po",
            "DeleteRow": "Usuń wiersz",
            "DeleteRows": "Usuń wiersze",
            "DeleteColumn": "Usuń kolumnę",
            "DeleteColumns": "Usuń kolumny",
            "Ok": "OK",
            "Close": "Zamknąć",
            "MoreOptions": "Więcej możliwości",
            "Cancel": "Anuluj",
            "Apply": "Zastosuj",
            "MoreColors": "Więcej kolorów",
            "StandardColors": "Standardowe kolory",
            "General": "Ogólny",
            "Number": "Numer",
            "Currency": "Waluta",
            "Accounting": "Rachunkowość",
            "ShortDate": "Krótka randka",
            "LongDate": "Długa randka",
            "Time": "Czas",
            "Percentage": "Odsetek",
            "Fraction": "Frakcja",
            "Scientific": "Naukowy",
            "Text": "Tekst",
            "NumberFormat": "Format liczbowy",
            "MobileFormulaBarPlaceHolder": "Wprowadź wartość lub formułę",
            "PasteAlert": "Nie możesz tego tutaj wkleić, ponieważ obszar kopiowania i obszar wklejania nie mają tego samego rozmiaru. Spróbuj wkleić w innym zakresie.",
            "DestroyAlert": "Czy na pewno chcesz zniszczyć bieżący skoroszyt bez zapisywania i utworzyć nowy skoroszyt?",
            "SheetRenameInvalidAlert": "Nazwa arkusza zawiera nieprawidłowy znak.",
            "SheetRenameEmptyAlert": "Nazwa arkusza nie może być pusta.",
            "SheetRenameAlreadyExistsAlert": "Nazwa arkusza już istnieje. Wprowadź inną nazwę.",
            "DeleteSheetAlert": "Czy na pewno chcesz usunąć ten arkusz?",
            "DeleteSingleLastSheetAlert": "Skoroszyt musi zawierać co najmniej jeden widoczny arkusz.",
            "PickACategory": "Wybierz kategorię",
            "Description": "Opis",
            "UnsupportedFile": "Nie wspierany plik",
            "DataLimitExceeded": "Dane w pliku są zbyt duże i ich przetwarzanie zajmuje więcej czasu, czy chcesz kontynuować?",
            "FileSizeLimitExceeded": "Rozmiar pliku jest zbyt duży i jego przetwarzanie zajmuje więcej czasu, czy chcesz kontynuować?",
            "InvalidUrl": "nieprawidłowy URL",
            "SUM": "Dodaje serię liczb i/lub komórek.",
            "SUMIF": "Dodaje komórki na podstawie określonego warunku.",
            "SUMIFS": "Dodaje komórki na podstawie określonych warunków.",
            "ABS": "Zwraca wartość liczby bez jej znaku.",
            "RAND": "Zwraca losową liczbę z przedziału od 0 do 1.",
            "RANDBETWEEN": "Zwraca losową liczbę całkowitą na podstawie określonych wartości.",
            "FLOOR": "Zaokrągla liczbę w dół do najbliższej wielokrotności danego czynnika.",
            "CEILING": "Zaokrągla liczbę w górę do najbliższej wielokrotności danego czynnika.",
            "PRODUCT": "Mnoży serię liczb i/lub komórek.",
            "AVERAGE": "Oblicza średnią dla serii liczb i/lub komórek z wyłączeniem tekstu.",
            "AVERAGEIF": "Oblicza średnią dla komórek na podstawie określonego kryterium.",
            "AVERAGEIFS": "Oblicza średnią dla komórek na podstawie określonych warunków.",
            "AVERAGEA": "Oblicza średnią dla komórek oceniających PRAWDA jako 1, tekst i FAŁSZ jako 0.",
            "COUNT": "Zlicza komórki zawierające wartości liczbowe w zakresie.",
            "COUNTIF": "Zlicza komórki na podstawie określonego warunku.",
            "COUNTIFS": "Zlicza komórki na podstawie określonych warunków.",
            "COUNTA": "Zlicza komórki zawierające wartości z zakresu.",
            "MIN": "Zwraca najmniejszą liczbę podanych argumentów.",
            "MAX": "Zwraca największą liczbę podanych argumentów.",
            "DATE": "Zwraca datę na podstawie podanego roku, miesiąca i dnia.",
            "DAY": "Zwraca dzień z podanej daty.",
            "DAYS": "Zwraca liczbę dni między dwiema datami.",
            "IF": "Zwraca wartość na podstawie podanego wyrażenia.",
            "IFS": "Zwraca wartość na podstawie podanych wielokrotnych wyrażeń.",
            "CalculateAND": "Zwraca PRAWDA, jeśli wszystkie argumenty są PRAWDĄ, w przeciwnym razie zwraca FAŁSZ.",
            "CalculateOR": "Zwraca PRAWDA, jeśli którykolwiek z argumentów jest PRAWDĄ, w przeciwnym razie zwraca FAŁSZ.",
            "IFERROR": "Zwraca wartość, jeśli nie znaleziono błędu, w przeciwnym razie zwróci określoną wartość.",
            "CHOOSE": "Zwraca wartość z listy wartości na podstawie numeru indeksu.",
            "INDEX": "Zwraca wartość komórki w podanym zakresie na podstawie numeru wiersza i kolumny.",
            "FIND": "Zwraca pozycję łańcucha w innym ciągu, w którym rozróżniana jest wielkość liter.",
            "CONCATENATE": "Łączy ze sobą dwa lub więcej ciągów.",
            "CONCAT": "Łączy listę lub zakres ciągów tekstowych.",
            "SUBTOTAL": "Zwraca sumę częściową dla zakresu przy użyciu podanego numeru funkcji.",
            "RADIANS": "Konwertuje stopnie na radiany.",
            "MATCH": "Zwraca względną pozycję określonej wartości w podanym zakresie.",
            "SLOPE": "Zwraca nachylenie linii z regresji liniowej punktów danych.",
            "INTERCEPT": "Oblicza punkt linii przecięcia z osią Y za pomocą regresji liniowej.",
            "UNIQUE": "Zwraca unikalne wartości z zakresu lub tablicy",
            "TEXT": "Konwertuje wartość na tekst w określonym formacie liczbowym.",
            "DefineNameExists": "Ta nazwa już istnieje, wypróbuj inną.",
            "CircularReference": "Gdy formuła odwołuje się do jednego lub więcej odwołań cyklicznych, może to spowodować nieprawidłowe obliczenie.",
            "SORT": "Sortuje zakres tablicy",
            "T": "Sprawdza, czy wartość jest tekstem, czy nie, i zwraca tekst.",
            "EXACT": "Sprawdza, czy dwa ciągi tekstowe są dokładnie takie same i zwraca PRAWDA lub FAŁSZ.",
            "LEN": "Zwraca liczbę znaków w podanym ciągu.",
            "MOD": "Zwraca resztę z dzielenia liczby przez dzielnik.",
            "ODD": "Zaokrągla liczbę dodatnią w górę i liczbę ujemną w dół do najbliższej nieparzystej liczby całkowitej.",
            "PI": "Zwraca wartość pi.",
            "COUNTBLANK": "Zwraca liczbę pustych komórek w określonym zakresie komórek.",
            "EVEN": "Zaokrągla liczbę dodatnią w górę i liczbę ujemną w dół do najbliższej parzystej liczby całkowitej.",
            "DECIMAL": "Konwertuje tekstową reprezentację liczby o podanej podstawie na liczbę dziesiętną.",
            "ADDRESS": "Zwraca odwołanie do komórki w postaci tekstu z podanymi numerami wierszy i kolumn.",
            "CHAR": "Zwraca znak z podanej liczby.",
            "CODE": "Zwraca kod liczbowy dla pierwszego znaku w danym ciągu.",
            "DOLLAR": "Konwertuje liczbę na tekst sformatowany według waluty.",
            "SMALL": "Zwraca k-tą najmniejszą wartość w podanej tablicy.",
            "LARGE": "Zwraca k-tą największą wartość w podanej tablicy.",
            "TIME": "Konwertuje godziny, minuty, sekundy na tekst sformatowany według czasu.",
            "DEGREES": "Konwertuje radiany na stopnie.",
            "FACT": "Zwraca silnię liczby.",
            "MEDIAN": "Zwraca medianę podanego zestawu liczb.",
            "EDATE": "Zwraca datę o podanej liczbie miesięcy przed lub po określonej dacie.",
            "DATEVALUE": "Konwertuje ciąg daty na wartość daty.",
            "NOW": "Zwraca bieżącą datę i godzinę.",
            "HOUR": "Zwraca liczbę godzin w określonym ciągu czasowym.",
            "MINUTE": "Zwraca liczbę minut w określonym ciągu czasowym.",
            "SECOND": "Zwraca liczbę sekund w określonym ciągu czasowym.",
            "MONTH": "Zwraca liczbę miesięcy w określonym ciągu daty.",
            "OR": "LUB",
            "AND": "ORAZ",
            "CustomFilterDatePlaceHolder": "Wybierz datę",
            "CustomFilterPlaceHolder": "Wprowadź wartość",
            "CustomFilter": "Filtr niestandardowy",
            "Between": "pomiędzy",
            "MatchCase": "Sprawa meczu",
            "DateTimeFilter": "Filtry daty i godziny",
            "Undo": "Cofnij",
            "Redo": "Przerobić",
            "ClearAllFilter": "Jasne",
            "ReapplyFilter": "Zastosuj ponownie",
            "DateFilter": "Filtry daty",
            "TextFilter": "Filtry tekstowe",
            "NumberFilter": "Filtry liczbowe",
            "ClearFilter": "Czysty filtr",
            "NoResult": "Nie znaleziono żadnego meczu",
            "FilterFalse": "Fałszywe",
            "FilterTrue": "Prawdziwe",
            "Blanks": "Puste miejsca",
            "SelectAll": "Zaznacz wszystko",
            "GreaterThanOrEqual": "Większy bądź równy",
            "GreaterThan": "Lepszy niż",
            "LessThanOrEqual": "Mniej niż lub równo",
            "LessThan": "Mniej niż",
            "NotEqual": "Nie równe",
            "Equal": "Równy",
            "Contains": "Zawiera",
            "NotContains": "Nie zawiera",
            "EndsWith": "Kończy się na",
            "NotEndsWith": "Nie Kończy Się Z",
            "StartsWith": "Zaczynać z",
            "NotStartsWith": "Nie zaczyna się od",
            "IsEmpty": "Pusty",
            "IsNotEmpty": "Nie pusty",
            "ClearButton": "Jasne",
            "FilterButton": "Filtr",
            "CancelButton": "Anuluj",
            "OKButton": "OK",
            "Search": "Szukaj",
            "DataValidation": "Walidacji danych",
            "CellRange": "Zakres komórek",
            "Allow": "Umożliwić",
            "Data": "Dane",
            "Minimum": "Minimum",
            "Maximum": "Maksymalny",
            "IgnoreBlank": "Ignoruj ​​puste",
            "WholeNumber": "Cały numer",
            "Decimal": "Dziesiętny",
            "Date": "Data",
            "TextLength": "Długość tekstu",
            "List": "Lista",
            "NotBetween": "Nie pomiędzy",
            "EqualTo": "Równy",
            "NotEqualTo": "Nie równy",
            "GreaterThanOrEqualTo": "Większe bądź równe",
            "LessThanOrEqualTo": "Mniejszy lub równy",
            "InCellDropDown": "Lista rozwijana w komórce",
            "Sources": "Źródła",
            "Value": "Wartość",
            "Retry": "Spróbować ponownie",
            "DialogError": "Źródłem listy musi być odwołanie do pojedynczego wiersza lub kolumny.",
            "MinMaxError": "Wartość maksymalna musi być większa lub równa wartości minimalnej.",
            "Spreadsheet": "Arkusz",
            "MoreValidation": "Ten wybór zawiera więcej niż jedną weryfikację.",
            "FileNameError": "Nazwa pliku nie może zawierać znaków takich jak \\ / : * ? \" < > [ ] |",
            "ValidationError": "Ta wartość nie odpowiada ograniczeniom sprawdzania poprawności danych zdefiniowanym dla komórki.",
            "ListLengthError": "Wartości listy dopuszczają maksymalnie 256 znaków",
            "ProtectSheet": "Chroń arkusz",
            "UnprotectSheet": "Usuń ochronę arkusza",
            "SelectCells": "Wybierz zablokowane komórki",
            "SelectUnlockedCells": "Wybierz odblokowane komórki",
            "Save": "Ratować",
            "EmptyFileName": "Nazwa pliku nie może być pusta.",
            "LargeName": "Nazwa jest za długa.",
            "FormatCells": "Sformatuj komórki",
            "FormatRows": "Sformatuj wiersze",
            "FormatColumns": "Sformatuj kolumny",
            "InsertLinks": "Wstaw linki",
            "ProtectContent": "Chroń zawartość zamkniętych komórek",
            "ProtectAllowUser": " Zezwól wszystkim użytkownikom tego arkusza na:",
            "EditAlert": "Komórka, którą próbujesz zmienić, jest chroniona. Aby wprowadzić zmiany, wyłącz ochronę arkusza.",
            "ClearValidation": "Wyczyść walidację",
            "ISNUMBER": "Zwraca wartość true, gdy wartość jest analizowana jako wartość liczbowa.",
            "ROUND": "Zaokrągla liczbę do określonej liczby cyfr.",
            "GEOMEAN": "Zwraca średnią geometryczną tablicy lub zakresu danych dodatnich.",
            "POWER": "Zwraca wynik liczby podniesionej do potęgi",
            "LOG": "Zwraca logarytm liczby do określonej podstawy.",
            "TRUNC": "Zwraca obciętą wartość liczby z dokładnością do określonej liczby miejsc dziesiętnych.",
            "EXP": "Zwraca e podniesione do potęgi podanej liczby.",
            "LOOKUP": "Znajduje wartość w zakresie wierszy lub kolumn i zwraca wartość w tym samym miejscu w zakresie wierszy lub kolumn.",
            "HLOOKUP": "Znajduje wartość w górnym wierszu tabeli wartości i zwraca wartość w tej samej kolumnie określonego wiersza tabeli.",
            "VLOOKUP": "Znajduje wartość określoną w pierwszej kolumnie zakresu wyszukiwania i zwraca odpowiadającą wartość w drugiej kolumnie wiersza.",
            "NOT": "Zwraca odwrotność określonego wyrażenia logicznego.",
            "EOMONTH": "Zwraca ostatni dzień miesiąca przed i po określonej liczbie miesięcy od pierwszej określonej daty początkowej.",
            "SQRT": "Zwraca pierwiastek kwadratowy z liczby dodatniej.",
            "ROUNDDOWN": "Zaokrąglij liczbę do 0.",
            "RSQ": "Zwraca kwadratowy współczynnik korelacji momentu iloczynu Pearsona na podstawie punktów danych znane_y i znane_x.",
            "HighlightCellsRules": "Podświetl reguły komórek",
            "CFEqualTo": "Równy",
            "TextThatContains": "Tekst, który zawiera",
            "ADateOccuring": "Występująca data",
            "DuplicateValues": "Zduplikowane wartości",
            "TopBottomRules": "Zasady góra/dół",
            "Top10Items": "10 najlepszych pozycji",
            "Top10": "10 najlepszych",
            "Bottom10Items": "Najniższe 10 pozycji",
            "Bottom10": "Dół 10",
            "AboveAverage": "Powyżej średniej",
            "BelowAverage": "Poniżej średniej",
            "FormatCellsGreaterThan": "Sformatuj komórki WIĘKSZE NIŻ:",
            "FormatCellsLessThan": "Sformatuj komórki, które są MNIEJSZE NIŻ:",
            "FormatCellsBetween": "Sformatuj komórki, które znajdują się POMIĘDZY:",
            "FormatCellsEqualTo": "Sformatuj komórki RÓWNE TO:",
            "FormatCellsThatContainTheText": "Sformatuj komórki zawierające tekst:",
            "FormatCellsThatContainADateOccurring": "Sformatuj komórki zawierające datę:",
            "FormatCellsDuplicate": "Sformatuj komórki, które zawierają:",
            "FormatCellsTop": "Sformatuj komórki, które znajdują się w TOP:",
            "FormatCellsBottom": "Sformatuj komórki, które znajdują się na DOLNYM miejscu:",
            "FormatCellsAbove": "Sformatuj komórki, które są POWYŻEJ ŚREDNIEJ:",
            "FormatCellsBelow": "Sformatuj komórki, które są PONIŻEJ ŚREDNIEJ:",
            "With": "z",
            "DataBars": "Paski danych",
            "ColorScales": "Skale kolorów",
            "IconSets": "Zestawy ikon",
            "ClearRules": "Jasne zasady",
            "SelectedCells": "Wyczyść reguły z wybranych komórek",
            "EntireSheet": "Wyczyść reguły z całego arkusza",
            "LightRedFillWithDarkRedText": "Jasnoczerwone wypełnienie ciemnoczerwonym tekstem",
            "YellowFillWithDarkYellowText": "Żółte wypełnienie ciemnożółtym tekstem",
            "GreenFillWithDarkGreenText": "Zielone wypełnienie ciemnozielonym tekstem",
            "RedFill": "Czerwone wypełnienie",
            "RedText": "czerwony tekst",
            "Duplicate": "Duplikować",
            "Unique": "Jedyny w swoim rodzaju",
            "And": "oraz",
            "WebPage": "Strona internetowa",
            "ThisDocument": "Ten dokument",
            "DisplayText": "Wyświetl tekst",
            "Url": "Adres URL",
            "CellReference": "Odwołanie do komórki",
            "DefinedNames": "Zdefiniowane nazwy",
            "EnterTheTextToDisplay": "Wprowadź tekst do wyświetlenia",
            "EnterTheUrl": "Wprowadź adres URL",
            "INT": "Zwraca liczbę do najbliższej liczby całkowitej.",
            "SUMPRODUCT": "Zwraca sumę iloczynu podanych zakresów tablic.",
            "TODAY": "Zwraca bieżącą datę jako wartość daty.",
            "ROUNDUP": "Zaokrągla liczbę od zera.",
            "Link": "Połączyć",
            "Hyperlink": "Hiperłącze",
            "EditHyperlink": "Edytuj hiperłącze",
            "OpenHyperlink": "Otwórz hiperłącze",
            "RemoveHyperlink": "Usuń hiperłącze",
            "InvalidHyperlinkAlert": "Adres tej witryny jest nieprawidłowy. Sprawdź adres i spróbuj ponownie.",
            "InsertLink": "Wstaw łącze",
            "EditLink": "Edytuj łącze",
            "WrapText": "Zawijaj tekst",
            "Update": "Aktualizacja",
            "SortAndFilter": "Sortuj i filtruj",
            "Filter": "Filtr",
            "FilterCellValue": "Filtruj według wartości wybranej komórki",
            "FilterOutOfRangeError": "Wybierz komórkę lub zakres w używanym zakresie i spróbuj ponownie.",
            "ClearFilterFrom": "Wyczyść filtr z",
            "LN": "Zwraca logarytm naturalny liczby.",
            "DefineNameInValid": "Wprowadzona nazwa jest nieprawidłowa.",
            "EmptyError": "Musisz wprowadzić wartość",
            "ClearHighlight": "Wyczyść zaznaczenie",
            "HighlightInvalidData": "Zaznacz nieprawidłowe dane",
            "Clear": "Jasne",
            "ClearContents": "Wyczyść zawartość",
            "ClearAll": "Wyczyść wszystko",
            "ClearFormats": "Wyczyść formaty",
            "ClearHyperlinks": "Wyczyść hiperłącza",
            "Image": "Obraz",
            "ConditionalFormatting": "Formatowanie warunkowe",
            "BlueDataBar": "Niebieski pasek danych",
            "GreenDataBar": "Zielony pasek danych",
            "RedDataBar": "Czerwony pasek danych",
            "OrangeDataBar": "Pomarańczowy pasek danych",
            "LightBlueDataBar": "Jasnoniebieski pasek danych",
            "PurpleDataBar": "Fioletowy pasek danych",
            "GYRColorScale": "Skala kolorów zielony - żółty - czerwony",
            "RYGColorScale": "Skala kolorów czerwony - żółty - zielony",
            "GWRColorScale": "Zielono-biało-czerwona skala kolorów",
            "RWGColorScale": "Skala koloru czerwono-biało-zielonego",
            "BWRColorScale": "Skala kolorów niebiesko-biało-czerwonych",
            "RWBColorScale": "Skala kolorów czerwono-biało-niebieskich",
            "WRColorScale": "Skala koloru białego - czerwonego",
            "RWColorScale": "Czerwono-biała skala kolorów",
            "GWColorScale": "Zielono-biała skala kolorów",
            "WGColorScale": "Skala koloru biało-zielonego",
            "GYColorScale": "Zielono-żółta skala kolorów",
            "YGColorScale": "Skala koloru żółtego - zielonego",
            "ThreeArrowsColor": "3 strzały (kolorowe)",
            "ThreeArrowsGray": "3 strzały (szare)",
            "ThreeTriangles": "3 trójkąty",
            "FourArrowsColor": "4 strzały (szare)",
            "FourArrowsGray": "4 strzały (kolorowe)",
            "FiveArrowsColor": "5 strzał (szary)",
            "FiveArrowsGray": "5 strzał (kolorowych)",
            "ThreeTrafficLights1": "3 światła drogowe (bez oprawek)",
            "ThreeTrafficLights2": "3 światła drogowe (z oprawą)",
            "ThreeSigns": "3 Znaki",
            "FourTrafficLights": "4 światła drogowe",
            "RedToBlack": "Czerwony do czarnego",
            "ThreeSymbols1": "3 symbole (zakreślone)",
            "ThreeSymbols2": "3 symbole (bez kółek)",
            "ThreeFlags": "3 flagi",
            "ThreeStars": "3 gwiazdki",
            "FourRatings": "4 Oceny",
            "FiveQuarters": "5 kwartałów",
            "FiveRatings": "5 ocen",
            "FiveBoxes": "5 pudełek",
            "Chart": "Wykres",
            "Column": "Kolumna",
            "Bar": "Bar",
            "Area": "Powierzchnia",
            "Pie": "Ciasto",
            "Doughnut": "Pączek",
            "PieAndDoughnut": "Ciasto / Pączek",
            "Line": "Linia",
            "Radar": "Radar",
            "Scatter": "Rozpraszać",
            "ChartDesign": "Projekt wykresu",
            "ClusteredColumn": "Skupiona kolumna",
            "StackedColumn": "Ułożona kolumna",
            "StackedColumn100": "100% ułożona kolumna",
            "ClusteredBar": "Skupiony pasek",
            "StackedBar": "Ułożony pasek",
            "StackedBar100": "100% ułożony pasek",
            "StackedArea": "Ułożony obszar",
            "StackedArea100": "100% ułożony obszar",
            "StackedLine": "Ułożona linia",
            "StackedLine100": "100% skumulowana linia",
            "LineMarker": "Linia ze znacznikami",
            "StackedLineMarker": "Skumulowana linia ze znacznikami",
            "StackedLine100Marker": "100% ułożona linia z markerami",
            "AddChartElement": "Dodaj element wykresu",
            "Axes": "osie",
            "AxisTitle": "Tytuł osi",
            "ChartTitle": "Tytuł wykresu",
            "DataLabels": "Etykiety danych",
            "Gridlines": "Linie siatki",
            "Legends": "Legendy",
            "PrimaryHorizontal": "Podstawowe poziome",
            "PrimaryVertical": "Podstawowy pion",
            "None": "Nic",
            "AboveChart": "Powyżej wykresu",
            "Center": "Centrum",
            "InsideEnd": "Koniec wewnętrzny",
            "InsideBase": "Baza wewnętrzna",
            "OutsideEnd": "Koniec zewnętrzny",
            "PrimaryMajorHorizontal": "Podstawowy główny poziomy",
            "PrimaryMajorVertical": "Główny główny pion",
            "PrimaryMinorHorizontal": "Podstawowy drugorzędny poziomy",
            "PrimaryMinorVertical": "Podstawowy mniejszy pion",
            "Right": "Prawidłowy",
            "Left": "Lewy",
            "Bottom": "Dno",
            "Top": "Szczyt",
            "SwitchRowColumn": "Przełącz wiersz/kolumnę",
            "ChartTheme": "Motyw wykresu",
            "ChartType": "Typ wykresu",
            "Material": "Materiał",
            "Fabric": "Tkanina",
            "Bootstrap": "Bootstrap",
            "HighContrastLight": "Światło o wysokim kontraście",
            "MaterialDark": "Materiał Ciemny",
            "FabricDark": "Tkanina Ciemna",
            "HighContrast": "Wysoki kontrast",
            "BootstrapDark": "Ciemny Bootstrap",
            "Bootstrap4": "Bootstrap4",
            "Bootstrap5Dark": "Bootstrap5 Dark",
            "Bootstrap5": "Bootstrap5",
            "Tailwind": "Wiatr w plecy",
            "TailwindDark": "Ciemny wiatr",
            "VerticalAxisTitle": "Tytuł osi pionowej",
            "HorizontalAxisTitle": "Tytuł osi poziomej",
            "EnterTitle": "Wprowadź tytuł",
            "UnprotectWorksheet": "Usuń ochronę arkusza",
            "ReEnterPassword": "Wprowadź ponownie hasło, aby kontynuować",
            "SheetPassword": "Hasło do wyłączenia ochrony arkusza:",
            "ProtectWorkbook": "Chroń skoroszyt",
            "Password": "Hasło (opcjonalnie):",
            "EnterThePassword": "Podaj hasło",
            "ConfirmPassword": "Potwierdź hasło",
            "EnterTheConfirmPassword": "Ponownie wprowadź hasło",
            "PasswordAlert": "Hasło potwierdzające nie jest identyczne",
            "UnprotectWorkbook": "Usuń ochronę skoroszytu",
            "UnprotectPasswordAlert": "Podane hasło jest nieprawidłowe.",
            "IncorrectPassword": "Nie można otworzyć pliku lub arkusza z podanym hasłem",
            "PasswordAlertMsg": "Proszę podaj hasło",
            "ConfirmPasswordAlertMsg": "Wprowadź hasło potwierdzające",
            "IsProtected": "jest chroniony",
            "PDF": "Dokument PDF",
            "AutoFillMergeAlertMsg": "Aby to zrobić, wszystkie połączone komórki muszą mieć ten sam rozmiar.",
            "Fluent": "Biegły",
            "FluentDark": "Płynna ciemność",
            "Custom": "Zwyczaj",
            "WEEKDAY": "Zwraca dzień tygodnia odpowiadający dacie.",
            "FillSeries": "Wypełnij serię",
            "CopyCells": "Kopiuj komórki",
            "FillFormattingOnly": "Wypełnij tylko formatowanie",
            "FillWithoutFormatting": "Wypełnij bez formatowania",
            "CustomFormat": "Niestandardowe formaty liczb",
            "CustomFormatPlaceholder": "Wpisz lub wybierz format niestandardowy",
            "CustomFormatTypeList": "Rodzaj",
            "CellReferenceTypoError": "Znaleźliśmy literówkę w odwołaniu do Twojej komórki. Czy chcesz poprawić to odniesienie w następujący sposób?",
            "AddCurrentSelection": "Dodaj bieżący wybór do filtrowania",
            "ExternalWorkbook": "Zaimportowany plik programu Excel zawiera odwołanie do zewnętrznego skoroszytu. Czy chcesz zaimportować ten plik?",
            "Directional": "Kierunkowy",
            "Shapes": "Kształty",
            "Indicators": "Wskaźniki",
            "Ratings": "Oceny",
            "InvalidFormulaError": "Odkryliśmy, że wpisałeś nieprawidłową formułę.",
            "InvalidArguments": "Odkryliśmy, że wpisałeś formułę z nieprawidłowymi argumentami.",
            "EmptyExpression": "Odkryliśmy, że wpisałeś formułę zawierającą puste wyrażenie.",
            "MismatchedParenthesis": "Odkryliśmy, że wpisałeś formułę, w której brakuje jednego lub większej liczby nawiasów otwierających lub zamykających.",
            "ImproperFormula": "Ustaliliśmy, że wpisałeś niewłaściwą formułę.",
            "WrongNumberOfArguments": "Odkryliśmy, że wpisałeś formułę z nieprawidłową liczbą argumentów.",
            "Requires3Arguments": "Ustaliliśmy, że wpisałeś formułę wymagającą 3 argumentów.",
            "MismatchedStringQuotes": "Odkryliśmy, że wpisałeś formułę z niedopasowanymi cudzysłowami.",
            "FormulaCircularRef": "Ustaliliśmy, że wpisałeś formułę z odwołaniem cyklicznym."
        },
        "filemanager": {
            "NewFolder": "Nowy folder",
            "Upload": "Przekazać plik",
            "Delete": "Usunąć",
            "Rename": "Przemianować",
            "Download": "Pobieranie",
            "Cut": "Skaleczenie",
            "Copy": "Kopiuj",
            "Paste": "Pasta",
            "SortBy": "Sortuj według",
            "Refresh": "Odświeżać",
            "Item-Selection": "element wybrany",
            "Items-Selection": "wybrane elementy",
            "View": "Widok",
            "Details": "Detale",
            "SelectAll": "Zaznacz wszystko",
            "Open": "otwarty",
            "Tooltip-NewFolder": "Nowy folder",
            "Tooltip-Upload": "Przekazać plik",
            "Tooltip-Delete": "Usunąć",
            "Tooltip-Rename": "Przemianować",
            "Tooltip-Download": "Pobieranie",
            "Tooltip-Cut": "Skaleczenie",
            "Tooltip-Copy": "Kopiuj",
            "Tooltip-Paste": "Pasta",
            "Tooltip-SortBy": "Sortuj według",
            "Tooltip-Refresh": "Odświeżać",
            "Tooltip-Selection": "Wyczyść wybór",
            "Tooltip-View": "Widok",
            "Tooltip-Details": "Detale",
            "Tooltip-SelectAll": "Zaznacz wszystko",
            "Name": "Nazwa",
            "Size": "Rozmiar",
            "DateModified": "Zmodyfikowano",
            "DateCreated": "Data utworzenia",
            "Path": "Ścieżka",
            "Modified": "Zmodyfikowano",
            "Created": "Utworzony",
            "Location": "Lokalizacja",
            "Employee": "Pracownik",
            "Type": "Rodzaj",
            "Permission": "Pozwolenie",
            "Ascending": "Rosnąco",
            "Descending": "Malejąco",
            "None": "Żaden",
            "View-LargeIcons": "Duże ikony",
            "View-Details": "Detale",
            "Search": "Szukaj",
            "Button-Ok": "Ok",
            "Button-Cancel": "Anuluj",
            "Button-Yes": "tak",
            "Button-No": "Nie",
            "Button-Create": "Stwórz",
            "Button-Save": "Zapisz",
            "Header-NewFolder": "Teczka",
            "Content-NewFolder": "Wpisz nazwę swojego folderu",
            "Header-Rename": "Przemianować",
            "Content-Rename": "Wpisz swoje nowe imię",
            "Header-Rename-Confirmation": "Zmień nazwę potwierdzenia",
            "Content-Rename-Confirmation": "Jeśli zmienisz rozszerzenie nazwy pliku, plik może stać się niestabilny. Czy na pewno chcesz to zmienić?",
            "Header-Delete": "Usunąć plik",
            "Content-Delete": "Czy na pewno chcesz usunąć ten plik?",
            "Header-Folder-Delete": "Usunięty folder",
            "Content-Folder-Delete": "Czy na pewno chcesz usunąć ten folder?",
            "Header-Multiple-Delete": "Usuń wiele plików",
            "Content-Multiple-Delete": "Czy na pewno chcesz usunąć te {0} plików?",
            "Header-Duplicate": "Plik / folder istnieje",
            "Content-Duplicate": "{0} już istnieje. Czy chcesz zmienić nazwę i wkleić?",
            "Header-Upload": "Prześlij pliki",
            "Error": "Błąd",
            "Validation-Empty": "Nazwa pliku lub folderu nie może być pusta.",
            "Validation-Invalid": "Nazwa pliku lub folderu {0} zawiera nieprawidłowe znaki. Proszę użyć innej nazwy. Prawidłowe nazwy plików lub folderów nie mogą kończyć się kropką ani spacją i nie mogą zawierać żadnego z następujących znaków: \\ /: *? \"<> |",
            "Validation-NewFolder-Exists": "Plik lub folder o nazwie {0} już istnieje.",
            "Validation-Rename-Exists": "Nie można zmienić nazwy {0} na {1}: miejsce docelowe już istnieje.",
            "Folder-Empty": "Ten folder jest pusty",
            "File-Upload": "Przeciągnij pliki tutaj, aby przesłać",
            "Search-Empty": "Nie znaleziono wyników",
            "Search-Key": "Spróbuj użyć różnych słów kluczowych",
            "Filter-Empty": "Nie znaleziono wyników",
            "Filter-Key": "Spróbuj z innym filtrem",
            "Sub-Folder-Error": "Folder docelowy jest podfolderem folderu źródłowego.",
            "Same-Folder-Error": "Folder docelowy jest taki sam jak folder źródłowy.",
            "Access-Denied": "Brak dostępu",
            "Access-Details": "Nie masz uprawnień dostępu do tego folderu.",
            "Header-Retry": "Plik już istnieje",
            "Content-Retry": "Plik o tej nazwie już istnieje w tym folderze. Co chciałbyś robić?",
            "Button-Keep-Both": "Zatrzymaj oba",
            "Button-Replace": "Zastąpić",
            "Button-Skip": "Pominąć",
            "ApplyAll-Label": "Zrób to dla wszystkich bieżących przedmiotów",
            "KB": "KB",
            "Access-Message": "{0} jest niedostępny. Potrzebujesz uprawnień do wykonania akcji {1}.",
            "Network-Error": "Nie można wysłać XMLHTTPRequest: Nie udało się załadować",
            "Server-Error": "Błąd serwera: Niepoprawna odpowiedź od"
        },
        "calendar": {
            "today": "Dzisiaj"
        },
        "datepicker": {
            "today": "Dzisiaj",
            "placeholder": "Wybierz datę"
        },
        "daterangepicker": {
            "placeholder": "Wybierz zakres dat",
            "startLabel": "Data rozpoczęcia",
            "endLabel": "Data końcowa",
            "applyText": "Zastosuj",
            "cancelText": "Anuluj",
            "selectedDays": "Wybrane dni",
            "days": "Dni",
            "customRange": "Zakres niestandardowy"
        },
        "timepicker": {
            "placeholder": "Wybierz czas"
        },
        "datetimepicker": {
            "today": "Dzisiaj",
            "placeholder": "Wybierz datę i godzinę"
        },
        "dialog": {
            "close": "Blisko"
        },
        "toast": {
            "close": "Blisko"
        },
        "tab": {
            "closeButtonTitle": "Blisko"
        },
        "schedule": {
            "day": "Dzień",
            "week": "Tydzień",
            "workWeek": "Tydzień pracy",
            "month": "Miesiąc",
            "year": "Rok",
            "agenda": "Program",
            "weekAgenda": "Program tygodniowy",
            "workWeekAgenda": "Agenda Tygodnia Pracy",
            "monthAgenda": "Agenda miesiąca",
            "today": "Dzisiaj",
            "noEvents": "Brak wydarzeń",
            "emptyContainer": "Na ten dzień nie zaplanowano żadnych wydarzeń.",
            "allDay": "Cały dzień",
            "start": "Początek",
            "end": "Koniec",
            "more": "więcej",
            "close": "Blisko",
            "cancel": "Anuluj",
            "noTitle": "(Bez tytułu)",
            "delete": "Usuń",
            "deleteEvent": "Wydarzenie",
            "deleteMultipleEvent": "Usuń wiele zdarzeń",
            "selectedItems": "Wybrane elementy",
            "deleteSeries": "Cała seria",
            "edit": "Edytuj",
            "editSeries": "Cała seria",
            "editEvent": "Wydarzenie",
            "createEvent": "Stwórz",
            "subject": "Przedmiot",
            "addTitle": "Dodaj tytuł",
            "moreDetails": "Więcej szczegółów",
            "save": "Zapisz",
            "editContent": "Jak chciałbyś zmienić spotkanie w cyklu?",
            "deleteContent": "Czy na pewno chcesz usunąć to wydarzenie?",
            "deleteMultipleContent": "Czy na pewno chcesz usunąć wybrane wydarzenia?",
            "newEvent": "Nowe wydarzenie",
            "title": "Tytuł",
            "location": "Lokalizacja",
            "employee" : "Pracownik",
            "description": "Opis",
            "timezone": "Strefa czasowa",
            "startTimezone": "Uruchom strefę czasową",
            "endTimezone": "Koniec strefy czasowej",
            "repeat": "Powtarzać",
            "saveButton": "Zapisz",
            "cancelButton": "Anuluj",
            "deleteButton": "Usuń",
            "recurrence": "Nawrót",
            "wrongPattern": "Wzorzec powtarzania się jest nieprawidłowy.",
            "seriesChangeAlert": "Czy chcesz anulować zmiany wprowadzone w określonych wystąpieniach tej serii i ponownie dopasować ją do całej serii?",
            "createError": "Czas trwania wydarzenia musi być krótszy niż częstotliwość jego występowania. Skróć czas trwania lub zmień wzorzec cyklu w edytorze zdarzeń cyklicznych.",
            "sameDayAlert": "Dwa wystąpienia tego samego zdarzenia nie mogą wystąpić tego samego dnia.",
            "occurenceAlert": "Nie można przełożyć wystąpienia spotkania cyklicznego, jeśli pomija późniejsze wystąpienie tego samego spotkania.",
            "editRecurrence": "Edytuj cykl",
            "repeats": "Powtarza się",
            "alert": "Alarm",
            "startEndError": "Wybrana data końcowa występuje przed datą początkową.",
            "invalidDateError": "Wprowadzona wartość daty jest nieprawidłowa.",
            "blockAlert": "Zdarzenia nie mogą być zaplanowane w zablokowanym przedziale czasowym.",
            "ok": "Ok",
            "yes": "tak",
            "no": "Nie",
            "occurrence": "Występowanie",
            "series": "Seria",
            "previous": "Poprzedni",
            "next": "Kolejny",
            "timelineDay": "Dzień na osi czasu",
            "timelineWeek": "Tydzień na osi czasu",
            "timelineWorkWeek": "Tydzień roboczy osi czasu",
            "timelineMonth": "Miesiąc osi czasu",
            "timelineYear": "Rok na osi czasu",
            "editFollowingEvent": "Następujące wydarzenia",
            "deleteTitle": "Usuń wydarzenie",
            "editTitle": "Edytuj wydarzenie",
            "beginFrom": "Zacząć od",
            "endAt": "Koniec o",
            "expandAllDaySection": "Rozwiń sekcję całodniową",
            "collapseAllDaySection": "Zwiń sekcję całodniową",
            "searchTimezone": "Wyszukaj strefę czasową",
            "noRecords": "Nic nie znaleziono"
        },
        "recurrenceeditor": {
            "none": "Żaden",
            "daily": "Codziennie",
            "weekly": "Co tydzień",
            "monthly": "Miesięczny",
            "month": "Miesiąc",
            "yearly": "Rocznie",
            "never": "Nigdy",
            "until": "Aż do",
            "count": "Liczyć",
            "first": "Pierwszy",
            "second": "druga",
            "third": "Trzeci",
            "fourth": "Czwarty",
            "last": "Ostatni, ubiegły, zeszły",
            "repeat": "Powtarzać",
            "repeatEvery": "Powtórz każdy",
            "on": "Powtórz wł",
            "end": "Koniec",
            "onDay": "Dzień",
            "days": "Dni)",
            "weeks": "Tydzień (y)",
            "months": "Miesięcy)",
            "years": "Rok",
            "every": "każdy",
            "summaryTimes": "czasy)",
            "summaryOn": "na",
            "summaryUntil": "aż do",
            "summaryRepeat": "Powtarza się",
            "summaryDay": "dni)",
            "summaryWeek": "tydzień",
            "summaryMonth": "miesięcy)",
            "summaryYear": "rok (lata)",
            "monthWeek": "Miesiąc Tydzień",
            "monthPosition": "Pozycja miesiąca",
            "monthExpander": "Expander miesięcy",
            "yearExpander": "Expander roku",
            "repeatInterval": "Powtarzaj interwał"
        },
        "gantt": {
            "emptyRecord": "brak nagrań do odtworzenia",
            "id": "ID",
            "name": "Nazwa",
            "startDate": "Data rozpoczęcia",
            "endDate": "Data końcowa",
            "duration": "Trwanie",
            "progress": "Postęp",
            "dependency": "Zależność",
            "notes": "Notatki",
            "baselineStartDate": "Początkowa data początkowa",
            "baselineEndDate": "Wyjściowa data końcowa",
            "taskMode": "Tryb zadania",
            "changeScheduleMode": "Zmień tryb harmonogramu",
            "subTasksStartDate": "Data rozpoczęcia podzadań",
            "subTasksEndDate": "Data zakończenia podzadań",
            "scheduleStartDate": "Zaplanuj datę rozpoczęcia",
            "scheduleEndDate": "Zaplanuj datę zakończenia",
            "auto": "Automatyczny",
            "manual": "podręcznik",
            "type": "Rodzaj",
            "offset": "Offsetowy",
            "resourceName": "Zasoby",
            "resourceID": "ID zasobu",
            "day": "dzień",
            "hour": "godzina",
            "minute": "minuta",
            "days": "dni",
            "hours": "godziny",
            "minutes": "minuty",
            "generalTab": "Ogólne",
            "customTab": "Niestandardowe kolumny",
            "writeNotes": "Notuj",
            "addDialogTitle": "Nowe zadanie",
            "editDialogTitle": "Informacje o zadaniu",
            "saveButton": "Zapisz",
            "add": "Dodaj",
            "edit": "Edytuj",
            "update": "Aktualizacja",
            "delete": "Usuń",
            "cancel": "Anuluj",
            "search": "Szukaj",
            "task": " zadanie",
            "tasks": " zadania",
            "zoomIn": "Zbliż",
            "zoomOut": "Oddal",
            "zoomToFit": "Powiększ, aby dopasować",
            "excelExport": "Eksport do Excela",
            "csvExport": "Eksport CSV",
            "expandAll": "Rozwiń wszystkie",
            "collapseAll": "Zwiń wszystkie",
            "nextTimeSpan": "Następny okres",
            "prevTimeSpan": "Poprzedni okres",
            "okText": "Ok",
            "confirmDelete": "Czy na pewno chcesz usunąć rekord?",
            "from": "Od",
            "to": "Do",
            "taskLink": "Link do zadania",
            "lag": "Opóźnienie",
            "start": "Początek",
            "finish": "koniec",
            "enterValue": "Wpisz wartość",
            "taskBeforePredecessor_FS": "Przesunąłeś „{0}”, aby rozpocząć przed zakończeniem „{1}” i połączeniem dwóch zadań. W rezultacie łącza nie mogą być honorowane. Wybierz jedną akcję poniżej do wykonania",
            "taskAfterPredecessor_FS": "Przeniosłeś „{0}” z dala od „{1}” i oba zadania są połączone. W rezultacie łącza nie mogą być honorowane. Wybierz jedną akcję poniżej do wykonania",
            "taskBeforePredecessor_SS": "Przesunąłeś „{0}”, aby rozpocząć przed uruchomieniem „{1}” i dwa zadania są połączone. W rezultacie łącza nie mogą być honorowane. Wybierz jedną akcję poniżej do wykonania",
            "taskAfterPredecessor_SS": "Przesunąłeś „{0}”, aby rozpocząć po uruchomieniu „{1}” i dwa zadania są połączone. W rezultacie łącza nie mogą być honorowane. Wybierz jedną akcję poniżej do wykonania",
            "taskBeforePredecessor_FF": "Przesunąłeś „{0}”, aby zakończyć, zanim „{1}” zakończy się, a dwa zadania zostaną połączone. W rezultacie łącza nie mogą być honorowane. Wybierz jedną akcję poniżej do wykonania",
            "taskAfterPredecessor_FF": "Przesunąłeś „{0}”, aby zakończyć po zakończeniu „{1}” i dwa zadania są połączone. W rezultacie łącza nie mogą być honorowane. Wybierz jedną akcję poniżej do wykonania",
            "taskBeforePredecessor_SF": "Przeniosłeś „{0}” z „{1}” na start i oba zadania są połączone. W rezultacie łącza nie mogą być honorowane. Wybierz jedną akcję poniżej do wykonania",
            "taskAfterPredecessor_SF": "Przesunąłeś „{0}”, aby zakończyć po uruchomieniu „{1}” i połączeniu dwóch zadań. W rezultacie łącza nie mogą być honorowane. Wybierz jedną akcję poniżej do wykonania",
            "taskInformation": "Informacje o zadaniu",
            "deleteTask": "Usuń zadanie",
            "deleteDependency": "Usuń zależność",
            "convert": "Konwertować",
            "save": "Zapisz",
            "above": "Powyżej",
            "below": "Poniżej",
            "child": "Dziecko",
            "milestone": "Kamień milowy",
            "toTask": "Do zadania",
            "toMilestone": "Do Milestone",
            "eventMarkers": "Znaczniki zdarzeń",
            "leftTaskLabel": "Lewa etykieta zadania",
            "rightTaskLabel": "Właściwa etykieta zadania",
            "timelineCell": "Komórka osi czasu",
            "confirmPredecessorDelete": "Czy na pewno chcesz usunąć link zależności?",
            "unit": "Jednostka",
            "work": "Praca",
            "taskType": "Rodzaj zadania",
            "unassignedTask": "Nieprzypisane zadanie",
            "group": "Grupa",
            "indent": "Akapit",
            "outdent": "wcięcie",
            "segments": "Segmenty",
            "splitTask": "Zadanie podzielone",
            "mergeTask": "Scal zadanie",
            "left": "Lewo",
            "right": "Prawo"
        },
        "richtexteditor": {
            "alignments": "Wyrównania",
            "justifyLeft": "Wyrównaj do lewej",
            "justifyCenter": "Wyrównaj do środka",
            "justifyRight": "Wyrównaj do prawej",
            "justifyFull": "Wyrównaj Wyrównaj",
            "fontName": "Nazwa czcionki",
            "fontSize": "Rozmiar czcionki",
            "fontColor": "Kolor czcionki",
            "backgroundColor": "Kolor tła",
            "bold": "Pogrubienie",
            "italic": "italski",
            "underline": "Podkreślać",
            "strikethrough": "Przekreślenie",
            "clearFormat": "Wyczyść format",
            "clearAll": "Wyczyść wszystko",
            "cut": "Skaleczenie",
            "copy": "Kopiuj",
            "paste": "Pasta",
            "unorderedList": "Lista wypunktowana",
            "orderedList": "Lista numerowana",
            "indent": "Zwiększ wcięcie",
            "outdent": "Zmniejsz wcięcie",
            "undo": "Cofnij",
            "redo": "Przerobić",
            "superscript": "Napisany u góry",
            "subscript": "Indeks",
            "createLink": "Wstaw łącze",
            "openLink": "Otwórz link",
            "editLink": "Edytuj link",
            "removeLink": "Usuń link",
            "image": "Umieść obraz",
            "replace": "Zastąpić",
            "numberFormatList": "Lista formatów liczb",
            "bulletFormatList": "Lista formatów punktorów",
            "audioReplace": "Zastępować",
            "videoReplace": "Zastępować",
            "videoAlign": "Wyrównywać",
            "videoDimension": "Wymiar",
            "audioRemove": "Usuń",
            "videoRemove": "Usuń",
            "audioLayoutOption": "Opcja układu",
            "videoLayoutOption": "Opcja układu",
            "align": "Wyrównać",
            "caption": "Tytuł Zdjęcia",
            "remove": "Usuń",
            "insertLink": "Wstaw link",
            "display": "Pokaz",
            "altText": "alternatywny tekst",
            "dimension": "Zmień rozmiar",
            "fullscreen": "Wyolbrzymiać",
            "maximize": "Wyolbrzymiać",
            "minimize": "Zminimalizować",
            "lowerCase": "Małe litery",
            "upperCase": "Duże litery",
            "print": "Wydrukować",
            "formats": "Formaty",
            "sourcecode": "Widok kodu",
            "preview": "Zapowiedź",
            "viewside": "Widok z boku",
            "insertCode": "Wprowadź kod",
            "linkText": "Wyświetl tekst",
            "linkTooltipLabel": "Tytuł",
            "linkWebUrl": "Adres internetowy",
            "linkTitle": "Wpisz tytuł",
            "linkurl": "https://przyklad.com",
            "linkOpenInNewWindow": "Otwórz link w nowym oknie",
            "linkHeader": "Wstaw link",
            "dialogInsert": "Wstawić",
            "dialogCancel": "Anuluj",
            "dialogUpdate": "Aktualizacja",
            "imageHeader": "Umieść obraz",
            "audioHeader": "Wstaw dźwięk",
            "videoHeader": "Wstaw wideo",
            "imageLinkHeader": "Możesz także podać link z Internetu",
            "audioLinkHeader": "Możesz także podać link z Internetu",
            "videoLinkHeader": "Adres internetowy",
            "embedVideoLinkHeader": "Adres URL osadzania multimediów",
            "mdimageLink": "Podaj adres URL swojego obrazu",
            "imageUploadMessage": "Upuść obraz tutaj lub przeglądaj, aby przesłać",
            "audioUploadMessage": "Upuść plik audio lub przeglądaj, aby go przesłać",
            "videoUploadMessage": "Upuść plik wideo lub przeglądaj, aby go przesłać",
            "imageDeviceUploadMessage": "Kliknij tutaj, aby przesłać",
            "audioDeviceUploadMessage": "Kliknij tutaj, aby przesłać",
            "videoDeviceUploadMessage": "Kliknij tutaj, aby przesłać",
            "imageAlternateText": "Alternatywny tekst",
            "alternateHeader": "alternatywny tekst",
            "browse": "Przeglądaj",
            "imageUrl": "https://example.com/image.png",
            "audioUrl": "https://example.com/audio.mp3",
            "videoUrl": "https://example.com/video.mp4",
            "webUrl": "Adres internetowy",
            "embedUrl": "Kod do umieszczenia na stronie",
            "imageCaption": "Podpis",
            "imageSizeHeader": "Rozmiar obrazu",
            "imageHeight": "Wysokość",
            "imageWidth": "Szerokość",
            "videoHeight": "Wysokość",
            "videoWidth": "Szerokość",
            "textPlaceholder": "Wprowadź tekst",
            "inserttablebtn": "Wypełnij tabelę",
            "tabledialogHeader": "Wypełnij tabelę",
            "tableWidth": "Szerokość",
            "cellpadding": "Padding komórek",
            "cellspacing": "Odstępy między komórkami",
            "columns": "Liczba kolumn",
            "rows": "Liczba rzędów",
            "tableRows": "Wiersz",
            "tableColumns": "Kolumna",
            "tableCellHorizontalAlign": "Tabela komórek Wyrównanie w poziomie",
            "tableCellVerticalAlign": "Wyrównanie w pionie tabeli",
            "createTable": "Utwórz tabelę",
            "removeTable": "Usuń tabelę",
            "tableHeader": "Wiersz nagłówka",
            "tableRemove": "Usuń tabelę",
            "tableCellBackground": "Tło komórki tabeli",
            "tableEditProperties": "Właściwości edycji tabeli",
            "styles": "Style",
            "insertColumnLeft": "Wstaw kolumnę po lewej",
            "insertColumnRight": "Wstaw kolumnę z prawej",
            "deleteColumn": "Usuń kolumnę",
            "insertRowBefore": "Wstaw wiersz przed",
            "insertRowAfter": "Wstaw wiersz po",
            "deleteRow": "Usuń wiersz",
            "tableEditHeader": "Edytuj tabelę",
            "TableHeadingText": "Nagłówek",
            "TableColText": "Przełęcz",
            "imageInsertLinkHeader": "Wstaw link",
            "editImageHeader": "Edytuj obraz",
            "alignmentsDropDownLeft": "Wyrównaj do lewej",
            "alignmentsDropDownCenter": "Wyrównaj do środka",
            "alignmentsDropDownRight": "Wyrównaj do prawej",
            "alignmentsDropDownJustify": "Wyrównaj Wyrównaj",
            "imageDisplayDropDownInline": "W linii",
            "imageDisplayDropDownBreak": "Przerwa",
            "audioLayoutOptionDropDownInline": "Wbudowany",
            "audioLayoutOptionDropDownBreak": "Przerwa",
            "videoLayoutOptionDropDownInline": "Wbudowany",
            "videoLayoutOptionDropDownBreak": "Przerwa",
            "tableInsertRowDropDownBefore": "Wstaw wiersz wcześniej",
            "tableInsertRowDropDownAfter": "Wstaw wiersz po",
            "tableInsertRowDropDownDelete": "Usuń wiersz",
            "tableInsertColumnDropDownLeft": "Wstaw kolumnę po lewej",
            "tableInsertColumnDropDownRight": "Wstaw kolumnę z prawej strony",
            "tableInsertColumnDropDownDelete": "Usuń kolumnę",
            "tableVerticalAlignDropDownTop": "Wyrównaj do góry",
            "tableVerticalAlignDropDownMiddle": "Wyrównaj środkowy",
            "tableVerticalAlignDropDownBottom": "Wyrównaj do dołu",
            "tableStylesDropDownDashedBorder": "Przekreślone granice",
            "tableStylesDropDownAlternateRows": "Alternatywne rzędy",
            "pasteFormat": "Wklej format",
            "pasteFormatContent": "Wybierz akcję formatowania",
            "plainText": "Zwykły tekst",
            "cleanFormat": "Czysty",
            "keepFormat": "Trzymać",
            "pasteDialogOk": "ok",
            "pasteDialogCancel": "Anuluj",
            "fileManager": "Menedżer plików",
            "fileDialogHeader": "Przeglądarka plików",
            "formatsDropDownParagraph": "Ustęp",
            "formatsDropDownCode": "Kod",
            "formatsDropDownQuotation": "Cytat",
            "formatsDropDownHeading1": "Nagłówek 1",
            "formatsDropDownHeading2": "Nagłówek 2",
            "formatsDropDownHeading3": "Nagłówek 3",
            "formatsDropDownHeading4": "Nagłówek 4",
            "fontNameSegoeUI": "SegoeUI",
            "fontNameArial": "Arial",
            "fontNameGeorgia": "Georgia",
            "fontNameImpact": "Impact",
            "fontNameTahoma": "Tahoma",
            "fontNameTimesNewRoman": "Times New Roman",
            "fontNameVerdana": "Verdana",
            "formatsOLListNumber": "Numer",
            "formatsOLListLowerAlpha": "DolnaAlfa",
            "formatsOLListUpperAlpha": "Górna alfa",
            "formatsOLListLowerRoman": "Dolny Roman",
            "formatsOLListUpperRoman": "górnorzymski",
            "formatsOLListLowerGreek": "Dolny Grecki",
            "formatsULListDisc": "Dysk",
            "formatsULListCircle": "okrąg",
            "formatsULListSquare": "Kwadrat",
            "formatsOLListNone": "Żaden",
            "formatsULListNone": "Żaden",
            "formatPainter": "Malarz formatów",
            "emojiPicker": "Selektor emotikonów",
            "embeddedCode": "Wbudowany kod",
            "pasteEmbeddedCodeHere": "Wklej kod osadzony tutaj",
            "emojiPickerTypeToFind": "Wpisz, aby znaleźć",
            "emojiPickerNoResultFound": "Nie znaleziono wyników",
            "emojiPickerTrySomethingElse": "Spróbuj czegoś innego",
            "linkAriaLabel": "Otworzyć w nowym oknie",
            "imageLinkAriaLabel": "Otworzyć w nowym oknie",
            "unsupportedImage": "Niewspierany format pliku",
            "mergecells": "Połącz komórki",
            "verticalsplit": "Podział pionowy",
            "horizontalsplit": "Podział poziomy",
            "numberFormatListLowerAlpha": "Niższa alfa",
            "numberFormatListUpperAlpha": "Górna Alfa",
            "numberFormatListLowerRoman": "Dolny Rzym",
            "numberFormatListUpperRoman": "Górny Rzym",
            "numberFormatListLowerGreek": "Dolna greka"
        },
        "colorpicker": {
            "Apply": "Zastosuj",
            "Cancel": "Anuluj",
            "ModeSwitcher": "Tryb przełączania"
        },
        "uploader": {
            "Browse": "Przeglądaj...",
            "Clear": "Jasny",
            "Upload": "Przekazać plik",
            "dropFilesHint": "Lub upuść pliki tutaj",
            "invalidMaxFileSize": "Rozmiar pliku jest za duży",
            "invalidMinFileSize": "Rozmiar pliku jest za mały",
            "invalidFileType": "Typ pliku jest niedozwolony",
            "uploadFailedMessage": "Plik nie został przesłany",
            "uploadSuccessMessage": "Plik przesłany pomyślnie",
            "removedSuccessMessage": "Plik usunięty pomyślnie",
            "removedFailedMessage": "Nie można usunąć pliku",
            "inProgress": "Przesyłanie",
            "readyToUploadMessage": "Gotowy do przesłania",
            "abort": "Anuluj",
            "remove": "Usuń",
            "cancel": "Anuluj",
            "delete": "Usuń plik",
            "pauseUpload": "Przesyłanie pliku zostało wstrzymane",
            "pause": "Pauza",
            "resume": "Wznawianie",
            "retry": "Spróbować ponownie",
            "fileUploadCancel": "Przesyłanie pliku anulowane"
        },
        "numerictextbox": {
            "incrementTitle": "Wartość przyrostu",
            "decrementTitle": "Wartość zmniejszenia"
        },
        "slider": {
            "incrementTitle": "Zwiększać",
            "decrementTitle": "Zmniejszać"
        },
        "formValidator": {
            "required": "To pole jest wymagane.",
            "email": "Proszę wpisać aktualny adres e-mail.",
            "url": "Proszę podać poprawny adres URL.",
            "date": "Proszę wprowadź poprawną datę.",
            "dateIso": "Podaj prawidłową datę (ISO).",
            "creditcard": "Proszę podać poprawny numer karty",
            "number": "Proszę wprowadzić poprawny numer.",
            "digits": "Wprowadź tylko cyfry.",
            "maxLength": "Wpisz nie więcej niż {0} znaków.",
            "minLength": "Wprowadź co najmniej {0} znaków.",
            "rangeLength": "Wprowadź wartość od {0} do {1} znaków.",
            "range": "Wprowadź wartość z przedziału od {0} do {1}.",
            "max": "Wprowadź wartość mniejszą lub równą {0}.",
            "min": "Wprowadź wartość większą lub równą {0}.",
            "regex": "Wpisz poprawną wartość.",
            "tel": "Proszę wpisać poprawny numer telefonu.",
            "pattern": "Podaj poprawną wartość wzoru.",
            "equalTo": "Wpisz poprawny tekst dopasowania"
        },
        "treegrid": {
            "Above": "Powyżej",
            "Below": "Poniżej",
            "AddRow": "Dodaj wiersz",
            "ExpandAll": "Rozwiń wszystkie",
            "CollapseAll": "Zwiń wszystkie"
        },
        "querybuilder": {
            "StartsWith": "Zaczynać z",
            "EndsWith": "Kończy się na",
            "Contains": "Zawiera",
            "NotLike": "Zawiera",
            "Like": "Nie jak",
            "Equal": "Równy",
            "NotEqual": "Nie równe",
            "LessThan": "Mniej niż",
            "LessThanOrEqual": "Mniej niż równe",
            "GreaterThan": "Lepszy niż",
            "GreaterThanOrEqual": "Większy bądź równy",
            "Between": "pomiędzy",
            "NotBetween": "Nie pomiędzy",
            "Empty": "Pusty",
            "NotEmpty": "Nie pusty",
            "In": "W",
            "NotIn": "Nie w",
            "NotContains": "Nie zawiera",
            "Remove": "Usuń",
            "SelectField": "Wybierz pole",
            "SelectOperator": "Wybierz operatora",
            "DeleteRule": "Usuń ten warunek",
            "DeleteGroup": "Usuń grupę",
            "AddGroup": "Dodaj grupę",
            "AddCondition": "Dodaj warunek",
            "Edit": "Edytuj",
            "ValidationMessage": "To pole jest wymagane",
            "SummaryViewTitle": "Widok podsumowujący",
            "OtherFields": "Inne pola",
            "AND": "I",
            "OR": "LUB",
            "SelectValue": "Wpisz wartość",
            "IsEmpty": "Jest pusty",
            "IsNotEmpty": "Nie jest pusty",
            "IsNull": "jest zerowy",
            "IsNotNull": "Nie jest zerowa",
            "True": "prawdziwe",
            "False": "fałszywy",
            "DoesNotStartWith": "Nie zaczyna się od",
            "DoesNotEndWith": "Nie kończy się na",
            "DoesNotContain": "Nie zawiera",
            "AddButton": "Dodaj grupę/warunek",
            "CloneGroup": "Grupa klonów",
            "LockGroup": "Grupa blokady",
            "CloneRule": "Zasada klonowania",
            "LockRule": "Zasada blokady",
            "UnlockRule": "Odblokuj regułę",
            "UnlockGroup": "Odblokuj grupę"
        },
        "barcode": {},
        "datamatrix": {},
        "qrcode": {},
        "pivotview": {
            "grandTotal": "Łączna suma",
            "total": "Całkowity",
            "value": "Wartość",
            "noValue": "Bez wartości",
            "row": "Rząd",
            "column": "Kolumna",
            "collapse": "Zawalić się",
            "expand": "Rozszerzać",
            "rowAxisPrompt": "Upuść wiersz tutaj",
            "columnAxisPrompt": "Upuść kolumnę tutaj",
            "valueAxisPrompt": "Upuść wartość tutaj",
            "filterAxisPrompt": "Upuść filtr tutaj",
            "filter": "Filtr",
            "filtered": "Przefiltrowany",
            "sort": "Sortować",
            "filters": "Filtry",
            "rows": "Wydziwianie",
            "columns": "Kolumny",
            "values": "Wartości",
            "close": "Blisko",
            "cancel": "Anuluj",
            "delete": "Usuń",
            "CalculatedField": "Obliczone pole",
            "createCalculatedField": "Utwórz pole obliczeniowe",
            "fieldName": "Wpisz nazwę pola",
            "error": "Błąd",
            "invalidFormula": "Niepoprawna formuła.",
            "dropText": "Przykład: („Sum (Order_Count)” + „Sum (In_Stock)”) * 250",
            "dropTextMobile": "Dodaj pola i edytuj formułę tutaj.",
            "dropAction": "Obliczone pole nie może być umieszczone w żadnym innym regionie oprócz osi wartości.",
            "alert": "Alarm",
            "warning": "Ostrzeżenie",
            "ok": "ok",
            "search": "Szukaj",
            "drag": "Opór",
            "remove": "Usuń",
            "allFields": "Wszystkie pola",
            "formula": "Formuła",
            "addToRow": "Dodaj do wiersza",
            "addToColumn": "Dodaj do kolumny",
            "addToValue": "Dodaj do wartości",
            "addToFilter": "Dodaj do filtra",
            "emptyData": "brak nagrań do odtworzenia",
            "fieldExist": "Pole o tej nazwie już istnieje. Wpisz inną nazwę.",
            "confirmText": "Pole obliczeniowe już istnieje w tej nazwie. Czy chcesz to wymienić?",
            "noMatches": "Brak wyników",
            "format": "Podsumowuje wartości według",
            "edit": "Edytować",
            "clear": "Jasny",
            "formulaField": "Przeciągnij i upuść pola do formuły",
            "dragField": "Przeciągnij pole do formuły",
            "clearFilter": "Jasny",
            "by": "przez",
            "all": "Wszystko",
            "multipleItems": "Wiele przedmiotów",
            "member": "Członek",
            "label": "Etykieta",
            "date": "Data",
            "enterValue": "Wpisz wartość",
            "chooseDate": "Wpisz datę",
            "Before": "Przed",
            "BeforeOrEqualTo": "Przed lub równe",
            "After": "Po",
            "AfterOrEqualTo": "Po lub równa",
            "labelTextContent": "Pokaż elementy, dla których etykieta",
            "dateTextContent": "Pokaż przedmioty, dla których data",
            "valueTextContent": "Pokaż przedmioty, dla których",
            "Equals": "Równa się",
            "DoesNotEquals": "Nie równa się",
            "BeginWith": "Zaczyna się z",
            "DoesNotBeginWith": "Nie zaczyna się",
            "EndsWith": "Kończy się na",
            "DoesNotEndsWith": "Nie kończy się na",
            "Contains": "Zawiera",
            "DoesNotContains": "Nie zawiera",
            "GreaterThan": "Lepszy niż",
            "GreaterThanOrEqualTo": "Większe bądź równe",
            "LessThan": "Mniej niż",
            "LessThanOrEqualTo": "Mniejszy lub równy",
            "Between": "pomiędzy",
            "NotBetween": "Nie pomiędzy",
            "And": "i",
            "Sum": "Suma",
            "Count": "Liczyć",
            "DistinctCount": "Wyraźny hrabia",
            "Product": "Produkt",
            "Avg": "Śr",
            "Min": "Min",
            "SampleVar": "Próbka Var",
            "PopulationVar": "Populacja Var",
            "RunningTotals": "Sumy bieżące",
            "Max": "Maks",
            "Index": "Indeks",
            "SampleStDev": "Próbka StDev",
            "PopulationStDev": "Populacja StDev",
            "PercentageOfRowTotal": "% sumy wierszy",
            "PercentageOfParentTotal": "% sumy nadrzędnej",
            "PercentageOfParentColumnTotal": "% łącznej kolumny macierzystej",
            "PercentageOfParentRowTotal": "% sumy wierszy nadrzędnych",
            "DifferenceFrom": "Różnica od",
            "PercentageOfDifferenceFrom": "% różnicy od",
            "PercentageOfGrandTotal": "% sumy całkowitej",
            "PercentageOfColumnTotal": "% kolumny ogółem",
            "NotEquals": "Nie równa się",
            "AllValues": "Wszystkie wartości",
            "conditionalFormatting": "Formatowanie warunkowe",
            "apply": "ZASTOSOWAĆ",
            "condition": "Dodaj warunek",
            "formatLabel": "Format",
            "valueFieldSettings": "Ustawienia pola wartości",
            "baseField": "Pole podstawowe:",
            "baseItem": "Element podstawowy:",
            "summarizeValuesBy": "Podsumuj wartości według:",
            "sourceName": "Nazwa pola :",
            "sourceCaption": "Podpis pola:",
            "example": "na przykład:",
            "editorDataLimitMsg": " Więcej elementów. Wyszukaj, aby dalej udoskonalać.",
            "details": "Detale",
            "manageRecords": "Zarządzaj rekordami",
            "Years": "Lat",
            "Quarters": "Mieszkanie",
            "Months": "Miesięcy",
            "Days": "Dni",
            "Hours": "godziny",
            "Minutes": "Minuty",
            "Seconds": "sekundy",
            "save": "Zapisz raport",
            "new": "Utwórz nowy raport",
            "load": "Załaduj",
            "saveAs": "Zapisz jako raport bieżący",
            "rename": "Zmień nazwę bieżącego raportu",
            "deleteReport": "Usuń bieżący raport",
            "export": "Eksport",
            "subTotals": "Suma częściowa",
            "grandTotals": "Wielkie sumy",
            "reportName": "Nazwa raportu :",
            "pdf": "PDF",
            "excel": "Przewyższać",
            "csv": "CSV",
            "png": "PNG",
            "jpeg": "JPG",
            "svg": "SVG",
            "mdxQuery": "Zapytanie MDX",
            "showSubTotals": "Pokaż sumy częściowe",
            "doNotShowSubTotals": "Nie pokazuj sum częściowych",
            "showSubTotalsRowsOnly": "Pokaż tylko wiersze sumy częściowej",
            "showSubTotalsColumnsOnly": "Pokaż tylko kolumny sum częściowych",
            "showGrandTotals": "Pokaż sumy",
            "doNotShowGrandTotals": "Nie pokazuj wielkich sum",
            "showGrandTotalsRowsOnly": "Pokaż tylko wiersze sumy ogólnej",
            "showGrandTotalsColumnsOnly": "Pokaż tylko kolumny sum ogółem",
            "fieldList": "Pokaż listę pól",
            "grid": "Pokaż tabelę",
            "toolbarFormatting": "Formatowanie warunkowe",
            "chart": "Wykres",
            "reportMsg": "Proszę podać nazwę raportu vaild !!!",
            "reportList": "Lista raportów",
            "removeConfirm": "Czy na pewno chcesz usunąć ten raport?",
            "emptyReport": "Nie znaleziono raportów !!",
            "bar": "Bar",
            "line": "Linia",
            "area": "Powierzchnia",
            "scatter": "Rozpraszać",
            "polar": "Polarny",
            "of": "z",
            "emptyFormat": "Nie znaleziono formatu !!!",
            "emptyInput": "Wpisz wartość",
            "newReportConfirm": "Chcesz zapisać zmiany w raporcie?",
            "emptyReportName": "Wpisz nazwę raportu",
            "qtr": "Qtr",
            "null": "zero",
            "undefined": "nieokreślony",
            "groupOutOfRange": "Poza zakresem",
            "fieldDropErrorAction": "Przenoszonego pola nie można umieścić w tym obszarze raportu",
            "MoreOption": "Więcej...",
            "aggregate": "Agregat",
            "drillThrough": "Wiercić przez",
            "ascending": "Rosnąco",
            "descending": "Malejąco",
            "number": "Numer",
            "currency": "Waluta",
            "percentage": "Odsetek",
            "formatType": "Typ formatu",
            "customText": "Symbol waluty",
            "symbolPosition": "Pozycja symbolu",
            "left": "Lewo",
            "right": "Prawo",
            "grouping": "Grupowanie",
            "true": "Prawdziwe",
            "false": "Fałszywy",
            "decimalPlaces": "Miejsca dziesiętne",
            "numberFormat": "Formatowanie liczb",
            "memberType": "Typ pola",
            "formatString": "Formatuj ciąg",
            "expressionField": "Wyrażenie",
            "customFormat": "Wprowadź ciąg formatu niestandardowego",
            "selectedHierarchy": "Hierarchia rodziców",
            "olapDropText": "Przykład: [Miary]. [Ilość zamówienia] + ([Miary]. [Ilość zamówienia] * 0,10)",
            "Percent": "Procent",
            "Custom": "Zwyczaj",
            "Measure": "Pomiar",
            "Dimension": "Wymiar",
            "Standard": "Standard",
            "blank": "(Pusty)",
            "fieldTooltip": "Przeciągnij i upuść pola, aby utworzyć wyrażenie. A jeśli chcesz edytować istniejące pola obliczeniowe! Następnie możesz to osiągnąć, po prostu wybierając pole w sekcji „Obliczeni członkowie”.",
            "QuarterYear": "Rok kwartalny",
            "fieldTitle": "Nazwa pola",
            "drillError": "Nie można wyświetlić nieprzetworzonych elementów pól obliczeniowych.",
            "caption": "Podpis polowy",
            "copy": "Kopiuj",
            "defaultReport": "Raport domyślny",
            "customFormatString": "Format niestandardowy",
            "invalidFormat": "Niepoprawny format.",
            "group": "Grupa",
            "unGroup": "Rozgrupuj",
            "invalidSelection": "Nie można zgrupować tego wyboru.",
            "groupName": "Wpisz podpis do wyświetlenia w nagłówku",
            "captionName": "Wpisz podpis pola grupy",
            "selectedItems": "Wybrane przedmioty",
            "groupFieldCaption": "Podpis polowy",
            "groupTitle": "Nazwa grupy",
            "startAt": "Zaczynać od",
            "endAt": "Kończąc się na",
            "groupBy": "Interwał przez",
            "selectGroup": "Wybierz grupy",
            "numberFormatString": "Przykład: C, P, 0000%, ### 0. ## 0 # itp.",
            "stackingcolumn": "Ułożona kolumna",
            "stackingbar": "Sztaplowany Sztabka",
            "stackingarea": "Obszar skumulowany",
            "stepline": "Linia kroku",
            "steparea": "Obszar kroku",
            "splinearea": "Obszar splajnu",
            "spline": "Klin",
            "stackingcolumn100": "100% skumulowana kolumna",
            "stackingbar100": "100% sztaplowany stos",
            "stackingarea100": "100% ułożonego obszaru",
            "bubble": "bańka",
            "pareto": "Pareta",
            "radar": "Radar",
            "chartTypeSettings": "Ustawienia typu wykresu",
            "multipleAxes": "Wiele osi",
            "sortAscending": "Sortuj rosnąco",
            "sortDescending": "Sortuj malejąco",
            "sortNone": "Sortuj kolejność danych",
            "clearCalculatedField": "Wyczyść edytowane informacje o polu",
            "editCalculatedField": "Edytuj pole obliczeniowe",
            "ChartType": "Rodzaj wykresu",
            "yes": "tak",
            "no": "Nie",
            "numberFormatMenu": "Formatowanie liczb ...",
            "conditionalFormattingMenu": "Formatowanie warunkowe...",
            "removeCalculatedField": "Czy na pewno chcesz usunąć to pole obliczeniowe?",
            "replaceConfirmBefore": "Raport o nazwie",
            "replaceConfirmAfter": " już istnieje. Czy chcesz to wymienić?",
            "pie": "Ciasto",
            "funnel": "Lejek",
            "doughnut": "Pączek",
            "pyramid": "Piramida",
            "showLegend": "Pokaż legendę",
            "exit": "Wyjście",
            "invalidJSON": "Nieprawidłowe dane JSON",
            "invalidCSV": "Nieprawidłowe dane CSV",
            "stacked": "Ułożone",
            "single": "Pojedynczy",
            "multipleAxisMode": "Tryb wielu osi",
            "grandTotalPosition": "Pozycja sum całkowitych",
            "top": "Szczyt",
            "bottom": "Na dole",
            "None": "Nic",
            "stackingline": "Linia ułożona",
            "stackingline100": "100% Linia ułożona",
            "rowPage": "Pager rzędowy",
            "rowPerPage": "Wiersze na stronę",
            "columnPage": "Pager kolumnowy",
            "columnPerPage": "Kolumny na stronę",
            "goToFirstPage": "Przejdź do pierwszej strony",
            "goToPreviousPage": "Wróć do poprzedniej strony",
            "goToNextPage": "Przejdź do następnej strony",
            "goToLastPage": "Idź do ostatniej strony",
            "combined": "Łączny",
            "subTotalPosition": "Pozycja sum częściowych",
            "auto": "Automatyczny",
            "loading": "Ładowanie..."
        },
        "pivotfieldlist": {
            "staticFieldList": "Lista pól przestawnych",
            "fieldList": "Lista pól",
            "dropFilterPrompt": "Upuść filtr tutaj",
            "dropColPrompt": "Upuść kolumnę tutaj",
            "dropRowPrompt": "Upuść wiersz tutaj",
            "dropValPrompt": "Upuść wartość tutaj",
            "addPrompt": "Dodaj pole tutaj",
            "adaptiveFieldHeader": "Wybierz pole",
            "centerHeader": "Przeciągnij pola między osiami poniżej:",
            "add": "Dodaj",
            "drag": "Opór",
            "filter": "Filtr",
            "filtered": "Przefiltrowany",
            "sort": "Sortować",
            "remove": "Usuń",
            "filters": "Filtry",
            "rows": "Wydziwianie",
            "columns": "Kolumny",
            "values": "Wartości",
            "CalculatedField": "Obliczone pole",
            "createCalculatedField": "Utwórz pole obliczeniowe",
            "fieldName": "Wpisz nazwę pola",
            "error": "Błąd",
            "invalidFormula": "Niepoprawna formuła.",
            "dropText": "Przykład: („Sum (Order_Count)” + „Sum (In_Stock)” ”) * 250",
            "dropTextMobile": "Dodaj pola i edytuj formułę tutaj.",
            "dropAction": "Obliczone pole nie może być umieszczone w żadnym innym regionie oprócz osi wartości.",
            "search": "Szukaj",
            "close": "Blisko",
            "cancel": "Anuluj",
            "delete": "Usuń",
            "alert": "Alarm",
            "warning": "Ostrzeżenie",
            "ok": "Ok",
            "allFields": "Wszystkie pola",
            "formula": "Formuła",
            "fieldExist": "Pole o tej nazwie już istnieje. Wpisz inną nazwę.",
            "confirmText": "Pole obliczeniowe już istnieje w tej nazwie. Czy chcesz to wymienić?",
            "noMatches": "Brak wyników",
            "format": "Podsumowuje wartości według",
            "edit": "Edytuj",
            "clear": "Jasny",
            "formulaField": "Przeciągnij i upuść pola do formuły",
            "dragField": "Przeciągnij pole do formuły",
            "clearFilter": "Jasny",
            "by": "przez",
            "enterValue": "Wpisz wartość",
            "chooseDate": "Wpisz datę",
            "all": "Wszystko",
            "multipleItems": "Wiele przedmiotów",
            "Equals": "Równa się",
            "DoesNotEquals": "Nie równa się",
            "BeginWith": "Zaczyna się z",
            "DoesNotBeginWith": "Nie zaczyna się",
            "EndsWith": "Kończy się na",
            "DoesNotEndsWith": "Nie kończy się na",
            "Contains": "Zawiera",
            "DoesNotContains": "Nie zawiera",
            "GreaterThan": "Lepszy niż",
            "GreaterThanOrEqualTo": "Większe bądź równe",
            "LessThan": "Mniej niż",
            "LessThanOrEqualTo": "Mniejszy lub równy",
            "Between": "pomiędzy",
            "NotBetween": "Nie pomiędzy",
            "Before": "Przed",
            "BeforeOrEqualTo": "Przed lub równe",
            "After": "Po",
            "AfterOrEqualTo": "Po lub równa",
            "member": "Członek",
            "label": "Etykieta",
            "date": "Data",
            "value": "Wartość",
            "labelTextContent": "Pokaż elementy, dla których etykieta",
            "dateTextContent": "Pokaż przedmioty, dla których data",
            "valueTextContent": "Pokaż przedmioty, dla których",
            "And": "i",
            "Sum": "Suma",
            "Count": "Liczyć",
            "DistinctCount": "Wyraźny hrabia",
            "Product": "Produkt",
            "Avg": "Śr",
            "Min": "Min",
            "Max": "Maks",
            "Index": "Indeks",
            "SampleStDev": "Próbka StDev",
            "PopulationStDev": "Populacja StDev",
            "SampleVar": "Próbka Var",
            "PopulationVar": "Populacja Var",
            "RunningTotals": "Sumy bieżące",
            "DifferenceFrom": "Różnica od",
            "PercentageOfDifferenceFrom": "% różnicy od",
            "PercentageOfGrandTotal": "% sumy całkowitej",
            "PercentageOfColumnTotal": "% kolumny ogółem",
            "PercentageOfRowTotal": "% sumy wierszy",
            "PercentageOfParentTotal": "% sumy nadrzędnej",
            "PercentageOfParentColumnTotal": "% łącznej kolumny macierzystej",
            "PercentageOfParentRowTotal": "% sumy wierszy nadrzędnych",
            "Years": "Lat",
            "Quarters": "Mieszkanie",
            "Months": "Miesięcy",
            "Days": "Dni",
            "Hours": "godziny",
            "Minutes": "Minuty",
            "Seconds": "sekundy",
            "apply": "ZASTOSOWAĆ",
            "valueFieldSettings": "Ustawienia pola wartości",
            "sourceName": "Nazwa pola :",
            "sourceCaption": "Podpis pola:",
            "summarizeValuesBy": "Podsumuj wartości według:",
            "baseField": "Pole podstawowe:",
            "baseItem": "Element podstawowy:",
            "example": "na przykład:",
            "editorDataLimitMsg": " Więcej elementów. Wyszukaj, aby dalej udoskonalać.",
            "deferLayoutUpdate": "Odłóż aktualizację układu",
            "null": "zero",
            "undefined": "nieokreślony",
            "groupOutOfRange": "Poza zakresem",
            "fieldDropErrorAction": "Przenoszonego pola nie można umieścić w tym obszarze raportu",
            "MoreOption": "Więcej...",
            "memberType": "Typ pola",
            "selectedHierarchy": "Hierarchia rodziców",
            "formatString": "Formatuj ciąg",
            "expressionField": "Wyrażenie",
            "olapDropText": "Przykład: [Miary]. [Ilość zamówienia] + ([Miary]. [Ilość zamówienia] * 0,10)",
            "customFormat": "Wprowadź ciąg formatu niestandardowego",
            "Measure": "Pomiar",
            "Dimension": "Wymiar",
            "Standard": "Standard",
            "Currency": "Waluta",
            "Percent": "Procent",
            "Custom": "Zwyczaj",
            "blank": "(Pusty)",
            "fieldTooltip": "Przeciągnij i upuść pola, aby utworzyć wyrażenie. A jeśli chcesz edytować istniejące pola obliczeniowe! Możesz to osiągnąć, po prostu wybierając pole w części „Obliczeni członkowie”.",
            "fieldTitle": "Nazwa pola",
            "QuarterYear": "Rok kwartalny",
            "caption": "Podpis polowy",
            "copy": "Kopiuj",
            "group": "Grupa",
            "numberFormatString": "Przykład: C, P, 0000%, ### 0. ## 0 # itp.",
            "sortAscending": "Sortuj rosnąco",
            "sortDescending": "Sortuj malejąco",
            "sortNone": "Sortuj kolejność danych",
            "clearCalculatedField": "Wyczyść edytowane informacje o polu",
            "editCalculatedField": "Edytuj pole obliczeniowe",
            "selectGroup": "Wybierz grupy",
            "of": "z",
            "removeCalculatedField": "Czy na pewno chcesz usunąć to pole obliczeniowe?",
            "yes": "tak",
            "no": "Nie",
            "None": "Żaden"
        },
        "pdfviewer": {
            "PdfViewer": "﻿Przeglądarka plików PDF",
            "Cancel": "Anuluj",
            "Download file": "Pobieranie pliku",
            "Download": "Pobieranie",
            "Enter Password": "Ten dokument jest chroniony hasłem. Proszę wprowadzić hasło.",
            "File Corrupted": "Plik uszkodzony",
            "File Corrupted Content": "Plik jest uszkodzony i nie można go otworzyć.",
            "Fit Page": "Dopasuj stronę",
            "Fit Width": "Dopasuj szerokość",
            "Automatic": "Automatyczny",
            "Go To First Page": "Pokaż pierwszą stronę",
            "Invalid Password": "Niepoprawne hasło. Proszę spróbuj ponownie.",
            "Next Page": "Pokaż następną stronę",
            "OK": "OK",
            "Open": "Otwórz plik",
            "Page Number": "Aktualny numer strony",
            "Previous Page": "Pokaż poprzednią stronę",
            "Go To Last Page": "Pokaż ostatnią stronę",
            "Zoom": "Powiększenie",
            "Zoom In": "Zbliżenie",
            "Zoom Out": "Oddal",
            "Page Thumbnails": "Miniatury stron",
            "Bookmarks": "Zakładki",
            "Print": "Drukuj plik",
            "Password Protected": "Wymagane hasło",
            "Copy": "Kopiuj",
            "Text Selection": "Narzędzie do zaznaczania tekstu",
            "Panning": "Tryb panoramy",
            "Text Search": "Znajdź tekst",
            "Find in document": "Znajdź w dokumencie",
            "Match case": "Sprawa meczu",
            "Apply": "Zastosować",
            "GoToPage": "Idź do strony",
            "No matches": "Przeglądarka zakończy wyszukiwanie dokumentu. Nie znaleziono więcej dopasowań",
            "No Text Found": "Nie znaleziono tekstu",
            "Undo": "Cofnij",
            "Redo": "Przerobić",
            "Annotation": "Dodaj lub edytuj adnotacje",
            "Highlight": "Zaznaczanie tekstu",
            "Underline": "Podkreśl tekst",
            "Strikethrough": "Tekst przekreślony",
            "Delete": "Usuń adnotację",
            "Opacity": "Nieprzezroczystość",
            "Color edit": "Zmień kolor",
            "Opacity edit": "Zmień krycie",
            "Highlight context": "Atrakcja",
            "Underline context": "Podkreślać",
            "Strikethrough context": "Przekreśl",
            "Server error": "Usługa internetowa nie nasłuchuje. Przeglądarka plików PDF zależy od serwisu internetowego we wszystkich jego funkcjach. Uruchom usługę internetową, aby kontynuować.",
            "Open text": "otwarty",
            "First text": "Pierwsza strona",
            "Previous text": "Poprzednia strona",
            "Next text": "Następna strona",
            "Last text": "Ostatnia strona",
            "Zoom in text": "Zbliżenie",
            "Zoom out text": "Pomniejsz",
            "Selection text": "Wybór",
            "Pan text": "Patelnia",
            "Print text": "Wydrukować",
            "Search text": "Szukaj",
            "Annotation Edit text": "Edytuj adnotację",
            "Line Thickness": "Grubość linii",
            "Line Properties": "Właściwości linii",
            "Start Arrow": "Uruchom strzałkę",
            "End Arrow": "Strzałka końca",
            "Line Style": "Styl linii",
            "Fill Color": "Kolor wypełnienia",
            "Line Color": "Kolor linii",
            "None": "Żaden",
            "Open Arrow": "otwarty",
            "Closed Arrow": "Zamknięte",
            "Round Arrow": "Okrągły",
            "Square Arrow": "Plac",
            "Diamond Arrow": "Diament",
            "Butt": "Krupon",
            "Cut": "Skaleczenie",
            "Paste": "Pasta",
            "Delete Context": "Usunąć",
            "Properties": "Nieruchomości",
            "Add Stamp": "Dodaj pieczęć",
            "Add Shapes": "Dodaj kształty",
            "Stroke edit": "Zmień kolor obrysu",
            "Change thickness": "Zmień grubość granicy",
            "Add line": "Dodaj linię",
            "Add arrow": "Dodaj strzałkę",
            "Add rectangle": "Dodaj prostokąt",
            "Add circle": "Dodaj krąg",
            "Add polygon": "Dodaj wielokąt",
            "Add Comments": "Dodaj Komentarze",
            "Comments": "Komentarze",
            "No Comments Yet": "Brak komentarzy",
            "Accepted": "Przyjęty",
            "Completed": "Zakończony",
            "Cancelled": "Anulowany",
            "Rejected": "Odrzucony",
            "Leader Length": "Długość lidera",
            "Scale Ratio": "Współczynnik skali",
            "Calibrate": "Kalibrować",
            "Calibrate Distance": "Kalibruj odległość",
            "Calibrate Perimeter": "Skalibruj obwód",
            "Calibrate Area": "Skalibruj obszar",
            "Calibrate Radius": "Kalibruj promień",
            "Calibrate Volume": "Kalibruj głośność",
            "highlight": "Atrakcja",
            "underline": "Podkreślać",
            "strikethrough": "Przekreślić",
            "Seach text": "Szukaj",
            "FormDesigner": "Dodawaj i edytuj pola formularza",
            "Client error": "Znaleziono błąd po stronie klienta. Sprawdź niestandardowe nagłówki podane we właściwości AjaxRequestSettings i metody akcji sieci Web we właściwości ServerActionSettings.",
            "FormDesigner Edit text": "Dodawaj i edytuj pola formularza",
            "SubmitForm": "Prześlij formularz",
            "Depth": "Głębokość",
            "Closed": "Zamknięte",
            "Round": "Okrągły",
            "Square": "Kwadrat",
            "Diamond": "Diament",
            "Edit": "Edytuj",
            "Comment": "Komentarz",
            "Comment Panel": "Panel komentarzy",
            "Set Status": "Ustaw status",
            "Post": "Poczta",
            "Page": "Strona",
            "Add a comment": "Dodaj komentarz",
            "Add a reply": "Dodaj odpowiedź",
            "Import Annotations": "Importuj adnotacje z pliku JSON",
            "Export Annotations": "Eksportuj adnotację do pliku JSON",
            "Export XFDF": "Eksportuj adnotację do pliku XFDF",
            "Import XFDF": "Importuj adnotacje z pliku XFDF",
            "Add": "Dodaj",
            "Clear": "Jasny",
            "Bold": "Pogrubienie",
            "Italic": "italski",
            "Strikethroughs": "Przekreślenie",
            "Underlines": "Podkreślać",
            "Superscript": "Napisany u góry",
            "Subscript": "Indeks",
            "Align left": "Wyrównaj do lewej",
            "Align right": "Wyrównaj do prawej",
            "Center": "Środek",
            "Justify": "Uzasadniać",
            "Font color": "Kolor czcionki",
            "Text Align": "Wyrównaj tekst",
            "Text Properties": "Styl czcionki",
            "SignatureFieldDialogHeaderText": "Dodaj podpis",
            "HandwrittenSignatureDialogHeaderText": "Dodaj podpis",
            "InitialFieldDialogHeaderText": "Dodaj inicjał",
            "HandwrittenInitialDialogHeaderText": "Dodaj inicjał",
            "Draw Ink": "Rysuj tuszem",
            "Create": "Stwórz",
            "Font family": "Rodzina czcionek",
            "Font size": "Rozmiar czcionki",
            "Free Text": "Dowolny tekst",
            "Import Failed": "Nieprawidłowy typ pliku JSON lub nazwa pliku; wybierz prawidłowy plik JSON",
            "File not found": "Zaimportowany plik JSON nie został znaleziony w żądanej lokalizacji",
            "Export Failed": "Eksport adnotacji nie powiódł się; upewnij się, że adnotacje zostały poprawnie dodane",
            "of": "z ",
            "Dynamic": "Dynamiczny",
            "Standard Business": "Standardowy biznes",
            "Sign Here": "Podpisz tutaj",
            "Custom Stamp": "Stempel niestandardowy",
            "Enter Signature as Name": "Wpisz swoje imię",
            "Draw-hand Signature": "REMIS",
            "Type Signature": "RODZAJ",
            "Upload Signature": "PRZEKAZAĆ PLIK",
            "Browse Signature Image": "PRZEGLĄDAJ",
            "Save Signature": "Zapisz podpis",
            "Save Initial": "Zapisz inicjał",
            "Textbox": "Pole tekstowe",
            "Password": "Hasło",
            "Check Box": "Pole wyboru",
            "Radio Button": "Przycisk radiowy",
            "Dropdown": "Upuścić",
            "List Box": "Skrzynka na listy",
            "Signature": "Podpis",
            "Delete FormField": "Usuń pole formularza",
            "Textbox Properties": "Właściwości pola tekstowego",
            "Name": "Nazwa",
            "Tooltip": "Etykietka",
            "Value": "Wartość",
            "Form Field Visibility": "Widoczność pola formularza",
            "Read Only": "Tylko czytać",
            "Required": "Wymagany",
            "Checked": "W kratę",
            "Show Printing": "Pokaż drukowanie",
            "Formatting": "Format",
            "Fill": "Wypełnić",
            "Border": "Granica",
            "Border Color": "Kolor ramki",
            "Thickness": "Grubość",
            "Max Length": "Maksymalna długość",
            "List Item": "Nazwa przedmiotu",
            "Export Value": "Wartość przedmiotu",
            "Dropdown Item List": "Rozwijana lista przedmiotów",
            "List Box Item List": "Lista pozycji w polu listy",
            "General": "OGÓLNY",
            "Appearance": "WYGLĄD ZEWNĘTRZNY",
            "Options": "OPCJE",
            "Delete Item": "Usuwać",
            "Up": "W górę",
            "Down": "Na dół",
            "Multiline": "Wielowierszowy",
            "Revised": "Poprawiony",
            "Reviewed": "Oceniony",
            "Received": "Otrzymane",
            "Confidential": "Poufny",
            "Approved": "Zatwierdzony",
            "Not Approved": "Niezatwierdzone",
            "Witness": "Świadek",
            "Initial Here": "Inicjał tutaj",
            "Draft": "Projekt",
            "Final": "Finał",
            "For Public Release": "Do publikacji",
            "Not For Public Release": "Nie do publikacji",
            "For Comment": "Do komentarza",
            "Void": "Próżnia",
            "Preliminary Results": "Wstępne rezultaty",
            "Information Only": "Tylko informacje",
            "in": "W",
            "m": "m",
            "ft_in": "ft_in",
            "ft": "stopa",
            "p": "p",
            "cm": "cm",
            "mm": "mm",
            "pt": "pkt",
            "cu": "cu",
            "sq": "kwadrat",
            "Initial": "Wstępny"
        },
        "diagram": {
            "Copy": "Kopiuj",
            "Cut": "Skaleczenie",
            "Paste": "Pasta",
            "Undo": "Cofnij",
            "Redo": "Przerobić",
            "SelectAll": "Zaznacz wszystko",
            "Grouping": "Grupowanie",
            "Group": "Grupa",
            "UnGroup": "Rozgrupuj",
            "Order": "Zamówienie",
            "BringToFront": "Przesuń na wierzch",
            "MoveForward": "Pójść naprzód",
            "SendToBack": "Wyślij wstecz",
            "SendBackward": "Wyślij wstecz"
        },
        "inplace-editor": {
            "save": "Zapisać",
            "cancel": "Anuluj",
            "loadingText": "Ładowanie...",
            "editIcon": "Kliknij aby edytowac",
            "editAreaClick": "Kliknij aby edytowac",
            "editAreaDoubleClick": "Kliknij dwukrotnie, aby edytować"
        },
        "drawing": {},
        "drop-down-base": {
            "noRecordsTemplate": "Nic nie znaleziono",
            "actionFailureTemplate": "Żądanie nie powiodło się"
        },
        "chart": {
            "Zoom": "Powiększenie",
            "ZoomIn": "Zbliżenie",
            "ZoomOut": "Pomniejsz",
            "Reset": "Resetowanie",
            "Pan": "Patelnia",
            "ResetZoom": "Zresetuj powiększenie"
        },
        "maps": {
            "Zoom": "Powiększenie",
            "ZoomIn": "Zbliżenie",
            "ZoomOut": "Pomniejsz",
            "Reset": "Resetowanie",
            "Pan": "Patelnia",
            "ResetZoom": "Zresetuj powiększenie",
            "ImageNotFound": "Nie znaleziono obrazu"
        },
        "drop-down-tree": {
            "noRecordsTemplate": "Nic nie znaleziono",
            "actionFailureTemplate": "Żądanie nie powiodło się",
            "overflowCountTemplate": "+ ${count} więcej ...",
            "totalCountTemplate": "Wybrano ${count}"
        },
        "documenteditor": {
            "Table": "Stół",
            "Row": "Rząd",
            "Cell": "Komórka",
            "Ok": "Ok",
            "Apply": "powoli i łatwo",
            "Cancel": "Anuluj",
            "Size": "Rozmiar",
            "Preferred Width": "Preferowana szerokość",
            "Points": "Zwrotnica",
            "Percent": "Procent",
            "Measure in": "Zmierzyć w",
            "Alignment": "Wyrównanie",
            "Left": "Lewo",
            "Center": "Centrum",
            "Right": "Prawo",
            "Decimal": "Pierwsze miejsce po przecinku",
            "Bar": "połowa",
            "Leader": "Administrator systemu",
            "Tab": "Zaległe faktury",
            "BookMarkList": "Lista wyświetlonych dokumentów.",
            "TabMarkList": "Pokazuje strony akapitów.",
            "Tab stop position": "miejsca na dysku",
            "Default tab stops": "Fantastyczny gokart",
            "Tab stops to be cleared": "wymienna karta",
            "Tabs": "okno zakładki",
            "Set": "używać",
            "Clear": "tel",
            "Clear All": "Wyczyść wszystko",
            "Justify": "Uzasadniać",
            "Indent from left": "Wcięcie od lewej",
            "Borders and Shading": "Granice i cieniowanie",
            "Options": "Opcje",
            "Specify height": "Określ wysokość",
            "At least": "Przynajmniej",
            "Exactly": "Dokładnie",
            "Row height is": "Wysokość rzędu wynosi",
            "Allow row to break across pages": "Zezwalaj wierszowi na podział na strony",
            "Repeat as header row at the top of each page": "Powtórz jako wiersz nagłówka u góry każdej strony",
            "Vertical alignment": "Wyrównanie w pionie",
            "Top": "Szczyt",
            "Bottom": "Dolny",
            "Default cell margins": "Domyślne marginesy komórek",
            "Default cell spacing": "Domyślne odstępy między komórkami",
            "Allow spacing between cells": "Pozwól na odstępy między komórkami",
            "Cell margins": "Marginesy komórkowe",
            "Same as the whole table": "Taki sam jak cały stół",
            "Borders": "Granice",
            "None": "Żaden",
            "Style": "Styl",
            "Width": "Szerokość",
            "Height": "Wysokość",
            "Letter": "List",
            "Tabloid": "Tabletka",
            "Legal": "Prawny",
            "Statement": "Komunikat",
            "Executive": "Wykonawczy",
            "A3": "A3",
            "A4": "A4",
            "A5": "A5",
            "B4": "B4",
            "B5": "B5",
            "Custom Size": "Niestandardowy rozmiar",
            "Different odd and even": "Różne nieparzyste i parzyste",
            "Different first page": "Inna pierwsza strona",
            "From edge": "Od krawędzi",
            "Header": "nagłówek",
            "Footer": "Stopka",
            "First Page Header": "Nagłówek pierwszej strony",
            "First Page Footer": "Stopka pierwszej strony",
            "Even Page Header": "Nagłówek strony parzystej",
            "Even Page Footer": "Stopka stron parzystych",
            "Odd Page Header": "Nagłówek strony nieparzystej",
            "Odd Page Footer": "Stopka strony nieparzystej",
            "Same as Previous": "Taki sam jak poprzedni",
            "Section": "Sekcja",
            "Margin": "Marginesy",
            "Paper": "Papier",
            "Layout": "Układ",
            "Orientation": "Orientacja",
            "Landscape": "Krajobraz",
            "Portrait": "Portret",
            "Show page numbers": "Pokaż numery stron",
            "Right align page numbers": "Wyrównaj do prawej numery stron",
            "Nothing": "Nic",
            "Tab leader": "Lider tabu",
            "Show levels": "Pokaż poziomy",
            "Use hyperlinks instead of page numbers": "Użyj hiperłączy zamiast numerów stron",
            "Build table of contents from": "Zbuduj spis treści z",
            "Styles": "Style",
            "Available styles": "Dostępne style",
            "TOC level": "Poziom spisu treści",
            "Heading": "Nagłówek",
            "Heading 1": "Nagłówek 1",
            "Heading 2": "Nagłówek 2",
            "Heading 3": "Nagłówek 3",
            "Heading 4": "Nagłówek 4",
            "Heading 5": "Nagłówek 5",
            "Heading 6": "Nagłówek 6",
            "List Paragraph": "Lista ust",
            "Normal": "Normalna",
            "Outline levels": "Poziomy konspektu",
            "Table entry fields": "Pola wprowadzania tabeli",
            "Modify": "Modyfikować",
            "Color": "Kolor",
            "Setting": "Oprawa",
            "Box": "Pudełko",
            "All": "Wszystko",
            "Custom": "Zwyczaj",
            "Preview": "Zapowiedź",
            "Shading": "Zacienienie",
            "Fill": "Napełnić",
            "Apply To": "Zastosuj do",
            "Table Properties": "Właściwości tabeli",
            "Cell Options": "Opcje komórek",
            "Table Options": "Opcje tabeli",
            "Insert Table": "Wypełnij tabelę",
            "Number of columns": "Liczba kolumn",
            "Number of rows": "Liczba rzędów",
            "Text to display": "Tekst do wyświetlenia",
            "ScreenTip text": "Tekst porady ekranowej",
            "Address": "Adres",
            "Insert Hyperlink": "Wstaw łącze",
            "Edit Hyperlink": "Edytuj hiperłącze",
            "Insert": "Wstawić",
            "General": "Ogólne",
            "Indentation": "Wcięcie",
            "Before text": "Przed tekstem",
            "Special": "Specjalny",
            "First line": "Pierwsza linia",
            "Hanging": "Wiszące",
            "After text": "Po tekście",
            "By": "Przez",
            "Before": "Przed",
            "Line Spacing": "Odstępy między wierszami",
            "After": "Po",
            "At": "W",
            "Multiple": "Wielokrotność",
            "Spacing": "Rozstaw",
            "Define new Multilevel list": "Zdefiniuj nową listę wielopoziomową",
            "List level": "Poziom listy",
            "Choose level to modify": "Wybierz poziom do modyfikacji",
            "Level": "Poziom",
            "Number format": "Format liczbowy",
            "Number style for this level": "Styl liczbowy dla tego poziomu",
            "Enter formatting for number": "Wprowadź formatowanie liczby",
            "Start at": "Zacznij od",
            "Restart list after": "Ponownie uruchom listę po",
            "Position": "Pozycja",
            "Text indent at": "Wcięcie tekstu o",
            "Aligned at": "Wyrównany o",
            "Follow number with": "Podaj numer za pomocą",
            "Tab character": "Znak tabulacji",
            "Space": "Przestrzeń",
            "Arabic": "arabski",
            "UpRoman": "UpRoman",
            "LowRoman": "Niskorzymski",
            "UpLetter": "UpLitera",
            "LowLetter": "NiskaLitera",
            "Number": "Numer",
            "Leading zero": "Wiodące zero",
            "Bullet": "Pocisk",
            "Ordinal": "Porządkowy",
            "Ordinal Text": "Tekst zwykły",
            "For East": "Na wschód",
            "No Restart": "Bez ponownego uruchomienia",
            "Font": "Czcionka",
            "Font style": "Styl czcionki",
            "Underline style": "Podkreśl styl",
            "Font color": "Kolor czcionki",
            "Effects": "Efekty",
            "Strikethrough": "Przekreślenie",
            "Superscript": "Napisany u góry",
            "Subscript": "Indeks",
            "Double strikethrough": "Podwójne przekreślenie",
            "Regular": "Regularny",
            "Bold": "Pogrubienie",
            "Italic": "italski",
            "Cut": "Skaleczenie",
            "Copy": "Kopiuj",
            "Paste": "Pasta",
            "Hyperlink": "Hiperłącze",
            "Open Hyperlink": "Otwórz hiperłącze",
            "Copy Hyperlink": "Skopiuj hiperłącze",
            "Remove Hyperlink": "Usuń hiperłącze",
            "Paragraph": "Ustęp",
            "Linked Style": "Połączone (akapit i znak)",
            "Character": "Postać",
            "Merge Cells": "Scal komórki",
            "Insert Above": "Wstaw powyżej",
            "Insert Below": "Wstaw poniżej",
            "Insert Left": "Wstaw lewy",
            "Insert Right": "Wstaw w prawo",
            "Delete": "Usuń",
            "Delete Table": "Usuń tabelę",
            "Delete Row": "Usuń wiersz",
            "Delete Column": "Usuń kolumnę",
            "File Name": "Nazwa pliku",
            "Format Type": "Typ formatu",
            "Save": "Zapisz",
            "Navigation": "Nawigacja",
            "Results": "Wyniki",
            "Replace": "Zastąpić",
            "Replace All": "Zamień wszystko",
            "We replaced all": "Wymieniliśmy wszystko",
            "Find": "Odnaleźć",
            "No matches": "Brak wyników",
            "All Done": "Wszystko gotowe",
            "Result": "Wynik",
            "of": "z",
            "instances": "instancje",
            "with": "z",
            "Click to follow link": "Kliknij, aby śledzić link",
            "Continue Numbering": "Kontynuuj numerację",
            "Bookmark name": "Nazwa zakładki",
            "Close": "Blisko",
            "Restart At": "Uruchom ponownie o",
            "Properties": "Nieruchomości",
            "Name": "Nazwa",
            "Style type": "Rodzaj stylu",
            "Style based on": "Styl oparty na",
            "Style for following paragraph": "Styl następnego akapitu",
            "Formatting": "Formatowanie",
            "Numbering and Bullets": "Numeracja i wypunktowania",
            "Numbering": "Numeracja",
            "Update Field": "Zaktualizuj pole",
            "Edit Field": "Edytuj pole",
            "Bookmark": "Zakładka",
            "Page Setup": "Ustawienia strony",
            "No bookmarks found": "Nie znaleziono zakładek",
            "Number format tooltip information": "Format liczb na jednym poziomie: </br> [PREFIX]% [LEVELNUMBER] [SUFFIX] </br> Na przykład „Rozdział% 1”. wyświetli numerację jak </br> Rozdział 1. Pozycja </br> Rozdział 2. Pozycja </br>… </br> Rozdział N. Pozycja </br> </br> Format liczb wielopoziomowych: </br> [ PREFIX]% [LEVELNUMBER] [SUFFIX] + [PREFIX]% [LEVELNUMBER] [SUFFIX] </br> Na przykład „% 1.% 2”. wyświetli numerację taką jak </br> 1.1. Przedmiot </br> 1.2. Przedmiot </br>… </br> 1.N. Pozycja",
            "Format": "Format",
            "Create New Style": "Utwórz nowy styl",
            "Modify Style": "Zmień styl",
            "New": "Nowy",
            "Bullets": "Kule",
            "Use bookmarks": "Użyj zakładek",
            "Table of Contents": "Spis treści",
            "AutoFit": "Autodopasowanie",
            "AutoFit to Contents": "Autodopasowanie do treści",
            "AutoFit to Window": "Autodopasowanie do okna",
            "Fixed Column Width": "Naprawiono szerokość kolumny",
            "Reset": "Resetowanie",
            "Match case": "Sprawa meczu",
            "Whole words": "Całe słowa",
            "Add": "Dodaj",
            "Go To": "Iść do",
            "Search for": "Szukaj",
            "Replace with": "Zamienić",
            "TOC 1": "Spis treści 1",
            "TOC 2": "Spis treści 2",
            "TOC 3": "Spis treści 3",
            "TOC 4": "Spis treści 4",
            "TOC 5": "Spis treści 5",
            "TOC 6": "Spis treści 6",
            "TOC 7": "Spis treści 7",
            "TOC 8": "Spis treści 8",
            "TOC 9": "Spis treści 9",
            "Right-to-left": "Od prawej do lewej",
            "Left-to-right": "Z lewej na prawą",
            "Direction": "Kierunek",
            "Table direction": "Kierunek stołu",
            "Indent from right": "Wcięcie od prawej",
            "Contextual Spacing": "Nie dodawaj spacji między akapitami tego samego stylu",
            "Password Mismatch": "Hasło nie pasuje",
            "Restrict Editing": "Ogranicz edycję",
            "Formatting restrictions": "Ograniczenia dotyczące formatowania",
            "Allow formatting": "Zezwalaj na formatowanie",
            "Editing restrictions": "Ograniczenia edycji",
            "Read only": "Tylko czytać",
            "Exceptions Optional": "Wyjątki (opcjonalnie)",
            "Select Part Of Document And User": "Wybierz części dokumentu i wybierz użytkowników, którzy mogą je swobodnie edytować.",
            "Everyone": "Każdy",
            "More users": "Więcej użytkowników",
            "Add Users": "Dodaj użytkowników",
            "Enforcing Protection": "Tak, zacznij egzekwować ochronę",
            "Start Enforcing Protection": "Rozpocznij egzekwowanie ochrony",
            "Enter User": "Wpisz użytkownika",
            "Users": "Użytkownicy",
            "Enter new password": "Wprowadź nowe hasło",
            "Reenter new password to confirm": "Wprowadź ponownie nowe hasło, aby potwierdzić",
            "Your permissions": "Twoje uprawnienia",
            "Protected Document": "Ten dokument jest chroniony przed niezamierzoną edycją. Możesz edytować w tym regionie.",
            "FormFieldsOnly": "Formularze można wypełniać tylko w tym regionie.",
            "CommentsOnly": "Możesz wstawiać komentarze tylko w tym regionie.",
            "ReadOnlyProtection": "Możesz edytować w tym regionie.",
            "Stop Protection": "Zatrzymaj ochronę",
            "Password": "Hasło",
            "Spelling Editor": "Edytor pisowni",
            "Spelling": "Pisownia",
            "Spell Check": "Sprawdzanie pisowni",
            "Underline errors": "Podkreśl błędy",
            "Ignore": "Ignorować",
            "Ignore All": "Ignorować wszystko",
            "Add to Dictionary": "Dodaj do słownika",
            "Change": "Zmiana",
            "Change All": "Zmienić wszystko",
            "Suggestions": "Propozycje",
            "The password is incorrect": "Hasło jest nieprawidłowe",
            "Error in establishing connection with web server": "Błąd podczas nawiązywania połączenia z serwerem WWW",
            "Highlight the regions I can edit": "Podświetl regiony, które mogę edytować",
            "Show All Regions I Can Edit": "Pokaż wszystkie regiony, które mogę edytować",
            "Find Next Region I Can Edit": "Znajdź następny region, który mogę edytować",
            "Keep source formatting": "Zachowaj formatowanie źródła",
            "Match destination formatting": "Dopasuj formatowanie docelowe",
            "Text only": "Tylko tekst",
            "Comments": "Komentarze",
            "Type your comment": "Wpisz swój komentarz",
            "Post": "Poczta",
            "Reply": "Odpowiadać",
            "New Comment": "Nowy komentarz",
            "Edit": "Edytuj",
            "Resolve": "Rozwiązać",
            "Reopen": "Otworzyć na nowo",
            "No comments in this document": "Brak komentarzy w tym dokumencie",
            "more": "więcej",
            "Type your comment here": "Wpisz tutaj swój komentarz",
            "Next Comment": "Następny komentarz",
            "Previous Comment": "Poprzedni komentarz",
            "Un-posted comments": "Niepublikowane komentarze",
            "Discard Comment": "Dodane komentarze nie zostały opublikowane. Jeśli będziesz kontynuować, ten komentarz zostanie odrzucony.",
            "Discard": "Wyrzucać",
            "No Headings": "Nie znaleziono nagłówka!",
            "Add Headings": "Ten dokument nie ma nagłówków. Dodaj nagłówki i spróbuj ponownie.",
            "More Options": "Więcej opcji",
            "Click to see this comment": "Kliknij, aby zobaczyć ten komentarz",
            "Drop Down Form Field": "Rozwijane pole formularza",
            "Drop-down items": "Elementy rozwijane",
            "Items in drop-down list": "Elementy na liście rozwijanej",
            "ADD": "DODAJ",
            "REMOVE": "USUŃ",
            "Field settings": "Ustawienia pola",
            "Tooltip": "Etykietka",
            "Drop-down enabled": "Rozwijane włączone",
            "Check Box Form Field": "Pole wyboru Pole formularza",
            "Check box size": "Zaznacz rozmiar pola",
            "Auto": "Automatyczny",
            "Default value": "Domyślna wartość",
            "Not checked": "Niesprawdzone",
            "Checked": "Sprawdzone",
            "Check box enabled": "Pole wyboru włączone",
            "Text Form Field": "Pole formularza tekstowego",
            "Type": "Rodzaj",
            "Default text": "Domyślny tekst",
            "Maximum length": "Maksymalna długość",
            "Text format": "Format tekstowy",
            "Fill-in enabled": "Wypełnienie włączone",
            "Default number": "Domyślny numer",
            "Default date": "Domyślna data",
            "Date format": "Format daty",
            "Merge Track": "Ta akcja nie zostanie oznaczona jako zmiana. Czy chcesz kontynuować?",
            "UnTrack": "Nie można śledzić",
            "Accept": "Zaakceptować",
            "Reject": "Odrzucać",
            "Previous Changes": "Poprzednie zmiany",
            "Next Changes": "Następne zmiany",
            "Inserted": "Wstawiony",
            "Deleted": "Usunięte",
            "Move From": "W",
            "Move To": "Tam",
            "Changes": "Zmiany",
            "Accept all": "Akceptuj wszystkie",
            "Reject all": "Odrzuć wszystko",
            "No changes": "Bez zmian",
            "Accept Changes": "Zaakceptuj zmiany",
            "Reject Changes": "Odrzuć zmiany",
            "User": "Użytkownik",
            "View": "Widok",
            "Insertion": "Wprowadzenie",
            "Deletion": "Usunięcie",
            "All caps": "Wszystkie duże litery",
            "This region is locked by": "Ten region jest zablokowany przez",
            "Lock": "Zamek",
            "Unlock": "Odblokować",
            "Already locked": "Wybrany region lub jego część jest już zablokowana przez innego użytkownika",
            "1, 2, 3, ...": "1, 2, 3, ...",
            "a, b, c, ...": "a, b, c, ...",
            "A, B, C, ...": "A, B, C, ...",
            "I, II, III, ...": "I, II, III, ...",
            "i, ii, iii, ...": "I, II, III, ...",
            "Text Form": "Formularz tekstowy",
            "Check Box": "Pole wyboru",
            "DropDown": "Upuścić",
            "Form Fields": "Pola formularza",
            "Fillin enabled": "Wypełnij włączone",
            "Multiple Comment": "Napisz swój komentarz",
            "InsertFootnote": "Wstaw przypis",
            "InsertEndnote": "Wstaw przypis końcowy",
            "Footnote": "Notatka",
            "Endnote": "Uwaga końcowa",
            "Notes Options": "Sprawdź opcje",
            "Click to View/Edit Footnote": "Kliknij, aby wyświetlić / edytować przypis",
            "Click to View/Edit Endnote": "Kliknij, aby wyświetlić / edytować przypis końcowy",
            "InsertAsRows": "Wstaw jako nowe wiersze",
            "InsertAsColumns": "Wstaw jako nowe kolumny",
            "OverwriteCells": "Zastąp komórki",
            "NestTable": "Zagnieżdżanie stołu",
            "No suggestions": "Brak sugestii",
            "More Suggestion": "Więcej sugestii",
            "Ignore Once": "Zignoruj ​​raz",
            "Keep With Next": "Zachowaj z następnym",
            "Keep Lines Together": "Utrzymuj linie razem",
            "WidowControl": "Kontrola wdowy/sieroty",
            "Indents and Spacing": "Wcięcia i odstępy",
            "Line and Page Breaks": "Łamanie wierszy i stron",
            "Pagination": "Paginacja",
            "Single": "Pojedynczy",
            "DashSmallGap": "DashMała przerwa",
            "DashDot": "KreskaKropka",
            "Double": "Podwójnie",
            "ThinThickSmallGap": "Cienki GrubyMała Szczelina",
            "ThickThinSmallGap": "Gruby CienkiMała Szczelina",
            "ThickThinMediumGap": "Gruba Cienka Średnia Szczelina",
            "ThickThinLargeGap": "Gruba Cienka Duża Szczelina",
            "SingleWavy": "Pojedyncza fala",
            "DoubleWavy": "Podwójna fala",
            "Inset": "Wstawka",
            "DashLargeGap": "DashDuża przerwa",
            "Dot": "Kropka",
            "DashDotDot": "KreskaKropkaKropka",
            "Triple": "Potroić",
            "ThinThickThinSmallGap": "CienkieGrube CienkieMała Szczelina",
            "ThinThickThinMediumGap": "Cienkie Grube Cienkie Średnie Szczeliny",
            "ThinThickThinLargeGap": "CienkieGrube CienkieDuża Luka",
            "DashDotStroked": "KreskaKropkaObrysowana",
            "Engrave3D": "Graweruj3D",
            "Thick": "Gruby",
            "Outset": "Początek",
            "Emboss3D": "Wytłoczenie3D",
            "ThinThickLargeGap": "CienkiGrubyDuży Szczelina",
            "ThinThickMediumGap": "Cienka Gruba Średnia Szczelina",
            "Number of rows must be between": "Liczba wierszy musi być pomiędzy",
            "Number of columns must be between": "Liczba kolumn musi mieścić się w przedziale",
            "and": "I",
            "Unlimited": "Nieograniczony",
            "Regular text": "Zwykły tekst",
            "Date": "Data",
            "Uppercase": "Duże litery",
            "Lowercase": "Małe litery",
            "FirstCapital": "Pierwszy kapitał",
            "TitleCase": "Tytuł sprawy",
            "Filling in forms": "Wypełnianie formularzy",
            "px": "piks",
            "Tracked changes": "Śledzone zmiany",
            "TrackChangesOnly": "Możesz edytować w tym regionie, ale wszystkie zmiany będą śledzone.",
            "RemovedIgnore": "Czy chcesz usunąć ignorowane wyjątki?",
            "RemovedIgnoreExceptions": "Jeśli wprowadzisz tę zmianę w ochronie dokumentów, program Word zignoruje wszystkie wyjątki w tym dokumencie.",
            "Information": "Informacja",
            "Yes": "TAk",
            "No": "Nie",
            "Page Break": "Podział strony",
            "Column Break": "Przerwa w kolumnie",
            "Section Break Next Page": "Podział sekcji (następna strona)",
            "Section Break Continuous": "Podział sekcji (ciągły)",
            "Unsupported format": "Wybrany format pliku nie jest obsługiwany. Proszę wybrać poprawny format.",
            "One": "Jeden",
            "Two": "Dwa",
            "Three": "Trzy",
            "Presets": "Presety",
            "Columns": "Kolumny",
            "Split your text into two or more columns": "Podziel tekst na dwie lub więcej kolumn",
            "Line between column": "Linia między kolumnami",
            "Width and Spacing": "Szerokość i odstępy",
            "Equal column width": "Równa szerokość kolumny",
            "Column": "Kolumna",
            "Paste Content Dialog": "Ze względu na politykę bezpieczeństwa przeglądarki wklejanie ze schowka systemowego jest ograniczone. Alternatywnie użyj skrótu klawiaturowego",
            "Paste Content CheckBox": "Nie pokazuj się więcej.",
            "The top/bottom margins are too large for the page height in some sections.": "Marginesy górny/dolny są w niektórych sekcjach za duże w stosunku do wysokości strony.",
            "Column width cannot be less than 36 pt.": "Szerokość kolumny nie może być mniejsza niż 36 pkt.",
            "Left and right margins.": "Wybrane ustawienia lewego i prawego marginesu, odstępów między kolumnami lub wcięć akapitów są zbyt duże w stosunku do szerokości strony w tych samych sekcjach.",
            "The address of this site is 'not valid. Check the address and try again.": "Adres tej witryny jest nieprawidłowy. Sprawdź adres i spróbuj ponownie.",
            "Alt Text": "Tekst alternatywny",
            "Title": "Tytuł",
            "Description": "Opis",
            "Left Indent": "pole komunikacyjne",
            "Right Indent": "właściwy kurs",
            "Hanging Indent": "każdy ma swoją rolę",
            "First Line Indent": "Pierwszym z nich jest zginanie linii.",
            "Left Margin": "Yu Zuo",
            "Right Margin": "dobrze dobrze",
            "Top Margin": "czujnik",
            "Bottom Margin": "wkrótce",
            "Left Tab": "lewe ucho",
            "Right Tab": "prawe ucho",
            "Center Tab": "wskaźnik centralny",
            "Decimal Tab": "uboju zwierząt",
            "Bar Tab": "Komil B",
            "Move Table Column": "Przenoszenie kolumn w tabeli",
            "Paste CheckBox Content Dialog": "Nie pokazuj więcej",
            "The address of this site is not valid. Check the address and try again.": "Adres tej witryny jest nieprawidłowy. ",
            "Left and right margins": "Wybrane ustawienia lewego i prawego marginesu, odstępów między kolumnami lub wcięć akapitów są zbyt duże w stosunku do szerokości strony w tych samych sekcjach.",
            "Number of rows must be between 1 and 32767.": "Liczba wierszy musi mieścić się w przedziale od 1 do 32767.",
            "Number of columns must be between 1 and 63.": "Liczba kolumn musi mieścić się w przedziale od 1 do 63.",
            "You may format text only with certain styles": "Tekst można formatować tylko przy użyciu określonych stylów.",
            "Ignore all": "Ignorować wszystko"
        },
        "documenteditorcontainer": {
            "New": "Nowy",
            "Open": "otwarty",
            "Undo": "Cofnij",
            "Redo": "Przerobić",
            "Image": "Wizerunek",
            "Table": "Stół",
            "Link": "Połączyć",
            "Bookmark": "Zakładka",
            "Table of Contents": "Spis treści",
            "HEADING - - - - 1": "NAGŁÓWEK 1",
            "HEADING - - - - 2": "DZIAŁ - - - - 2",
            "HEADING - - - - 3": "DZIAŁ - - - - 3",
            "Header": "nagłówek",
            "Footer": "Stopka",
            "Page Setup": "Ustawienia strony",
            "Page Number": "Numer strony",
            "Break": "Przerwa",
            "Find": "Odnaleźć",
            "Local Clipboard": "Lokalny schowek",
            "Restrict Editing": "Ogranicz edycję",
            "Upload from computer": "Prześlij z komputera",
            "By URL": "Według adresu URL",
            "Page Break": "Podział strony",
            "Section Break": "Podział sekcji",
            "Header And Footer": "Nagłówek stopka",
            "Options": "Opcje",
            "Levels": "Poziomy",
            "Different First Page": "Inna pierwsza strona",
            "Different header and footer for odd and even pages": "Inny nagłówek i stopka dla stron nieparzystych i parzystych.",
            "Different Odd And Even Pages": "Różne strony nieparzyste i parzyste",
            "Different header and footer for first page": "Inny nagłówek i stopka dla pierwszej strony.",
            "Position": "Pozycja",
            "Header from Top": "Nagłówek z góry",
            "Footer from Bottom": "Stopka od dołu",
            "Distance from top of the page to top of the header": "Odległość od góry strony do góry nagłówka.",
            "Distance from bottom of the page to bottom of the footer": "Odległość od dołu strony do dołu stopki.",
            "Aspect ratio": "Współczynnik kształtu",
            "W": "W.",
            "H": "H.",
            "Width": "Szerokość",
            "Height": "Wysokość",
            "Text": "Tekst",
            "Paragraph": "Ustęp",
            "Fill": "Napełnić",
            "Fill color": "Kolor wypełnienia",
            "Border Style": "Styl granicy",
            "Outside borders": "Poza granicami",
            "All borders": "Wszystkie granice",
            "Inside borders": "Wewnątrz granic",
            "Left border": "Lewa granica",
            "Inside vertical border": "Wewnątrz pionowej granicy",
            "Right border": "Prawa granica",
            "Top border": "Górna granica",
            "Inside horizontal border": "Wewnątrz poziomej granicy",
            "Bottom border": "Dolna granica",
            "Border color": "Kolor ramki",
            "Border width": "Szerokość granicy",
            "Cell": "Komórka",
            "Merge cells": "Scal komórki",
            "Insert Or Delete": "Wstaw / Usuń",
            "Insert columns to the left": "Wstaw kolumny po lewej stronie",
            "Insert columns to the right": "Wstaw kolumny po prawej stronie",
            "Insert rows above": "Wstaw wiersze powyżej",
            "Insert rows below": "Wstaw wiersze poniżej",
            "Delete rows": "Usuń wiersze",
            "Delete columns": "Usuń kolumny",
            "Cell Margin": "Margines komórki",
            "Top": "Szczyt",
            "Bottom": "Dolny",
            "Left": "Lewo",
            "Right": "Prawo",
            "Align Text": "Wyrównaj tekst",
            "Align top": "Wyrównaj górę",
            "Align bottom": "Wyrównaj do dołu",
            "Align center": "Wyrównaj centrum",
            "Number of heading or outline levels to be shown in table of contents": "Liczba poziomów nagłówka lub konspektu do wyświetlenia w spisie treści.",
            "Show page numbers": "Pokaż numery stron",
            "Show page numbers in table of contents": "Pokaż numery stron w spisie treści.",
            "Right align page numbers": "Wyrównaj do prawej numery stron",
            "Right align page numbers in table of contents": "Wyrównaj do prawej numery stron w spisie treści.",
            "Use hyperlinks": "Użyj hiperłączy",
            "Use hyperlinks instead of page numbers": "Użyj hiperłączy zamiast numerów stron.",
            "Font": "Czcionka",
            "Font Size": "Rozmiar czcionki",
            "Font color": "Kolor czcionki",
            "Text highlight color": "Kolor podświetlenia tekstu",
            "Clear all formatting": "Wyczyść całe formatowanie",
            "Bold Tooltip": "Pogrubienie (Ctrl + B)",
            "Italic Tooltip": "Kursywa (Ctrl + I)",
            "Underline Tooltip": "Podkreśl (Ctrl + U)",
            "Strikethrough": "Przekreślenie",
            "Superscript Tooltip": "Indeks górny (Ctrl + Shift ++)",
            "Subscript Tooltip": "Indeks dolny (Ctrl + =)",
            "Align left Tooltip": "Wyrównaj do lewej (Ctrl + L)",
            "Center Tooltip": "Centrum (Ctrl + E)",
            "Align right Tooltip": "Wyrównaj do prawej (Ctrl + R)",
            "Justify Tooltip": "Justuj (Ctrl + J)",
            "Decrease indent": "Zmniejsz wcięcie",
            "Increase indent": "Zwiększ wcięcie",
            "Line spacing": "Odstępy między wierszami",
            "Bullets": "Kule",
            "Numbering": "Numeracja",
            "Styles": "Style",
            "Manage Styles": "Zarządzaj stylami",
            "Page": "Strona",
            "of": "z",
            "Fit one page": "Dopasuj jedną stronę",
            "Spell Check": "Sprawdzanie pisowni",
            "Spelling": "Nic",
            "Underline errors": "Podkreśl błędy",
            "Fit page width": "Dopasuj szerokość strony",
            "Update": "Aktualizacja",
            "Cancel": "Anuluj",
            "Insert": "Wstawić",
            "No Border": "Bez granic",
            "Create a new document": "Utwórz nowy dokument.",
            "Open a document": "Otwórz dokument.",
            "Undo Tooltip": "Cofnij ostatnią operację (Ctrl + Z).",
            "Redo Tooltip": "Wykonaj ostatnią operację (Ctrl + Y).",
            "Insert inline picture from a file": "Wstaw obraz wbudowany z pliku.",
            "Insert a table into the document": "Wstaw tabelę do dokumentu",
            "Create Hyperlink": "Utwórz link w dokumencie, aby uzyskać szybki dostęp do stron internetowych i plików (Ctrl + K).",
            "Insert a bookmark in a specific place in this document": "Wstaw zakładkę w określonym miejscu w tym dokumencie.",
            "Provide an overview of your document by adding a table of contents": "Podaj przegląd swojego dokumentu, dodając spis treści.",
            "Add or edit the header": "Dodaj lub edytuj nagłówek.",
            "Add or edit the footer": "Dodaj lub edytuj stopkę.",
            "Open the page setup dialog": "Otwórz okno dialogowe konfiguracji strony.",
            "Add page numbers": "Dodaj numery stron.",
            "Find Text": "Znajdź tekst w dokumencie (Ctrl + F).",
            "Toggle between the internal clipboard and system clipboard": "Przełącz między schowkiem wewnętrznym a schowkiem systemowym. </br> Ze względu na zasady bezpieczeństwa przeglądarki dostęp do schowka systemowego za pomocą skryptu jest zabroniony. Zamiast tego </br> 1. Możesz włączyć wewnętrzny schowek do wycinania, kopiowania i wklejania w składniku. </br> 2. Możesz używać skrótów klawiaturowych (Ctrl + X, Ctrl + C i Ctrl + V) do wycinania , kopiuj i wklej ze schowka systemowego.",
            "Current Page Number": "Aktualny numer strony w dokumencie. Kliknij lub naciśnij, aby nawigować po określonej stronie.",
            "Read only": "Tylko czytać",
            "Protections": "Zabezpieczenia",
            "Error in establishing connection with web server": "Błąd podczas nawiązywania połączenia z serwerem WWW",
            "Single": "Pojedynczy",
            "Double": "Podwójnie",
            "New comment": "Nowy komentarz",
            "Comments": "Komentarze",
            "Print layout": "Układ wydruku",
            "Web layout": "Układ sieci",
            "Text Form": "Formularz tekstowy",
            "Check Box": "Pole wyboru",
            "DropDown": "Upuścić",
            "Update Fields": "Zaktualizuj pola",
            "Update cross reference fields": "Zaktualizuj pola odsyłaczy",
            "Hide properties pane": "Ukryj panel właściwości",
            "Show properties pane": "Pokaż panel właściwości",
            "Form Fields": "Pola formularza",
            "Track Changes": "Śledź zmiany wprowadzone w dokumencie",
            "TrackChanges": "Śledzenie zmian",
            "AllCaps": "Wszystkie duże litery",
            "Change case Tooltip": "Zmień wielkość liter",
            "Insert Footnote": "Wstaw przypis",
            "Insert Endnote": "Wstaw przypis końcowy",
            "Footnote Tooltip": "Wstaw przypis (Alt + Ctrl + F).",
            "Endnote Tooltip": "Wstaw przypis końcowy (Alt + Ctrl + D).",
            "UPPERCASE": "DUŻE LITERY",
            "No color": "Brak koloru",
            "Top margin": "Górny margines",
            "Bottom margin": "Margines dolny",
            "Left margin": "Lewy margines",
            "Right margin": "Prawy margines",
            "Normal": "Normalna",
            "Heading": "Nagłówek",
            "Heading 1": "Nagłówek 1",
            "Heading 2": "Nagłówek 2",
            "Heading 3": "Nagłówek 3",
            "Heading 4": "Nagłówek 4",
            "Heading 5": "Nagłówek 5",
            "Heading 6": "Nagłówek 6",
            "ZoomLevelTooltip": "Poziom przybliżenia. Kliknij lub dotknij, aby otworzyć opcje powiększenia.",
            "None": "Nic",
            "Borders": "Granice",
            "ShowHiddenMarks Tooltip": "Pokaż ukryte znaki, takie jak spacje, tabulatory, znaki akapitu i przerwy. (Ctrl + *)",
            "Columns": "Kolumny",
            "Column Break": "Przerwa w kolumnie",
            "Link to Previous": "poprzedni link",
            "Link to PreviousTooltip": "Połącz tę sekcję z nagłówkiem lub stopką poprzedniej sekcji",
            "Alternate Text": "Alternatywny tekst",
            "The address of this site is not valid. Check the address and try again.": "Adres tej witryny jest nieprawidłowy. ",
            "OK": "OK",
            "Information": "Informacja",
            "Page Breaks": "na końcu strony",
            "Section Breaks": "Ten sektor upada",
            "Column": "Udostępnij to",
            "Next Page": "Następna strona",
            "Continuous": "praca"
        },
        "carousel": {
            "nextSlide": "Następny slajd",
            "of": "z",
            "pauseSlideTransition": "Wstrzymaj przejście slajdów",
            "playSlideTransition": "Odtwórz przejście slajdów",
            "previousSlide": "Poprzedni slajd",
            "slide": "Ślizgać się",
            "slideShow": "Pokaz slajdów"
        },
        "message": {
            "close": "Blisko"
        },
        "image-editor": {
            "0": "0%",
            "1": "1",
            "2": "2",
            "3": "3",
            "4": "4",
            "5": "5",
            "20": "20%",
            "40": "40%",
            "60": "60%",
            "80": "80%",
            "100": "100%",
            "Crop": "Przyciąć",
            "CropAndTransform": "Przytnij i przekształć",
            "ZoomIn": "Zbliżenie",
            "ZoomOut": "Pomniejsz",
            "Undo": "Cofnij",
            "Redo": "Przerobić",
            "Transform": "Przekształcać",
            "Annotation": "Adnotacja",
            "Finetune": "Dostroić",
            "Brightness": "Jasność",
            "Contrast": "Kontrast",
            "Hue": "Odcień",
            "Saturation": "Nasycenie",
            "Opacity": "Nieprzezroczystość",
            "Blur": "Plama",
            "Sharpen": "Wyostrzyć",
            "Exposure": "Narażenie",
            "Filter": "Filtr",
            "Default": "Domyślny",
            "Chrome": "Chrom",
            "Cold": "Zimno",
            "Warm": "Ciepły",
            "Grayscale": "Skala szarości",
            "BlackAndWhite": "Czarny i biały",
            "Sepia": "Sepia",
            "Invert": "Odwracać",
            "Text": "Dodaj tekst",
            "Pen": "Długopis",
            "Reset": "Resetowanie",
            "Save": "Ratować",
            "Select": "Wybierać",
            "RotateLeft": "Obrót w lewo",
            "RotateRight": "Obróć w prawo",
            "HorizontalFlip": "Odwrócenie poziome",
            "VerticalFlip": "Odwrócenie w pionie",
            "OK": "OK",
            "Cancel": "Anulować",
            "FillColor": "Kolor wypełnienia",
            "StrokeColor": "Kolor obrysu",
            "StrokeWidth": "Szerokość skoku",
            "FontFamily": "Rodzina czcionek",
            "FontStyle": "Styl czcionki",
            "FontSize": "Rozmiar czcionki",
            "FontColor": "Kolor czcionki",
            "Pan": "Patelnia",
            "Move": "Przenosić",
            "Load": "Obciążenie",
            "Custom": "Zwyczaj",
            "Square": "Kwadrat",
            "Circle": "Koło",
            "Ellipse": "Elipsa",
            "Rectangle": "Prostokąt",
            "Line": "Linia",
            "Arrow": "Strzałka",
            "Path": "Ścieżka",
            "Bold": "Pogrubiony",
            "Italic": "italski",
            "BoldItalic": "Pogrubiona kursywa",
            "XSmall": "X-mały",
            "Small": "Mały",
            "Medium": "Średni",
            "Large": "Duży",
            "XLarge": "Bardzo duży",
            "ABC": "ABC",
            "Browse": "Przeglądać",
            "Duplicate": "Duplikować",
            "Remove": "Usuń",
            "EditText": "Edytuj tekst",
            "Start": "Początek",
            "End": "Koniec",
            "Bar": "Bar",
            "ArrowSolid": "Strzałka Solidna",
            "CircleSolid": "Okrąg Solidny",
            "SquareSolid": "Kwadratowa bryła",
            "None": "Nic",
            "CropSelection": "Wybór upraw",
            "Image": "Dodaj obraz",
            "Transparency": "Przezroczystość",
            "Height": "Wysokość",
            "Width": "Szerokość",
            "AspectRatio": "Zachowaj proporcje",
            "W": "W",
            "H": "H",
            "DragText": "Przeciągnij i upuść swój obraz tutaj lub",
            "DropText": "Upuść swój obraz tutaj lub",
            "BrowseText": "Przeglądaj tutaj...",
            "SupportText": "Obsługuje:",
            "Frame": "Rama",
            "Mat": "Mata",
            "Bevel": "Ukos",
            "Inset": "Wstawka",
            "Hook": "Hak",
            "Color": "Kolor",
            "Size": "Rozmiar",
            "Offset": "Zrównoważyć",
            "Radius": "Promień",
            "Amount": "Kwota",
            "Resize": "Zmień rozmiar",
            "Border": "Granica",
            "Solid": "Solidny",
            "Dashed": "Przerywana",
            "Dotted": "Kropkowany",
            "GradientColor": "Kolor gradientowy",
            "ConfirmDialogHeader": "Potwierdź Zapisz zmiany",
            "ConfirmDialogContent": "Czy chcesz zapisać zmiany wprowadzone w obrazie?",
            "AlertDialogHeader": "Nie wspierany plik",
            "AlertDialogContent": "Upuszczony plik nie jest obsługiwany.",
            "Yes": "Tak",
            "No": "NIE",
            "ImageErrorDialogHeader": "Błąd wyboru obrazu",
            "ImageErrorDialogContent": "Po prostu wybierz obraz, który chcesz otworzyć.",
            "Straighten": "dotrzymanie terminu",
            "NoOutline": "Nie ma dyspozycji.",
            "Clone": "metafora",
            "Delete": "zniszczyć"
        },
        "mention": {
            "noRecordsTemplate": "Nic nie znaleziono"
        },
        "inplaceeditor": {
            "save": "Ratować",
            "cancel": "Anulować",
            "loadingText": "Ładowanie...",
            "editIcon": "Kliknij aby edytowac",
            "editAreaClick": "Kliknij aby edytowac",
            "editAreaDoubleClick": "Kliknij dwukrotnie, aby edytować"
        },
        "PdfViewer": {
            "PdfViewer": "Przeglądarka plików PDF",
            "Cancel": "Anulować",
            "Download file": "Pobieranie pliku",
            "Download": "Pobierać",
            "Enter Password": "Ten dokument jest chroniony hasłem. ",
            "File Corrupted": "Plik uszkodzony",
            "File Corrupted Content": "Plik jest uszkodzony i nie można go otworzyć.",
            "Fit Page": "Dopasuj stronę",
            "Fit Width": "Dopasuj szerokość",
            "Automatic": "Automatyczny",
            "Go To First Page": "Pokaż pierwszą stronę",
            "Invalid Password": "Niepoprawne hasło. ",
            "Next Page": "Pokaż następną stronę",
            "OK": "OK",
            "Open": "Otwórz plik",
            "Page Number": "Aktualny numer strony",
            "Previous Page": "Pokaż poprzednią stronę",
            "Go To Last Page": "Pokaż ostatnią stronę",
            "Zoom": "Powiększenie",
            "Zoom In": "Zbliżenie",
            "Zoom Out": "Pomniejsz",
            "Page Thumbnails": "Miniatury stron",
            "Bookmarks": "Zakładki",
            "Print": "Drukuj plik",
            "Password Protected": "Wymagane hasło",
            "Copy": "Kopiuj",
            "Text Selection": "Narzędzie do zaznaczania tekstu",
            "Panning": "Tryb panoramowania",
            "Text Search": "Znajdź tekst",
            "Find in document": "Znajdź w dokumencie",
            "Match case": "Sprawa meczu",
            "Apply": "Zastosuj",
            "GoToPage": "Idź do strony",
            "No matches": "Przeglądarka zakończyła przeszukiwanie dokumentu. ",
            "No Text Found": "Nie znaleziono tekstu",
            "Undo": "Cofnij",
            "Redo": "Przerobić",
            "Annotation": "Dodaj lub edytuj adnotacje",
            "Highlight": "Zaznaczanie tekstu",
            "Underline": "Podkreśl tekst",
            "Strikethrough": "Przekreślony tekst",
            "Delete": "Usuń adnotację",
            "Opacity": "Nieprzezroczystość",
            "Color edit": "Zmień kolor",
            "Opacity edit": "Zmień krycie",
            "Highlight context": "Atrakcja",
            "Underline context": "Podkreślać",
            "Strikethrough context": "Przebić",
            "Server error": "Usługa internetowa nie słucha. ",
            "Open text": "otwarty",
            "First text": "Pierwsza strona",
            "Previous text": "Poprzednia strona",
            "Next text": "Następna strona",
            "Last text": "Ostatnia strona",
            "Zoom in text": "Zbliżenie",
            "Zoom out text": "Pomniejsz",
            "Selection text": "Wybór",
            "Pan text": "Patelnia",
            "Print text": "Wydrukować",
            "Search text": "Szukaj",
            "Annotation Edit text": "Edytuj adnotację",
            "Line Thickness": "Grubość linii",
            "Line Properties": "Właściwości linii",
            "Start Arrow": "Uruchom Strzałkę",
            "End Arrow": "Strzałka końcowa",
            "Line Style": "Styl linii",
            "Fill Color": "Kolor wypełnienia",
            "Line Color": "Kolor linii",
            "None": "Nic",
            "Open Arrow": "otwarty",
            "Closed Arrow": "Zamknięte",
            "Round Arrow": "Okrągły",
            "Square Arrow": "Kwadrat",
            "Diamond Arrow": "Diament",
            "Butt": "Krupon",
            "Cut": "Cięcie",
            "Paste": "Pasta",
            "Delete Context": "Usuwać",
            "Properties": "Nieruchomości",
            "Add Stamp": "Dodaj pieczęć",
            "Add Shapes": "Dodaj kształty",
            "Stroke edit": "Zmień kolor obrysu",
            "Change thickness": "Zmień grubość obramowania",
            "Add line": "Dodaj linię",
            "Add arrow": "Dodaj strzałkę",
            "Add rectangle": "Dodaj prostokąt",
            "Add circle": "Dodaj okrąg",
            "Add polygon": "Dodaj wielokąt",
            "Add Comments": "Dodaj Komentarze",
            "Comments": "Uwagi",
            "No Comments Yet": "Brak komentarzy",
            "Accepted": "Przyjęty",
            "Completed": "Zakończony",
            "Cancelled": "Odwołany",
            "Rejected": "Odrzucony",
            "Leader Length": "Długość lidera",
            "Scale Ratio": "Współczynnik skali",
            "Calibrate": "Kalibrować",
            "Calibrate Distance": "Kalibracja odległości",
            "Calibrate Perimeter": "Kalibracja obwodu",
            "Calibrate Area": "Kalibracja obszaru",
            "Calibrate Radius": "Kalibracja promienia",
            "Calibrate Volume": "Skalibruj głośność"
        },
        "stepper": {
            "optional": "Opcjonalny"
        }
    }
}