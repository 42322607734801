<template>
  <AppLayout>
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
      <div class="widget widget-six">
        <div class="widget-heading">
          <div class="row">
            <div class="col-xl-12 col-md-12 col-sm-12 col-12 d-inline-flex align-items-center">
              <h4 class="me-3 my-0">Utwórz nowego kontrahenta</h4>
              <Link :href="route('customers.index')" class="btn btn-outline-primary">
                <i class="bi bi-people"></i>&nbsp;Lista kontrahentów
              </Link>
            </div>
          </div>
        </div>

        <div class="widget-content">
          <div v-if="$page.props.errors">
            <ul style="list-style-type: none; padding: 0" v-for="error in $page.props.errors">
              <li class="alert alert-danger">{{ error }}</li>
            </ul>
          </div>

          <div class="float-end">
            <GetCustomerFromGUS @getFromGus="getFromGus"></GetCustomerFromGUS>
          </div>

          <div class="row">
            <form @submit.prevent="form.post(route('customers.store'))">

              <div class="d-inline-flex">
                <h4 class="me-3 my-0 mb-2">Kontrahent</h4>

                <div class="checkbox-success custom-control custom-checkbox text-color me-2">
                  <input type="checkbox" class="custom-control-input" id="recipient"
                         v-model="form.recipient"/>
                  <label class="custom-control-label" for="recipient">Odbiorca</label>
                </div>

                <div class="checkbox-success custom-control custom-checkbox text-color me-2">
                  <input type="checkbox" class="custom-control-input" id="supplier"
                         v-model="form.supplier"/>
                  <label class="custom-control-label" for="supplier">Dostawca</label>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6 col-lg-3">
                  <label class="form-label" for="name">Nazwa:</label>
                  <div v-if="form.errors.name">{{ form.errors.name }}</div>
                  <input class="form-control" id="name" v-model="form.name"/>
                </div>

                <div class="col-md-6 col-lg-3">
                  <label class="form-label" for="short_name">Krótka Nazwa</label>
                  <div v-if="form.errors.short_name">{{ form.errors.short_name }}</div>
                  <input class="form-control" id="short_name" v-model="form.short_name"/>
                </div>

                <div class="col-md-6 col-lg-3">
                  <label class="form-label" for="nip">Nip:</label>
                  <div v-if="form.errors.nip">{{ form.errors.nip }}</div>
                  <input class="form-control" id="nip" v-model="form.nip"/>
                </div>

                <div class="col-md-6 col-lg-3">
                  <label class="form-label" for="zip_code">Kod pocztowy:</label>
                  <div v-if="form.errors.zip_code">{{ form.errors.zip_code }}</div>
                  <input class="form-control" id="zip_code" v-model="form.zip_code"/>
                </div>

                <div class="col-md-6 col-lg-3">
                  <label class="form-label" for="city">Miasto:</label>
                  <div v-if="form.errors.city">{{ form.errors.city }}</div>
                  <input class="form-control" id="city" v-model="form.city"/>
                </div>

                <div class="col-md-6 col-lg-3">
                  <label class="form-label" for="address">Adres (ulica i nr budynku):</label>
                  <div v-if="form.errors.address">{{ form.errors.address }}</div>
                  <input class="form-control" id="address" v-model="form.address"/>
                </div>

                <div class="col-md-6 col-lg-3">
                  <label class="form-label" for="address2">Numer lokalu:</label>
                  <div v-if="form.errors.address2">{{ form.errors.address2 }}</div>
                  <input class="form-control" id="address2" v-model="form.address2"/>
                </div>

              </div>
              <button class="btn btn-primary mt-3" type="submit" :disabled="form.processing">Zapisz</button>
            </form>
          </div>

          <div class="row mt-5">
            <h4 class="me-3 my-0 mb-2">Osoby kontaktowe</h4>
            <!-- Wyświetlanie istniejących osób kontaktowych -->
            <table class="table mt-3">
              <thead>
              <tr>
                <th>Zwrot grzecz.</th>
                <th>Imię</th>
                <th>Nazwisko</th>
                <th>Stanowisko</th>
                <th>Telefon</th>
                <th>Email</th>
                <th>Domyślna</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(contact, index) in form.contacts" :key="index">
                <td>{{ contact.salutation }}</td>
                <td>{{ contact.first_name }}</td>
                <td>{{ contact.last_name }}</td>
                <td>{{ contact.position }}</td>
                <td>{{ contact.phone }}</td>
                <td>{{ contact.email }}</td>
                <td>{{ contact.default }}</td>
              </tr>
              </tbody>
            </table>

            <hr class="bg-dark">
            <form @submit.prevent="addContact">
              <div class="row">
                <!-- Formularz do dodawania osób kontaktowych -->
                <div class="mb-3 col-md-6 col-lg-3">
                  <label for="first_name">Zwrot grzecz.:</label>
                  <input type="text" class="form-control" id="salutation" v-model="newContact.salutation">
                </div>

                <div class="mb-3 col-md-6 col-lg-3">
                  <label for="first_name">Imię*:</label>
                  <input type="text" class="form-control" id="first_name" v-model="newContact.first_name">
                </div>

                <div class="mb-3 col-md-6 col-lg-3">
                  <label for="last_name">Nazwisko*:</label>
                  <input type="text" class="form-control" id="last_name" v-model="newContact.last_name">
                </div>

                <div class="mb-3 col-md-6 col-lg-3">
                  <label for="position">Stanowisko:</label>
                  <input type="text" class="form-control" id="position" v-model="newContact.position">
                </div>

                <div class="mb-3 col-md-6 col-lg-3">
                  <label for="phone">Telefon:</label>
                  <input type="text" class="form-control" id="phone" v-model="newContact.phone">
                </div>

                <div class="mb-3 col-md-6 col-lg-3">
                  <label for="email">Email:</label>
                  <input type="text" class="form-control" id="email" v-model="newContact.email">
                </div>

                <div class="col-md-6 col-lg-3 align-self-center">
                  <div class="checkbox-success custom-control custom-checkbox text-color">
                    <input type="checkbox" class="custom-control-input" id="defaultSwitch"
                           v-model="newContact.default"/>
                    <label class="custom-control-label" for="defaultSwitch">Domyślna</label>
                  </div>
                </div>

              </div>
              <button type="submit" class="btn btn-primary">Dodaj osobę kontaktową</button>
            </form>
          </div>

        </div>
      </div>
    </div>
  </AppLayout>
</template>

<script setup>
import {useForm} from "@inertiajs/vue3";
import AppLayout from "@/Layouts/App.vue";
import {ref} from "vue";
import route from "../../../../vendor/tightenco/ziggy/src/js/index.js";
import GetCustomerFromGUS from "./GetCustomerFromGUS.vue";

const form = useForm({
  name: null,
  short_name: null,
  nip: null,
  company: null,
  zip_code: null,
  city: null,
  address: null,
  address2: null,
  contacts: [],
  supplier: null,
  recipient: null
});

const newContact = ref({
  salutation: "",
  first_name: "",
  last_name: "",
  position: "",
  phone: "",
  email: "",
  default: "",
});

const addContact = () => {
  // Sprawdź, czy wszystkie pola są uzupełnione
  if (
      newContact.value.first_name &&
      newContact.value.last_name
  ) {
    // Dodaj nową osobę kontaktową do form.contacts
    form.contacts.push({...newContact.value});

    // Wyczyść dane nowej osoby kontaktowej
    newContact.value.salutation = "";
    newContact.value.first_name = "";
    newContact.value.last_name = "";
    newContact.value.position = "";
    newContact.value.phone = "";
    newContact.value.email = "";
    newContact.value.default = "";
  }
};

const getFromGus = (data) => {
  form.name = data.company_name;
  form.nip = data.nip;
  form.company = data.company;
  form.zip_code = data.zip_code;
  form.city = data.city;
  form.address = data.street + ' ' + data.building_number;
  form.address2 = data.apartment_number;
}
</script>
