<template>
    <button type="button" class="btn btn-sm btn-outline-info" data-bs-toggle="modal" data-bs-target="#modalDeliverers">
        <i class="bi bi-truck"></i>&nbsp; Dostawcy
    </button>


    <!-- Modal -->

    <div class="modal fade" id="modalDeliverers" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Dostawcy</h5>
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <button v-if="!showAddNewCategorySupplier" type="button" class="btn btn-outline-primary" @click="f_showAddNewCategorySupplier()">Dodaj kategorię</button>
                    <button v-else type="button" class="btn btn-outline-primary" @click="f_showAddNewCategorySupplier()">Użyj istniejącej</button>

                    <form class="pb-5" style="border-bottom: 1px solid #ddd" @submit.prevent="createNewSupplier()">
                        <div class="row mb-3">
                            <div v-if="!showAddNewCategorySupplier" class="col">
                                <label class="form-label">Kategoria*</label>
                                <select class="form-select form-select-sm" v-model="newSupplier.category" required>
                                    <option value="dostawca_pneumatyki">Dostawca pneumatyki</option>
                                    <option value="dostawca_napedow_servo">Dostawca napędów servo</option>
                                    <option value="dostawca_prowadnic_liniowych">dostawca prowadnic liniowych</option>
                                </select>
                            </div>
                            <div v-else class="col">
                                <label class="form-label">Nowa kategoria*</label>
                                <input class="form-control form-control-sm" type="text" v-model="newSupplier.newSupplierCategory" placeholder="Wpisz nową kategorię">
                            </div>

                            <div class="col">
                                <label class="form-label">Dostawca*</label>
                                <select class="form-select form-select-sm" v-model="newSupplier.deliverer" required>
                                    <option value="1">Dostawca 1</option>
                                    <option value="2">Dostawca 2</option>
                                    <option value="3">Dostawca 3</option>
                                </select>
                            </div>

                            <div class="col">
                                <label class="form-label">Nazwa dostawcy*</label>
                                <input class="form-control form-control-sm" type="text" v-model="newSupplier.name" required>
                            </div>

                            <div class="col">
                                <label class="form-label">&nbsp;</label>
                                <button type="submit" class="d-block btn btn-sm btn-primary">Zapisz nową kategorię</button>
                            </div>
                        </div>
                        <div id="errorMessageCreateNewSupplier" class="text-danger" style="display: none">Wypełnij wszystkie pola</div>
                    </form>

                    <table class="mt-5 table table-bordered">
                        <thead>
                        <tr>
                            <th>Kategoria</th>
                            <th>Dostawca 1</th>
                            <th>Dostawca 2</th>
                            <th>Dostawca 3</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(deliverers) in projectSuppliers">
                            <td>
                                {{ deliverers[Object.keys(deliverers)[0]][0].categoryDisplayName }}
                            </td>
                            <td v-for="key in [1, 2, 3]" :key="key">
                                <template v-if="deliverers[key]">
                                    <div v-for="delivererData in deliverers[key]" :key="delivererData.id">
                                        <form :id="'deliverer_'+delivererData.id" class="mb-2" @submit.prevent="deleteProjectSupplier(delivererData.name, delivererData.id)">
                                            {{ delivererData.name }}
                                            <button type="submit" class="float-end ms-1 btn btn-xs btn-danger" :value="delivererData.id">
                                                <i class="bi bi-x-square"></i>
                                            </button>
                                        </form>
                                    </div>
                                </template>

                                <template v-else>
                                    <td></td>
                                </template>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Zamknij</button>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import route from "../../../../../vendor/tightenco/ziggy/src/js/index.js";
export default {
    props: {
        projectSuppliers: Object,
        updateProjectSuppliers: {
            type: Function
        }
    },
    data() {
        return {
            showAddNewCategorySupplier: false,
            newSupplier: {
                newSupplierCategory: null,
                category: '',
                deliverer: '',
                name: ''
            },
        }
    },

    methods: {
        f_showAddNewCategorySupplier() {
            this.showAddNewCategorySupplier = this.showAddNewCategorySupplier === false;
        },

        deleteProjectSupplier(delivererName, delivererId) {
            const self = this;
            Toast.fire({
                toast: false,
                icon: 'warning',
                title: 'Usuwanie dostawcy',
                html: 'Jesteś pewny że chcesz usunąć ' + delivererName + ' z dostawców?',
                showDenyButton: true,
                showClass: {popup: 'animate__animated animate__fadeInDown'},
                hideClass: {popup: 'animate__animated animate__fadeOutUp'},
                timer: false,
                position: 'center',
                showConfirmButton: true,
                denyButtonText: 'Nie',
                confirmButtonText: 'Tak',
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.post(route('project.project-supplier.softDelete', {projectSupplier: delivererId})).then(response => {
                        Toast.fire({
                            toast: true,
                            icon: response.data.icon,
                            title: response.data.title,
                            html: response.data.message,
                            showClass: {popup: 'animate__animated animate__fadeInDown'},
                            hideClass: {popup: 'animate__animated animate__fadeOutUp'},
                            timer: 2500,
                        })

                        document.getElementById('deliverer_' + delivererId).remove();

                        self.updateProjectSuppliers();

                    }).catch(error => {
                        console.log(error);
                    })
                }
            })
        },

        createNewSupplier() {
            const self = this;
            if ((!this.newSupplier.category && !this.newSupplier.newSupplierCategory) || !this.newSupplier.deliverer || !this.newSupplier.name) {
                $('#errorMessageCreateNewSupplier').show();
                return;
            }

            axios.post(route('project.project-supplier.add', {
                newSupplierCategory: this.newSupplier.newSupplierCategory,
                name: this.newSupplier.name,
                category: this.newSupplier.category,
                deliverer: this.newSupplier.deliverer
            })).then(response => {
                Toast.fire({
                    toast: true,
                    icon: response.data.icon,
                    title: response.data.title,
                    html: response.data.message,
                    showClass: {popup: 'animate__animated animate__fadeInDown'},
                    hideClass: {popup: 'animate__animated animate__fadeOutUp'},
                    timer: 2500,
                })

                self.updateProjectSuppliers();

            }).catch(error => {
                console.log(error);
            })
        },
    }
}
</script>
